import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  //GetProductByIdSale,
  GetSaleOrderDetailsByID,
  GetSaleOrderCustomerByID,
  //DeleteEditSaleOrderProduct,
  //EditSaleOrderApi,
  //getProductFilterSubString,
  GetAccToIdBySaleOrder,
  //EditSpecialOrder,
  getAccNamesAssetCash,
  AddSalesReturnApi,
  //getAllVendorsNameByPurchaseProduct,
} from "../../api";
import { ValidOrderField } from "../../contexts/Utils";
import { Header } from "../../components";
//import Select from "react-select";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/sale.css";
import { Card } from "react-bootstrap";
//import Sidebar from "../../components/ViewOrderProduct";
import "../../styles/viewCustomer.css";
import { Col, Container, Row } from "react-bootstrap";
//import { openNewTab } from "../../contexts/SO_Invoice";

const AddSalesReturn = () => {
  const {
    currentColor,
    //activeProdMenu,
    setActiveProdMenu,
    setActiveProdMenuId,
    userID,
    userToken,
  } = useStateContext();
  //const navigate = useNavigate();
  //const [GetCustomer, setGetCustomer] = useState([]);
  //const [ProductStr, setProductStr] = useState("");
  //const [Customer, setCustomer] = useState("");
  //const [CustomerOptions, setCustomerOptions] = useState([]);
  //const [GetProduct, setGetProduct] = useState([]);
  //const [product, setProducts] = useState("");
  //const [qty, setqty] = useState(1.0);
  //const [productOptions, setProductOptions] = useState([]);
  //const [p_code, setp_code] = useState("");
  // const [cardList_old, setcartList_old] = useState([]);
  //const [totaldiscount, settotaldiscount] = useState(0.0);
  //const [status_id, setstatus_id] = useState("");
  //const [CustomerDetail, setCustomerDetail] = useState([]);
  //const [invoice_id, setinvoice_id] = useState("");
  //const [projectname2, setprojectname2] = useState(0);
  // const [deleteprodList, setdeleteprodList] = useState([]);
  //const [OrderStatus, setOrderStatus] = useState("");
  //const [ValError, setValError] = useState([]);
  // const [unshippedList, setunshippedList] = useState([]);
  // const [qtyShipped, setqtyShipped] = useState([]);
  //const [projectname1, setprojectname1] = useState("");
  //const [productID, setproductID] = useState("");
  //const [toggle, setToggle] = useState(false);
  //const [ActiveProduct, setActiveProduct] = useState(0);
  //const [aexist, setaexist] = useState("");
  // const [name, setname] = useState("");
  //const [cusPhone, setcusPhone] = useState("");
  //const [req_date, setreq_date] = useState();
  const [customer_ids, setcustomer_ids] = useState("");
  const [CardList, setcartList] = useState([]);
  const [total_amount, settotalAmount] = useState(0);
  const [tax, settax] = useState(0);
  const [total_item, settotalitem] = useState(0);
  const [index1, setindex1] = useState("");
  const [grandtotal, setgrandtotal] = useState(0);
  const [note, setnote] = useState("");
  const [trackingno, settrackingno] = useState("");
  const [projectname, setprojectname] = useState("");
  const [shipmethod, setshipmethod] = useState("");
  const [cusFlag, setcusFlag] = useState("");
  const [orderstatuslable, setorderstatuslable] = useState("");
  const [acc_from_id, setacc_from_id] = useState("");
  const [acc_from_bal, setacc_from_bal] = useState("");
  const [acc_to_id, setacc_to_id] = useState("");
  const [acc_to_bal, setacc_to_bal] = useState("");
  const [c_type_id, setc_type_id] = useState("");
  const [amount_paid, setamount_paid] = useState(0);
  const [allAccTo, setAllAccTo] = useState([]);
  const [getacc_tos, setGetacc_tos] = useState([]);
  const [acc_to, setacc_to] = useState("");
  const [store_id_param, setstore_id_param] = useState("");
  const [dot, setdot] = useState(0);
  const [isPageFrozen, setIsPageFrozen] = useState(false);
  const [saveClick, setsaveClick] = useState(false);
  const [ppo_id, setppo_id] = useState(0);
  const [print_flag, setprint_flag] = useState(false);
  const [sDatetime, setSDatetime] = useState("");
  const [oDatetime, setODatetime] = useState("");
  const [oMinDatetime, setOMinDatetime] = useState("");
  const [oMaxDatetime, setOMaxDatetime] = useState("");
  const [dTNow, setDTNow] = useState(1);

  //const [DirectShipping_flag] = useState(true);
  let param = useParams();
  var so_id_param = param.so_id;

  const [formData] = useState({
    so_id: 0,
    // est_sale: "S",
    customer_id: 0,
    //customer_po_no: "",
    project_name: "",
    ship_method: "",
    tracking_no: "",
    total: 0,
    amount_paid: 0,
    amount_pending: 0,
    user_id: null,
    sr_note: "",
    total_price: 0,
    //discount: 0,
    //tax: 0,
    //status_id: 0,
    // shipment: 0,
    store_id: 0,
    //invoice_id: 0,
    t_type_id: 709,
    o_datetime: "",
    products: [],
    receive_logs: [],
  });

  // const [formData1, setFormData1] = useState({
  //   so_id: so_id_param,
  //   store_id: 0,
  //   invoice_id: 0,
  //   product_id: 0,
  // });

  const keypadButtons = [
    "7",
    "8",
    "9",
    "4",
    "5",
    "6",
    "1",
    "2",
    "3",
    ".",
    "0",
    "00",
  ];

  const [activeInput, setActiveInput] = useState(null);
  // const [selectedProduct, setSelectedProduct] = useState(null);
  //const [selectedCustomer, setSelectedCustomer] = useState(null);
  // const [selectedSupplier, setSelectedSupplier] = useState(null);
  // const [supplier_ids, setsupplier_ids] = useState("");
  // const [supplier_name, setsupplier_name] = useState("-");
  // const [SupplierOptions, setSupplierOptions] = useState([]);
  // const [GetSupplier, setGetSupplier] = useState([]);

  const handleChangeDTNow = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    // console.log(now.toISOString().slice(0, 19));
    //console.log(oDatetime);
    setODatetime(now.toISOString().slice(0, 19));

    if (dTNow === 1) {
      setOMaxDatetime(now.toISOString().slice(0, 19));
      now = new Date(now.getFullYear(), 0, 2);
      //console.log(now.toISOString().slice(0, 19));
      //setOMinDatetime(now.toISOString().slice(0, 19));
    }
    setDTNow((prev) => (prev === 0 ? 1 : 0));
  };

  const handleChangeODateTime = (e) => {
    if (e === "undefined") {
      setODatetime(null);
    } else {
      if (new Date(e) > new Date(oMinDatetime)) {
        setODatetime(e);
      }
    }
  };

  const handleInputClick = (inputId, index) => {
    TimeoutUtility.resetTimeout();
    setActiveInput(inputId);
    setindex1(index);
  };

  const handleSpecQtyClick = (inputId, index) => {
    TimeoutUtility.resetTimeout();
    setActiveInput(inputId);
    setindex1(index);
  };

  // const handleUnitPriceChange = (index, value) => {
  //   TimeoutUtility.resetTimeout();
  //   const newCartList = [...CardList];
  //   newCartList[index].unit_price = value;
  //   newCartList[index].total =
  //     value * parseFloat(newCartList[index].quantity) -
  //     parseFloat(newCartList[index].quantity) *
  //       parseFloat(newCartList[index].discount);
  //   setcartList(newCartList);
  //   setActiveInput("unit_price");
  //   setindex1(index);
  // };

  const handleKeypadClick = (value) => {
    TimeoutUtility.resetTimeout();
    // try {
    if (activeInput !== null) {
      const newCartList = [...CardList];
      const indexToUpdate = index1;

      if (
        // activeInput === "quantity" ||
        // activeInput === "unit_price" ||
        // activeInput === "discount" ||
        // activeInput === "shipment" ||
        activeInput === "return_qty"
      ) {
        var currentValue = String(
          newCartList[indexToUpdate][activeInput] || ""
        );
        //console.log(currentValue);
        if (value === "." && currentValue.includes(".")) {
          return;
        }

        if (value === "." && !currentValue.includes(".")) {
          if (currentValue === "") {
            currentValue = "0";
          }
          newCartList[indexToUpdate][activeInput] = currentValue + ".0";
          setcartList(newCartList);
          setdot(1);
          return;
        }
        var newValue = currentValue + String(value);
        if (dot === 1) {
          var [integerPart] = currentValue.split(".");
          if (integerPart === "") {
            integerPart = "0";
          }
          newValue = integerPart + "." + value;
          setdot(0);
        }

        if (!isNaN(newValue)) {
          // if (activeInput === "shipment" || activeInput === "spec_order") {
          //   // newValue = String(parseInt(newValue));
          //   if (parseFloat(newValue) > newCartList[indexToUpdate].quantity) {
          //     if (activeInput === "shipment") {
          //       alert("Ship qty must be less or equal to total qty.");
          //     } else {
          //       alert("Special order qty must be less or equal to total qty.");
          //     }

          //     newCartList[indexToUpdate][activeInput] =
          //       newCartList[indexToUpdate][activeInput] + "";
          //     setcartList(newCartList);
          //     setdot(0);
          //     return;
          //   }
          // }
          // newCartList[indexToUpdate][activeInput] = newValue;
          // newCartList[indexToUpdate].total =
          //   newCartList[indexToUpdate].unit_price *
          //     newCartList[indexToUpdate].quantity -
          //   newCartList[indexToUpdate].discount *
          //     newCartList[indexToUpdate].quantity;
          newCartList[indexToUpdate][activeInput] = newValue;
          newCartList[indexToUpdate].return_total =
            newCartList[indexToUpdate].unit_price *
              newCartList[indexToUpdate].return_qty -
            newCartList[indexToUpdate].discount *
              newCartList[indexToUpdate].return_qty;
          //-newCartList[indexToUpdate].discount;

          setcartList(newCartList);
          setdot(0);
        }
        // } else if (activeInput === "tax" && index1 === -2) {
        //   const currentTaxValue = String(tax || "");

        //   if (value === "." && currentTaxValue.includes(".")) {
        //     return;
        //   }

        //   const newValue = currentTaxValue + String(value || "");
        //   if (!isNaN(newValue)) {
        //     settax(newValue);
        //   }
        // } else if (activeInput === "shipment1" && index1 === -3) {
        //   const currentShipmentValue = String(projectname2 || "");

        //   if (value === "." && currentShipmentValue.includes(".")) {
        //     return;
        //   }

        //   const newValue = currentShipmentValue + String(value || "");
        //   if (!isNaN(newValue)) {
        //     setprojectname2(newValue);
        //   }
      } else if (activeInput === "amount_paid" && index1 === -4) {
        const currentShipmentValue = String(amount_paid || "");

        if (value === "." && currentShipmentValue.includes(".")) {
          return;
        }

        const newValue = currentShipmentValue + String(value || "");
        if (!isNaN(newValue)) {
          setamount_paid(newValue);
        }
      }
    }
  };

  // const handleAddcartClick = () => {
  //   TimeoutUtility.resetTimeout();
  //   // const isProductInCart = CardList.some(
  //   //   (item) => item.product_id === product.value
  //   // );
  //   // if (!isProductInCart && product.value) {
  //   if (product.value) {
  //     // var supp_id = null;
  //     // if (supplier_ids !== "" && supplier_ids !== null) {
  //     //   supp_id = supplier_ids;
  //     // }
  //     const resp1 = GetProductByIdSale(p_code);
  //     resp1
  //       .then(function (result) {
  //         const defaultProduct = {
  //           product_id: result.data[0].product_id,
  //           name: result.data[0].name,
  //           code: result.data[0].code,
  //           unit_price: result.data[0].unit_price,
  //           cost_price: result.data[0].cost_price,
  //           quantity: qty,
  //           item_no: 0,
  //           discount: result.data[0].discount,
  //           total:
  //             result.data[0].unit_price * qty - result.data[0].discount * qty,
  //           // - result.data[0].discount,
  //           shipment: 0,
  //           image: result.data[0].image,
  //           details: result.data[0].details,
  //           req_delivery_date: null,
  //           supplier_id: supplier_ids,
  //           supplier_name: supplier_name,
  //           spec_qty: 0,
  //           notes: "",
  //         };

  //         setcartList((prevProductList) => [
  //           defaultProduct,
  //           ...prevProductList,
  //         ]);
  //         setProducts("");
  //         setSelectedProduct("");
  //         setSelectedSupplier("");
  //         setqty(1);

  //         setProductOptions((prevOptions) =>
  //           prevOptions.filter(
  //             (option) => option.values !== result.data[0].product_id
  //           )
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }
  //   setSelectedProduct("");
  //   setSelectedSupplier("");
  //   setqty(1);
  // };

  // const handleChangeReqDate = (index, e) => {
  //   const newCartList = [...CardList];
  //   if (e === "undefined") {
  //     newCartList[index].req_delivery_date = null;
  //     //setreq_date(null);
  //   } else {
  //     newCartList[index].req_delivery_date = e;
  //     //setreq_date(e);
  //   }
  //   setcartList(newCartList);
  // };

  // const handleChangeCustomer = (selectedOption) => {
  //   if (selectedOption && selectedOption.value) {
  //     setSelectedCustomer(selectedOption);
  //     setacc_from_id(selectedOption.account_id);
  //     setActiveInput("customer");
  //     setCustomer(selectedOption);
  //     setcustomer_ids(selectedOption.value);
  //     getCustomerDetail(selectedOption.value);
  //     if (selectedOption.value) {
  //       setGetProduct([]);
  //       // getProductsAll();
  //     } else {
  //       setGetProduct([]);
  //     }
  //     setcartList([]);
  //     settax(0);
  //     settotaldiscount(0);
  //     settotalitem(0);
  //     setSelectedProduct("");
  //     setnote("");
  //     settrackingno("");
  //     setprojectname("");
  //     setprojectname1("");
  //     setprojectname2(0);
  //     setshipmethod("");
  //   }
  // };

  // const handleChangeSupplier = (selectedOption) => {
  //   TimeoutUtility.resetTimeout();
  //   if (selectedOption && selectedOption.value) {
  //     setSelectedSupplier(selectedOption);
  //     setActiveInput("customer");
  //     setsupplier_ids(selectedOption.value);
  //     setsupplier_name(selectedOption.label);
  //   } else {
  //     setSelectedSupplier(null);
  //     setActiveInput("customer");
  //     setsupplier_ids("");
  //     setsupplier_name("");
  //   }
  // };

  const handleAccToChange = (e) => {
    TimeoutUtility.resetTimeout();
    setacc_to(e.target.value);
    const acc = getacc_tos.find((item) => item.name === e.target.value);
    //console.log(acc.account_id);
    setacc_to_bal(acc.end_balance);
    setacc_to_id(acc.account_id);
  };

  const handleSpecQtyChange = (index, value) => {
    const newCartList = [...CardList];
    if (newCartList[index].shipment >= value) {
      if (!ValidOrderField(value)) {
        if (value !== "") {
          newCartList[index].return_qty = 0;
          newCartList[index].return_total =
            value * parseFloat(newCartList[index].unit_price) -
            value * parseFloat(newCartList[index].discount);
          setcartList(newCartList);
          setActiveInput("quantity");
          setindex1(index);
          return;
        }
      }
      newCartList[index].return_qty = value;
      newCartList[index].return_total =
        value * parseFloat(newCartList[index].unit_price) -
        value * parseFloat(newCartList[index].discount);
      setcartList(newCartList);
      setActiveInput("return_qty");
      setindex1(index);
      // }
    } else {
      alert("Return Quantity cannot be more than the Shipped Quantity");
    }
  };

  // const handleChangeProduct1 = async (selectedOption, event) => {
  //   TimeoutUtility.resetTimeout();
  //   if (event.key === "Enter" && selectedOption && selectedOption.value) {
  //     setSelectedProduct(selectedOption);
  //     setqty(1);
  //     await getAllVendorsNameByPurchaseProduct(selectedOption.values)
  //       .then((resp) => {
  //         setGetSupplier(resp.data || []);
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //     setActiveInput("product");
  //     setProducts(selectedOption);
  //     const selectedProduct = selectedOption.value;
  //     setp_code(selectedProduct);
  //     handleAddcartClick();
  //   }
  // };

  // const handleChangeProduct = async (selectedOption) => {
  //   TimeoutUtility.resetTimeout();
  //   if (selectedOption && selectedOption.values) {
  //     setSelectedProduct(selectedOption);
  //     setqty(1);
  //     await getAllVendorsNameByPurchaseProduct(selectedOption.values)
  //       .then((resp) => {
  //         setGetSupplier(resp.data || []);
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //     var ProductStr1 = ProductStr;
  //     ProductStr1 = ProductStr1 + selectedOption.values + " ";
  //     setProductStr(ProductStr1);
  //     setActiveInput("product");
  //     setProducts(selectedOption);
  //     const selectedProduct = selectedOption.values;
  //     setp_code(selectedProduct);
  //   }
  // };

  const handleChangeNote = (e) => {
    setnote(e.target.value);
  };

  const handleChangeAmountPaid = (e) => {
    setActiveInput("amount_paid");
    setamount_paid(e.target.value);
    setindex1(-4);
  };
  // const handleChangeProjectName2 = (e) => {
  //   setActiveInput("shipment1");
  //   setprojectname2(e.target.value);
  //   setindex1(-3);
  // };
  // const handleChangeProjectName1 = (e) => {
  //   setprojectname1(e.target.value);
  // };

  const handleChangeProjectName = (e) => {
    setprojectname(e.target.value);
  };

  const handleChangeTrackingNo = (e) => {
    settrackingno(e.target.value);
  };

  const handleChangeShipMethod = (e) => {
    setshipmethod(e.target.value);
  };

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  // const getCustomerDetail = async (id) => {
  //   try {
  //     const resp = await GetSaleCustomerById(id);
  //     setCustomerDetail(resp.data || []);
  //     // console.log(resp.data[0].phone);
  //     setcusPhone(resp.data[0].phone);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const getProductsAll = async () => {
  //   try {
  //     const resp = await GetProductByStoreID(store_id_param);
  //     setGetProduct(resp.data || []);
  //     //console.log(resp.data);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const handleInputChange = (inputValue) => {
  //   // if (customer_ids) {
  //   if (customer_ids) {
  //     if (inputValue && inputValue.length >= 2) {
  //       const searchResults = getProductFilterSubString(
  //         store_id_param,
  //         inputValue,
  //         ProductStr
  //       );
  //       searchResults
  //         .then((response) => {
  //           const productsArray = response.data;
  //           // const filteredProducts = productsArray.filter(
  //           //   (product) =>
  //           //     !CardList.some((item) => item.product_id === product.product_id)
  //           // );
  //           //const newProductOptions = filteredProducts.map((item) => ({
  //           const newProductOptions = productsArray.map((item) => ({
  //             values: item.product_id,
  //             value: `${item.code} ${item.productname} ${
  //               item.details
  //             } ${formatCurrency(item.unit_price)}`.toLowerCase(),
  //             label: (
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   marginTop: "4px",
  //                 }}
  //               >
  //                 <div
  //                   style={{
  //                     flex: "0 0 10%",
  //                   }}
  //                 >
  //                   <img
  //                     className="rounded-xl"
  //                     src={`data:image/jpeg;base64,${item.image}`}
  //                     alt={`Product ${item.code}`}
  //                     style={{
  //                       maxWidth: "50px",
  //                       height: "50px",
  //                       objectFit: "cover",
  //                     }}
  //                   />
  //                 </div>
  //                 <div
  //                   style={{
  //                     flex: "0 0 80%",
  //                     paddingLeft: "0px",
  //                   }}
  //                 >
  //                   <div>
  //                     <div style={{ fontWeight: "bold", fontSize: "18px" }}>{`${
  //                       item.code
  //                     } ${truncate(item.productname, 75)}`}</div>
  //                   </div>
  //                   <div style={{ fontSize: "14px" }}>{`${truncate(
  //                     item.details,
  //                     120
  //                   )}`}</div>
  //                 </div>
  //                 <div
  //                   style={{
  //                     flex: "0 0 10%",
  //                     fontWeight: "500",
  //                     color: currentColor,
  //                     textAlign: "right",
  //                     verticalAlign: "middle",
  //                     paddingTop: "10px",
  //                     paddingBottom: "10px",
  //                     fontSize: "16px",
  //                   }}
  //                 >{`${formatCurrency(item.unit_price)}`}</div>
  //               </div>
  //             ),
  //           }));
  //           setProductOptions(newProductOptions);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching products:", error);
  //         });
  //     } else {
  //       // setProductOptions(productOptions1);
  //     }
  //   }
  //   // }
  // };
  // const customFilter = (option, inputValue) => {
  //   const optionSearchField = String(option.value).toLowerCase();
  //   const lowerCasedInput = inputValue.toLowerCase();
  //   return optionSearchField.includes(lowerCasedInput);
  // };

  // const UpdateStatus = async (stat, id) => {
  //   // imgg.preventDefault();
  //   const response = await EditSaleOrderStatusBYSo_id(stat, id);
  //   if (response.status === 200) {
  //     // if(response.data[0][0].result){
  //     alert("Sale order status updated successfully.");
  //     // }
  //     // else{
  //     //   alert("Order Status Failed to Update!");
  //     // }
  //   } else {
  //     alert("Failed to update sale order status.");
  //   }
  // };

  const handleSaleOrderClick = async (e) => {
    TimeoutUtility.resetTimeout();
    if (CardList.length === 0) {
      alert("Sales order cart is empty.");
      return;
    }
    //console.log(unshippedList);
    setIsPageFrozen(true);
    setsaveClick(!saveClick);
  };

  // const handlePrintOrderClick = async (e) => {
  //   TimeoutUtility.resetTimeout();
  //   setprint_flag(true);
  //   handleSaleOrderClick();
  // };

  useEffect(() => {
    async function SaveOrder() {
      if (saveClick && userID && userToken) {
        const updatedFormData = { ...formData };
        updatedFormData.so_id = so_id_param;
        // updatedFormData.est_sale = "S";
        updatedFormData.customer_id = customer_ids;
        // updatedFormData.customer_po_no = projectname1;
        updatedFormData.total = grandtotal;
        updatedFormData.amount_pending = grandtotal - amount_paid;
        updatedFormData.amount_paid = amount_paid;
        updatedFormData.sr_note = note;
        updatedFormData.total_price = grandtotal;
        updatedFormData.tax = tax;
        //updatedFormData.discount = totaldiscount;
        updatedFormData.tracking_no = trackingno;
        updatedFormData.project_name = projectname;
        updatedFormData.ship_method = shipmethod;
        //updatedFormData.invoice_id = invoice_id;
        updatedFormData.store_id = store_id_param;
        //updatedFormData.shipment = projectname2;
        updatedFormData.acc_from_id = acc_from_id;
        updatedFormData.acc_from_bal = acc_from_bal;
        updatedFormData.acc_to_id = acc_to_id;
        updatedFormData.acc_to_bal = acc_to_bal;
        updatedFormData.user_id = userID;
        if (dTNow) {
          var d = new Date();
          updatedFormData.o_datetime =
            [
              d.getFullYear(),
              (d.getMonth() + 1).toString().padStart(2, "0"),
              d.getDate().toString().padStart(2, "0"),
            ].join("-") +
            " " +
            [
              d.getHours().toString().padStart(2, "0"),
              d.getMinutes().toString().padStart(2, "0"),
              d.getSeconds().toString().padStart(2, "0"),
            ].join(":");
        } else {
          updatedFormData.o_datetime = oDatetime.replace("T", " ");
        }

        updatedFormData.products = [];
        updatedFormData.receive_logs = [];

        // if (OrderStatus === "MARK AS OPEN") {
        //   updatedFormData.status_id = 81;
        // } else {
        //   updatedFormData.status_id = 80;
        // }
        var ff = 0,
          xx = null;
        const retList = CardList.filter((v) => v.return_qty > 0);
        const uniqueValues = new Set(
          retList.map((item) => String(item.product_id) + item.return_notes)
        );

        if (uniqueValues.size < retList.length) {
          alert(
            `Duplicate notes for same products found.\nSales Return failed to create.`
          );
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          return;
        } else {
          CardList.forEach((product) => {
            //const qtyShippedProduct = null;
            // const qtyShippedProduct = qtyShipped.find(
            //   (item) => parseInt(item.item_no) === parseInt(product.item_no)
            // );
            // const shipprod = unshippedList.find(
            //   (item) => parseInt(item.item_no) === parseInt(product.item_no)
            // );
            // console.log(qtyShippedProduct);
            // console.log(shipprod);
            // console.log("Product:", qtyShipped);
            // console.log("QtyShippedProduct:", unshippedList);
            // if (DirectShipping_flag) {
            if (
              parseFloat(product.return_qty) <= parseFloat(product.shipment)
            ) {
              // if (qtyShippedProduct) {
              //   const shippedQuantity =
              //     parseFloat(product.quantity) -
              //     parseFloat(qtyShippedProduct.shipment);
              //   // console.log(
              //   //   parseFloat(qtyShippedProduct.shipment),
              //   //   parseFloat(product.quantity),
              //   //   parseFloat(product.shipment)
              //   // );
              //   // if (shippedQuantity !== 0) {
              //   const shipment = {
              //     product_id: product.product_id,
              //     s_quantity: product.quantity,
              //     quantity_shipped: shippedQuantity,
              //     shipped_note: product.notes,
              //     old_unit_price: qtyShippedProduct.unit_price,
              //     old_discount: qtyShippedProduct.discount,
              //     old_qty_ship: qtyShippedProduct.shipment,
              //   };
              //   updatedFormData.receive_logs.push(shipment);
              //   // }
              // } else {
              if (product.return_qty > 0) {
                const shipment = {
                  product_id: product.product_id,
                  quantity: product.quantity,
                  quantity_recv: product.return_qty,
                  recv_note: product.return_notes,
                  //recv_by: projectname1,
                  // s_quantity: product.quantity,
                  // quantity_shipped: product.quantity,
                  // shipped_note: product.notes,
                  // old_unit_price: 0,
                  // old_discount: 0,
                  // old_qty_ship: 0,
                };
                updatedFormData.receive_logs.push(shipment);

                const saleProduct = {
                  product_id: product.product_id,
                  //supplier_id: product.supplier_id,
                  so_item_no: product.item_no,
                  quantity: product.return_qty,
                  //quantity: product.quantity,
                  unit_price: product.unit_price,
                  cost_price: product.cost_price,
                  discount: product.discount,
                  item_note: product.return_notes,
                  // req_delivery_date: dt,
                };
                updatedFormData.products.push(saleProduct);
              }
            } else {
              if (ff === 0) {
                //console.log(product);
                xx = product.code;
                ff = 1;
              }
            }
            //} else if (parseFloat(product.shipment) > 0 || shipprod) {
            // } else {
            //   if (
            //     parseFloat(product.shipment) <= parseFloat(product.quantity)
            //   ) {
            //     if (qtyShippedProduct) {
            //       const shippedQuantity =
            //         parseFloat(product.shipment) -
            //         parseFloat(qtyShippedProduct.shipment);
            //       //console.log(parseFloat(qtyShippedProduct.shipment));
            //       //if (shippedQuantity !== 0) {
            //       const shipment = {
            //         product_id: product.product_id,
            //         s_quantity: product.quantity,
            //         quantity_shipped: shippedQuantity,
            //         shipped_note: product.notes,
            //         old_unit_price: qtyShippedProduct.unit_price,
            //         old_discount: qtyShippedProduct.discount,
            //         old_qty_ship: qtyShippedProduct.shipment,
            //       };
            //       updatedFormData.receive_logs.push(shipment);
            //       //}
            //     } else {
            //       const shipment = {
            //         product_id: product.product_id,
            //         s_quantity: product.quantity,
            //         quantity_shipped: product.shipment,
            //         shipped_note: product.notes,
            //         old_unit_price: 0,
            //         old_discount: 0,
            //         old_qty_ship: 0,
            //       };
            //       updatedFormData.receive_logs.push(shipment);
            //     }
            //   } else {
            //     if (ff === 0) {
            //       xx = product.code;
            //       ff = 1;
            //     }
            //   }
            // }
            // var dt = null;
            // if (
            //   product.req_delivery_date !== null ||
            //   product.req_delivery_date !== ""
            // ) {
            //   dt = product.req_delivery_date;
            // }
          });
          if (ff === 1) {
            alert(
              `Return Qty of ${xx} must be less or equal to Shipped Qty.\nSales Return failed to update.`
            );
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }

          // console.log(updatedFormData);
          if (updatedFormData.products.length > 0) {
            const response = await AddSalesReturnApi(
              updatedFormData,
              userID,
              userToken
            );
            if (response.status === 200) {
              if (print_flag && so_id_param) {
                alert("Sales order updated successfully.");
                //await openNewTab(so_id_param);
                setprint_flag(false);
                window.close();
                // window.location.reload();
              }
              // if (orderstatuslable === "OPEN") {
              //   // console.log(response.data[0][0].so_id)
              //   if (so_id_param) {
              //     // console.log(response.data[0][0].so_id)
              //     UpdateStatus(80, so_id_param);
              //   } else {
              //     UpdateStatus(81, so_id_param);
              //   }
              // } else {
              //   // return;
              // }
              if (!print_flag) {
                alert("Sales Return created successfully.");
              }
            } else {
              alert("Sales Return failed to create.");
              setsaveClick(!saveClick);
              setIsPageFrozen(false);
              return;
            }
          } else {
            alert(
              `Return Qty must be greater than 0 in order to return a product and save this Sales Return!`
            );
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          if (!print_flag) {
            window.close();
            // window.location.reload();
          }
        }
      }
    }
    SaveOrder();
  }, [saveClick]);

  // const handleStatusClick = async (event) => {
  //   TimeoutUtility.resetTimeout();
  //   event.preventDefault();
  //   if (CardList.length === 0) {
  //     alert("Sales order cart is empty.");
  //     return;
  //   }
  //   if (orderstatuslable === "CLOSE") {
  //     if (window.confirm(`Order is already closed.\nStill want to continue?`)) {
  //       setOrderStatus("MARK AS CLOSE");
  //       setorderstatuslable("OPEN");
  //       return;
  //     } else {
  //       // return;
  //     }
  //   } else {
  //     if (orderstatuslable === "OPEN") {
  //       let f = 0;
  //       CardList.forEach((element) => {
  //         if (!(parseInt(element.quantity) <= parseInt(element.shipment))) {
  //           f = 1;
  //         } else {
  //           setOrderStatus("MARK AS OPEN");
  //           setorderstatuslable("CLOSE");
  //           return;
  //         }
  //       });
  //       if (f === 1) {
  //         if (
  //           window.confirm(
  //             `Quantity is not completely shipped.\nStill want to continue?`
  //           )
  //         ) {
  //           setOrderStatus("MARK AS OPEN");
  //           setorderstatuslable("CLOSE");
  //           return;
  //         }
  //       }
  //     }
  //   }
  // };

  // const handleClearClick = async (event) => {
  //   TimeoutUtility.resetTimeout();
  //   if (window.confirm(`Are you sure you want to Clear the sale order?`)) {
  //     // setcartList([]);
  //     // setshipmethod("");
  //     // settrackingno("");
  //     // setprojectname1("");
  //     // setprojectname2(0);
  //     // setprojectname("");
  //     // settax(0);
  //     // setProductOptions([]);
  //     // setnote("");
  //     // setSelectedProduct("");
  //     // setSelectedCustomer("");
  //     window.location.reload();
  //   }
  // };

  // const handleNewClick = async (event) => {
  //   TimeoutUtility.resetTimeout();
  //   event.preventDefault();
  //   try {
  //     //console.log("Add new");
  //     const path = `/Sales/AddSaleOrder/${store_id_param}`;
  //     window.open(path, "_blank");
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const CalculateAllFields = () => {
    let total_amt = 0,
      total_itm = 0;
    let total_dis = 0;
    let grandTotal = 0.0;
    let taxx = 0;
    //dd = 0;

    if (tax) {
      taxx = parseFloat(tax);
    }

    for (let i = 0; i < CardList.length; i++) {
      let a = 0,
        b = 0,
        c = 0;
      if (CardList[i].total) {
        a = parseFloat(CardList[i].return_total);
      }
      if (CardList[i].discount) {
        b = parseFloat(CardList[i].discount);
      }
      if (CardList[i].quantity) {
        // c = parseFloat(CardList[i].quantity);
        c = parseFloat(CardList[i].return_qty);
      }
      // if (projectname2) {
      //   dd = parseFloat(projectname2);
      // }
      total_amt = total_amt + a;
      total_dis = total_dis + b * c;
      total_itm = total_itm + c;

      // grandTotal = ((parseFloat(CardList[i].unit_price) - parseFloat(CardList[i].discount)) * parseFloat(CardList[i].quantity));
    }
    //settotaldiscount(total_dis);
    settotalAmount(total_amt);
    settotalitem(total_itm);
    taxx = (taxx / 100) * total_amt;
    // grandTotal = total_amt - total_dis + taxx + dd;
    grandTotal = total_amt + taxx;
    // taxx = (taxx / 100) * total_amt;
    // grandTotal = total_amt + taxx + dd;

    setgrandtotal(grandTotal);
  };

  const handleToggle = (value) => {
    TimeoutUtility.resetTimeout();
    // console.log(value);
    // setToggle((pre) => !pre);
    // if (!viewOrderProductOpen) {
    //   openViewOrderProduct();
    // }
    setActiveProdMenuId({
      product_id: value.product_id,
      store_id: store_id_param,
    });
    setActiveProdMenu(true);
    //setproductID(value.product_id);
  };

  // const handleNotesChange = (index, value) => {
  //   const newCartList = [...CardList];
  //   newCartList[index].notes = value;
  //   setcartList(newCartList);
  //   setActiveInput("notes");
  //   setindex1(index);
  // };

  const handleRetNotesChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].return_notes = value;
    setcartList(newCartList);
    setActiveInput("return_notes");
    setindex1(index);
  };

  const handleBackClick = async (event) => {
    TimeoutUtility.resetTimeout();
    if (
      window.confirm(
        `Are you sure you want to Close without saving the changes?`
      )
    ) {
      //console.log(CardList);
      window.close();
    }
  };

  // const handleShipmentClick = (inputId, index) => {
  //   setActiveInput(inputId);
  //   setindex1(index);
  // };

  // const handleShipmentChange = (index, value) => {
  //   const newCartList = [...CardList];
  //   newCartList[index].shipment = value;
  //   setcartList(newCartList);
  //   setActiveInput("shipment");
  //   setindex1(index);
  // };

  // useEffect(() => {
  //   // handleStatusClick();
  //   // console.log(unshippedList);
  // }, [unshippedList]);

  // const handleQuantityChange = (index, value) => {
  //   TimeoutUtility.resetTimeout();
  //   const newCartList = [...CardList];
  //   if (!ValidOrderField(value)) {
  //     if (value !== "") {
  //       newCartList[index].quantity = 0;
  //       newCartList[index].total =
  //         value * parseFloat(newCartList[index].unit_price) -
  //         value * parseFloat(newCartList[index].discount);
  //       setcartList(newCartList);
  //       setActiveInput("quantity");
  //       setindex1(index);
  //       return;
  //     }
  //   }
  //   newCartList[index].quantity = value;
  //   newCartList[index].total =
  //     value * parseFloat(newCartList[index].unit_price) -
  //     value * parseFloat(newCartList[index].discount);
  //   setcartList(newCartList);
  //   setActiveInput("quantity");
  //   setindex1(index);
  // };
  const formatCurrency = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  // const handleDiscountChange = (index, value) => {
  //   const newCartList = [...CardList];
  //   newCartList[index].discount = value;
  //   newCartList[index].total =
  //     newCartList[index].quantity * newCartList[index].unit_price -
  //     newCartList[index].quantity * value;
  //   setcartList(newCartList);
  //   setActiveInput("discount");
  //   setindex1(index);
  // };

  // const handleChangeTax = (e) => {
  //   setActiveInput("tax");
  //   settax(e.target.value);
  //   setindex1(-2);
  // };

  // const handleChangeQty = (e) => {
  //   setActiveInput("qty");
  //   setqty(e.target.value);
  //   //setindex1(-2);
  // };

  // const DeleteProductfrom_SaleOrder = async () => {
  //   const updatedFormData = { ...formData1 };
  //   updatedFormData.store_id = store_id_param;
  //   updatedFormData.invoice_id = invoice_id;
  //   setFormData1(updatedFormData);

  //   try {
  //     await Promise.all(
  //       deleteprodList.map(async (item) => {
  //         if (item.item_no !== 0) {
  //           const deleteFormData = {
  //             ...updatedFormData,
  //             product_id: item.product_id,
  //             item_no: item.item_no,
  //           };
  //           const response = await DeleteEditSaleOrderProduct(deleteFormData);

  //           if (response.status !== 200) {
  //             console.error(`Product ${item.product_id} failed to remove.`);
  //           }
  //         }
  //       })
  //     );
  //   } catch (error) {
  //     console.error("Error during product removal:", error);
  //     alert("An error occurred during product removal.");
  //   }
  // };

  // const DeleteUnshippedProductsfrom_SaleOrder = async () => {
  //   //console.log(unshippedList);
  //   const shippableProductIds = CardList.filter(
  //     (product) => product.shipment > 0
  //   ).map((product) => product.product_id);
  //   const updatedUnshippedList = unshippedList.filter(
  //     (productId) => !shippableProductIds.includes(productId)
  //   );
  //   setunshippedList(updatedUnshippedList);
  //   const updatedFormData = { ...formData1 };
  //   updatedFormData.invoice_id = invoice_id;
  //   setFormData1(updatedFormData);
  //   // console.log("heheh " + updatedUnshippedList);
  //   try {
  //     await Promise.all(
  //       updatedUnshippedList.map(async (productId) => {
  //         const deleteFormData = { ...updatedFormData, product_id: productId };
  //         const response = await RemoveProd_fromShipmentTrans(deleteFormData);

  //         if (response.status !== 200) {
  //           console.error(`Product shipment  ${productId} failed to remove.`);
  //         }
  //       })
  //     );
  //   } catch (error) {
  //     console.error("Error during product shipment removal:", error);
  //     alert("An error occurred during product shipment removal.");
  //   }
  // };

  // const handleDeleteClick = async (index) => {
  //   TimeoutUtility.resetTimeout();
  //   const removedProduct = CardList[index];
  //   //console.log(removedProduct.shipment);
  //   if (removedProduct.shipment === 0) {
  //     if (removedProduct.spec_qty === 0) {
  //       if (
  //         window.confirm(
  //           `Do you want to remove ${removedProduct.code} from cart?`
  //         )
  //       ) {
  //         setdeleteprodList((prevArray) => [
  //           ...prevArray,
  //           {
  //             product_id: removedProduct.product_id,
  //             item_no: removedProduct.item_no,
  //           },
  //         ]);
  //         const item1 = CardList[index].product_id;
  //         const newCartList = [...CardList];
  //         newCartList.splice(index, 1);
  //         setcartList(newCartList);

  //         const currentIdsArray = ProductStr.split(" ");
  //         const updatedIdsArray = currentIdsArray.filter(
  //           (id) => id !== String(item1)
  //         );
  //         const updatedProductIds = updatedIdsArray.join(" ");
  //         setProductStr(updatedProductIds);
  //       }
  //     } else {
  //       alert(
  //         "Special order qty is greater than zero. So, it could not be deleted."
  //       );
  //     }
  //   } else {
  //     alert("Ship qty is greater than zero. So, it could not be deleted.");
  //   }
  // };

  // function formatDate(dateString) {
  //   const date = new Date(dateString);
  //   const month = date.getMonth() + 1; // Adding 1 because getMonth() returns 0-indexed month
  //   const day = date.getDate();
  //   const year = date.getFullYear();
  //   return `${month.toString().padStart(2, "0")}/${day
  //     .toString()
  //     .padStart(2, "0")}/${year}`;
  // }

  useEffect(() => {
    if (dTNow === 0) {
      if (allAccTo.length > 0) {
        const filteredAccTos = allAccTo.filter(
          (item) => new Date(item.open_datetime) <= new Date(oDatetime)
        );
        setGetacc_tos(filteredAccTos);
      }
    } else {
      if (allAccTo.length > 0) {
        //console.log(GetCustomer);
        const filteredAccTos = allAccTo.filter(
          (item) => new Date(item.open_datetime) <= new Date(oDatetime)
        );
        setGetacc_tos(filteredAccTos);
      }
    }
  }, [oDatetime]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (store_id_param && userID && userToken) {
        await getAccNamesAssetCash(store_id_param, userID, userToken)
          .then((resp) => {
            setAllAccTo(resp.data || []);
            setGetacc_tos(resp.data || []);
            //console.log(resp.data);
            //aa = resp.data;
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [store_id_param, userID, userToken]);

  useEffect(() => {
    async function fetchData() {
      try {
        setacc_to(getacc_tos[0]);
        var bal = getacc_tos[0];
        setacc_to_id(bal.account_id);
        setacc_to_bal(bal.end_balance);
        //console.log(bal.end_balance);
      } catch (err) {}
    }
    fetchData();
  }, [getacc_tos]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (c_type_id === 1600) {
          setamount_paid(grandtotal);
        } else {
          setamount_paid(0);
        }
      } catch (err) {}
    }
    fetchData();
  }, [c_type_id, grandtotal]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (so_id_param && userID && userToken) {
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        setODatetime(now.toISOString().slice(0, 19));

        await GetAccToIdBySaleOrder(so_id_param, userID, userToken)
          .then((resp) => {
            setacc_from_bal(resp.data[0].end_balance);
            //setacc_to(resp.data[0].name);
          })
          .catch((err) => {
            console.log(err.message);
          });

        await GetSaleOrderCustomerByID(so_id_param, userID, userToken)
          .then((resp) => {
            var est_dt = new Date(resp.data[0].sale_date);
            est_dt.setMinutes(est_dt.getMinutes() - est_dt.getTimezoneOffset());
            setOMinDatetime(est_dt.toISOString().slice(0, 19));
            var d1 = new Date(resp.data[0].sale_date);
            setSDatetime(d1.toLocaleString());
            setstore_id_param(resp.data[0].store_id);
            // setreq_date(resp.data[0].req_delivery_date);
            setcusFlag(
              // resp.data[0].customer_id + " " +
              resp.data[0].name
            );
            setcustomer_ids(resp.data[0].customer_id);
            //setprojectname1(resp.data[0].customer_po_no);
            //setcusPhone(resp.data[0].customer_po_no);
            //settrackingno(resp.data[0].tracking_no);
            setprojectname(resp.data[0].project_name);
            //setshipmethod(resp.data[0].ship_method);
            settax(resp.data[0].tax);
            //setnote(resp.data[0].so_note);
            //setinvoice_id(resp.data[0].invoice_id);
            //setprojectname2(resp.data[0].shipment);
            //setstatus_id(resp.data[0].status_id);
            //setamount_paid(resp.data[0].amount_paid);
            setc_type_id(resp.data[0].type_id);
            setacc_from_id(resp.data[0].account_id);
            setppo_id(resp.data[0].po_id);
            if (resp.data[0].status_id === 81) {
              //setOrderStatus("CLOSE");
              setorderstatuslable("CLOSE");
            } else {
              //setOrderStatus("OPEN");
              setorderstatuslable("OPEN");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
        //setcartList([]);
        // getProductsAll();
        //   getProductsAll();
        await GetSaleOrderDetailsByID(so_id_param, userID, userToken).then(
          function (result) {
            //console.log(result.data);

            if (result.data) {
              const productList = result.data.map((item) => {
                return {
                  product_id: item.product_id,
                  name: item.product_name,
                  code: item.code,
                  unit_price: item.unit_price,
                  cost_price: item.cost_price,
                  quantity: item.quantity,
                  discount: item.discount,
                  total: (item.unit_price - item.discount) * item.quantity,
                  item_no: item.item_no,
                  shipment: item.product_shipped,
                  image: item.image,
                  details: item.details,
                  return_qty: 0,
                  return_total: 0,
                  return_notes: "",
                  notes: item.item_note,
                  //req_delivery_date: reqDeliveryDate,
                  supplier_id: item.vendor_id,
                  supplier_name: item.vendor,
                  spec_qty: item.quantity_req,
                };
              });

              //console.log(result.data);

              setcartList(() => [...productList]);
              // setcartList_old((prevProductList) => [
              //   ...prevProductList,
              //   ...productList,
              // ]);

              // const qtyShippedList = productList.map((product) => ({
              //   product_id: product.product_id,
              //   shipment: product.shipment,
              //   spec_qty: product.spec_qty,
              //   unit_price: product.unit_price,
              //   discount: product.discount,
              //   item_no: product.item_no,
              //   notes: product.item_note,
              // }));

              // setqtyShipped((prevQtyShippedList) => [
              //   ...prevQtyShippedList,
              //   ...qtyShippedList,
              // ]);

              // // setqtyShipped([CardList]);
              // const unshippedSet = new Set();

              // productList.forEach((product, index) => {
              //   if (product.shipment > 0) {
              //     unshippedSet.add({
              //       product_id: product.product_id,
              //       item_no: product.item_no,
              //     });
              //   }
              // });
              // const unshippedArray = Array.from(unshippedSet);
              // setunshippedList(unshippedArray);

              //console.log(unshippedArray);
            }
          }
        );
        // const productIdsInCart = CardList.map((product) => product.product_id);
        // setProductOptions((prevOptions) =>
        //   prevOptions.filter((option) => !productIdsInCart.includes(option.value))
        // );
      }
    }
    fetchData();
  }, [so_id_param, userID, userToken]);

  // useEffect(() => {
  //   const fetchProductOptions = async () => {
  //     const fetchedProductOptions = GetProduct.map((item) => ({
  //       label: `${item.code} ${item.productname}`,
  //       value: item.product_id,
  //     }));
  //     setProductOptions(fetchedProductOptions);
  //   };
  //   fetchProductOptions();
  // }, [GetProduct, customer_ids]);

  // useEffect(() => {
  //   const fetchCustomerOptions = async () => {
  //     const fetchedCustomerOption = GetCustomer.map((item) => ({
  //       label: `${item.customer}`,
  //       value: item.customer_id,
  //       balance: item.balance,
  //       account_id: item.account_id,
  //     }));
  //     setCustomerOptions(fetchedCustomerOption);
  //     //setcartList([]);
  //   };
  //   fetchCustomerOptions();
  // }, [GetCustomer]);

  // useEffect(() => {
  //   const fetchSupplierOptions = async () => {
  //     if (GetSupplier) {
  //       const fetchedSupplierOption = GetSupplier.map((item) => ({
  //         label: `${item.vendor}`,
  //         value: item.vendor_id,
  //         balance: item.balance,
  //         account_id: item.account_id,
  //       }));
  //       //console.log(fetchedCustomerOption);
  //       //setDefaultSupplier(fetchedSupplierOption[0]);
  //       setSupplierOptions(fetchedSupplierOption);
  //       setSelectedSupplier(fetchedSupplierOption[0]);
  //       if (fetchedSupplierOption[0]) {
  //         setsupplier_ids(fetchedSupplierOption[0].value);
  //         setsupplier_name(fetchedSupplierOption[0].label);
  //         //getCustomerDetail(fetchedCustomerOption[0].value);
  //       }
  //     }
  //     //setcartList([]);
  //   };
  //   fetchSupplierOptions();
  // }, [GetSupplier]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    CalculateAllFields();
    //console.log(cusFlag);
    //setSelectedCustomer(cusFlag);
    // const productIdsInCart = CardList.map((product) => product.product_id);
    // setProductOptions((prevOptions) =>
    //   prevOptions.filter((option) => !productIdsInCart.includes(option.values))
    // );
  }, [CardList]);

  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     maxHeight: "70px",
  //     height: "70px",
  //     fontSize: "14px",
  //   }),
  //   option: (provided) => ({
  //     ...provided,
  //     maxHeight: "70px",
  //     fontSize: "14px",
  //   }),
  //   menuList: (provided) => ({
  //     ...provided,
  //     maxHeight: "250px",
  //   }),
  // };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      {isPageFrozen && <div className="overlay-freeze" />}
      <Container fluid className="g-0 p-0 justify-end">
        <Row
          xs={1}
          sm={1}
          // className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={5} className="container-col">
            <Header title="ADD SALES RETURN" />
          </Col>
          <Col md={2} className="container-col">
            <label className="label" style={{ fontSize: "12px" }}>
              <span style={{ paddingLeft: "8px" }}>Sale DateTime:</span>
            </label>
            <input
              disabled
              className="input"
              id="e_date"
              // type="datetime-local"
              style={{
                textAlign: "left",
                fontSize: "12px",
                width: "178px",
                background: "lightgray",
              }}
              value={sDatetime}
            />
          </Col>
          <Col md={2} className="container-col">
            <label className="label" style={{ fontSize: "12px" }}>
              <span style={{ paddingLeft: "8px", paddingRight: "36px" }}>
                DateTime:
              </span>
              <span>
                Now{" "}
                <input
                  type="checkbox"
                  checked={dTNow === 1}
                  onChange={handleChangeDTNow}
                  style={{
                    width: "14px",
                    height: "14px",
                  }}
                />
              </span>
            </label>
            <input
              disabled={dTNow}
              className="input"
              id="o_date"
              type="datetime-local"
              style={{
                textAlign: "left",
                fontSize: "12px",
                width: "178px",
                background: dTNow && "lightgray",
              }}
              value={oDatetime}
              min={oMinDatetime}
              max={oMaxDatetime}
              onChange={(e) => handleChangeODateTime(e.target.value)}
            />
          </Col>
          <Col md={3} className="container-col">
            <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
              }}
            >
              ORDER STATUS: {orderstatuslable}
            </label>
            <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
                // textAlign: "right",
              }}
            >
              SO# {so_id_param}
            </label>
          </Col>
        </Row>
        <Row
          xs={1}
          sm={1}
          className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={10} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <label className="label" style={{ fontSize: "18px" }}>
                    Customer:
                  </label>
                  <div className="sale-input-div">
                    <div className="sale-input">
                      <input
                        className="input"
                        style={{
                          width: "100%",
                          height: "36px",
                        }}
                        type="text"
                        value={cusFlag}
                        readOnly
                        disabled
                      />
                    </div>
                    <div
                      className="sale-bal ml-4"
                      style={{ backgroundColor: currentColor }}
                    >
                      <label>{formatCurrency(acc_from_bal)}</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              {/* <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="ProductSelect"
                      className="label"
                      style={{ fontSize: "18px", flex: "56%" }}
                    >
                      Product:
                    </label>
                    <label
                      className="label"
                      style={{ fontSize: "18px", flex: "12%" }}
                    >
                      Quantity:
                    </label>
                    <label
                      className="label"
                      style={{ fontSize: "18px", flex: "32%" }}
                    >
                      Supplier:
                    </label>
                  </div>
                  <div className="sale-input-div">
                    <div className="sale-input">
                      <Select
                        className="myreact-select-prod"
                        id="product"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        value={selectedProduct}
                        onChange={handleChangeProduct}
                        options={productOptions}
                        isSearchable
                        placeholder="Search Product With Name / Code"
                        //isClearable
                        styles={customStyles}
                        onKeyDown={(event) =>
                          handleChangeProduct1(selectedProduct, event)
                        }
                        filterOption={customFilter}
                        onInputChange={handleInputChange}
                      />
                      <input
                        id="qty"
                        type="number"
                        step="1"
                        min="0"
                        max="1000000"
                        className="input myreact-select-prod-qty"
                        //defaultValue={1.0}
                        value={qty}
                        placeholder="Quantity"
                        //onClick={() => handleInputClick("tax", -2)}
                        onChange={handleChangeQty}
                      />
                      <Select
                        className="myreact-select-supp"
                        id="supplier"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        value={selectedSupplier}
                        onChange={handleChangeSupplier}
                        options={SupplierOptions}
                        isSearchable
                        placeholder="Product Supplier"
                        isClearable
                        styles={customStyles}
                      />
                    </div>
                    <button
                      className="sale-bal ml-4"
                      type="button"
                      style={{
                        backgroundColor: currentColor,
                        fontWeight: "1000",
                        fontSize: "18px",
                        height: "100%",
                      }}
                      onClick={handleAddcartClick}
                    >
                      +
                    </button>
                  </div>
                </Col>
              </Row> 
              <br />
              <br />*/}
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale"
                style={{ height: "57vh" }}
              >
                <div className="m-0 p-0">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="table-sale-tr">
                        <th style={{ width: "80px" }}>CODE</th>
                        <th style={{ width: "320px" }}>PRODUCT</th>
                        <th style={{ width: "75px" }}>PRICE</th>
                        <th style={{ width: "75px" }}>DISC</th>
                        <th style={{ width: "65px" }}>QTY</th>
                        <th style={{ width: "90px" }}>SUPPLIER</th>
                        <th style={{ width: "180px" }}>ITEM NOTES</th>
                        <th style={{ width: "65px" }}>SHIP QTY</th>
                        <th style={{ width: "90px" }}>RET QTY</th>
                        <th style={{ width: "90px" }}>RET TOTAL</th>
                        <th style={{ width: "240px" }}>RETURN NOTES</th>
                      </tr>
                    </thead>
                    <tbody className="pos-table-body">
                      {CardList?.map((item, index) => (
                        <tr key={index}>
                          <td onClick={() => handleToggle(item)}>
                            {item.code}
                          </td>
                          <td onClick={() => handleToggle(item)}>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {item.image && (
                                <div
                                  className="image flex gap-4"
                                  style={{
                                    maxWidth: "72px",
                                    height: "72px",
                                    flex: "0 0 20%",
                                  }}
                                >
                                  <img
                                    className="rounded-xl"
                                    src={`data:image/jpeg;base64,${item.image}`}
                                    alt={`Product ${item.code}`}
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  flex: item.image && "0 0 80%",
                                  paddingTop: !item.image && "8px",
                                  paddingBottom: !item.image && "7px",
                                  alignSelf: "center",
                                }}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  {truncate(item.name, item.image ? 38 : 44)}
                                </div>
                                <div>
                                  {truncate(item.details, item.image ? 78 : 88)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>{formatCurrency(item.unit_price)}</td>
                          <td>{formatCurrency(item.discount)}</td>
                          <td>{item.quantity}</td>
                          <td>{item.supplier_name}</td>
                          <td>{item.notes}</td>
                          <td>{item.shipment}</td>
                          <td>
                            <div className="centered-input">
                              <input
                                disabled={ppo_id}
                                className="input"
                                id="return_qty"
                                type="number"
                                min="0"
                                max={item.shipment}
                                step="1.00"
                                value={item.return_qty}
                                onClick={() =>
                                  handleSpecQtyClick("return_qty", index)
                                }
                                onChange={(e) =>
                                  handleSpecQtyChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>{formatCurrency(item.return_total)}</td>
                          <td>
                            <div className="centered-input">
                              <textarea
                                className="textarea"
                                id="return_notes"
                                type="text"
                                value={item.return_notes}
                                onClick={() =>
                                  handleInputClick("return_notes", index)
                                }
                                onChange={(e) =>
                                  handleRetNotesChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale1"
              >
                <table className="borderless w-100 h-100">
                  <tbody>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ paddingTop: "8px" }}
                      >
                        SUB TOTAL:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          paddingTop: "8px",
                        }}
                      >
                        {formatCurrency(total_amount)}
                      </td>
                      <td
                        className="table-grand-sum"
                        rowSpan="7"
                        style={{ backgroundColor: currentColor }}
                      >
                        <div className="tgs-label">
                          GRAND TOTAL
                          <br />
                          <span className="tgs-value">
                            {formatCurrency(grandtotal)}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">TAX:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"%"}
                        </label>
                        <input
                          id="tax"
                          type="number"
                          step="1"
                          min="0"
                          max="100"
                          className="input table-sum-tb"
                          disabled
                          value={tax}
                          style={{
                            color: currentColor,
                            background: "lightgray",
                          }}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="table-sum-label">SHIPPING:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"$"}
                        </label>
                        <input
                          // defaultValue={0.0}
                          type="number"
                          name="shipment"
                          min="0"
                          step="1.00"
                          value={projectname2}
                          onChange={handleChangeProjectName2}
                          onClick={() => handleInputClick("shipment1", -3)}
                          className="input table-sum-tb"
                          style={{
                            color: currentColor,
                            width: "182px",
                          }}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td className="table-sum-label">AMOUNT RETURNED:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"$"}
                        </label>
                        <input
                          type="number"
                          name="shipment"
                          min="0"
                          step="1.00"
                          disabled={c_type_id === 1600 ? true : false}
                          value={amount_paid}
                          onChange={handleChangeAmountPaid}
                          onClick={() => handleInputClick("amount_paid", -4)}
                          className="input table-sum-tb"
                          style={{
                            color: currentColor,
                            marginLeft: "13px",
                            background: c_type_id === 1600 && "lightgray",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">RETURNED FROM:</td>
                      <td className="table-sum-cash">
                        <select
                          className="select table-sum-tb"
                          style={{
                            color: currentColor,
                            marginLeft: "22px",
                          }}
                          value={acc_to}
                          onChange={handleAccToChange}
                        >
                          {getacc_tos.map((item) => (
                            <option key={item.account_id}>{item.name}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ marginBottom: "8px" }}
                      >
                        TOTAL ITEMS:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          marginBottom: "8px",
                        }}
                      >
                        {total_item || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </div>
          </Col>

          <Col md={2} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleSaleOrderClick}
                  >
                    <div className="action-btn">SAVE</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: "gray",
                      // background: currentColor,
                    }}
                    // onClick={handlePrintOrderClick}
                  >
                    <div className="action-btn">PRINT</div>
                  </Card>
                </Col>
              </Row>
              {/* <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleNewClick}
                  >
                    <div className="action-btn">NEW</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleClearClick}
                  >
                    <div className="action-btn">CLEAR</div>
                  </Card>
                </Col>
              </Row> */}
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                {/* <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      disabled: true,
                      background: "gray",
                    }}
                    //onClick={handleStatusClick}
                  >
                    <div className="action-btn">{OrderStatus}</div>
                  </Card>
                </Col> */}
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleBackClick}
                  >
                    <div className="action-btn">CLOSE</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <Col md={12} className="container-col right-table-field">
                  <label>Ship Method:</label>
                  <input
                    type="text"
                    name="shipmethod"
                    placeholder="Ship-Method"
                    value={shipmethod}
                    onChange={handleChangeShipMethod}
                    className="input"
                  />
                </Col>
                <Col md={12} className="container-col right-table-field">
                  <label>Tracking No:</label>
                  <input
                    type="text"
                    name="tracking_no"
                    value={trackingno}
                    onChange={handleChangeTrackingNo}
                    placeholder="Tracking No."
                    className="input"
                  />
                </Col>
                <Col md={12} className="container-col right-table-field">
                  <label>Project Name:</label>
                  <input
                    type="text"
                    name="project name"
                    value={projectname}
                    onChange={handleChangeProjectName}
                    placeholder="Project Name"
                    className="input"
                  />
                </Col>
                <Col
                  md={12}
                  className="container-col right-table-field"
                  style={{
                    paddingBottom: "16px",
                  }}
                >
                  <label>Sales Return Note:</label>
                  <textarea
                    placeholder="Sales Return Note"
                    id="noteTextarea"
                    value={note}
                    onChange={handleChangeNote}
                    className="textarea"
                  />
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{ padding: "0px" }}
              >
                {keypadButtons.map((number, index) => (
                  <Col
                    md={4}
                    className="container-col"
                    key={index}
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <Card
                      className="keypad-button1"
                      style={{
                        border: "1px solid " + currentColor,
                        color: currentColor,
                      }}
                      key={number}
                      onClick={() => handleKeypadClick(number)}
                    >
                      {number}
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          {/* {toggle && (
            <div className="overlay1">
              <Sidebar
                close={() => setToggle(false)}
                product_id={productID}
                store_id={store_id_param}
              />
            </div>
          )} */}
        </Row>
      </Container>
    </div>
  );
};
export default AddSalesReturn;
