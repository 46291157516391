import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  GetAllAccountsByStore,
  //CheckDefaultAcc,
  CheckAccDeleteStatus,
  DeleteAccount,
} from "../../api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const Account = () => {
  const [AllAccounts, setAllAccounts] = useState("");
  const [Account_id, setAccount_id] = useState("");
  const [defaultacc, setdefaultacc] = useState(0);
  const [acc_Status, setacc_Status] = useState(1);
  // const [store, setstore] = useState("");
  // const [store_id, setstore_id] = useState("");
  // const [getstores, setstores] = useState([]);
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(false);
  const { currentColor, storeG, userPermID, userID, userToken } =
    useStateContext();
  const navigate = useNavigate();

  const customersGrid = [
    {
      headerText: "Code",
      field: "code",
      minWidth: "110",
      width: "110",
      maxWidth: "120",
      textAlign: "right",
    },
    {
      headerText: "Account Name",
      field: "name",
      minWidth: "180",
      width: "240",
      maxWidth: "420",
      textAlign: "left",
    },
    {
      field: "description",
      headerText: "Description",
      minWidth: "160",
      width: "320",
      maxWidth: "590",
      textAlign: "left",
    },
    {
      field: "balance",
      headerText: "Balance",
      format: "C2",
      minWidth: "125",
      width: "130",
      maxWidth: "140",
      textAlign: "right",
    },
    {
      field: "type",
      headerText: "Type",
      minWidth: "120",
      width: "130",
      maxWidth: "140",
      textAlign: "left",
    },
    {
      field: "category",
      headerText: "Category",
      minWidth: "170",
      width: "180",
      maxWidth: "190",
      textAlign: "left",
    },
  ];

  const handleAddEmployeesClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("Add new");
      navigate(`/Account/AddAccount/${storeG.store_id}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditEmployeesClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("edit new");
      if (defaultacc === 0) {
        if (Account_id !== "") {
          navigate(`/Account/EditAccount/${Account_id}`);
        } else {
          alert("Please select account to edit.");
        }
      } else {
        alert(`A${Account_id} is Defualt account.\nIt is not editable.`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick = async (event) => {
    // event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      // console.log("view account");
      if (Account_id !== "") {
        navigate(`/Account/ViewAccount/${Account_id}`);
      } else {
        alert("Please select account to view.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleChangeStore = (e) => {
  //   TimeoutUtility.resetTimeout();
  //   setstore(e.target.value);
  //   const s_id = getstores.find((item) => item.name === e.target.value);
  //   setstore_id(s_id.store_id);
  //   const Sale_Store1 = {
  //     SaleStore1: e.target.value,
  //     SaleId: s_id.store_id,
  //   };
  //   localStorage.setItem("Sale_Store1", JSON.stringify(Sale_Store1));
  // };

  const handleViewEmployeesClick1 = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      // console.log("delete account");
      if (Account_id !== "" && userID && userToken) {
        if (defaultacc === 0) {
          if (acc_Status === 0) {
            if (
              window.confirm(`Are you sure you want to Delete A${Account_id}?`)
            ) {
              const response = await DeleteAccount(
                Account_id,
                userID,
                userToken
              );
              //console.log(response, "Response");
              if (response.status === 200) {
                window.location.reload();
                alert("Account deleted successfully.");
              } else {
                alert("Account failed to delete.");
              }
            }
            //console.log(acc_Status);
          } else {
            alert(
              `A${Account_id} ledger transactions exist.\nIt could not be deleted.`
            );
          }
        } else {
          alert(`A${Account_id} is Defualt account.\nIt could not be deleted.`);
        }
      } else {
        alert("Please select account to delete.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAccLedgRepClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      navigate(`/Account/LedgerReport`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    const selectedRowData = args.data;
    const resp1 = CheckAccDeleteStatus(
      selectedRowData.account_id,
      userID,
      userToken
    );
    resp1
      .then(function (result) {
        //console.log(result.data[0].status);
        setacc_Status(result.data[0].status);
      })
      .catch((err) => {
        //console.log(err.message);
      });
    setAccount_id(selectedRowData.account_id);
    //console.log(selectedRowData.account_id);
    setdefaultacc(selectedRowData.default);
  };

  // useEffect(() => {
  //   TimeoutUtility.resetTimeout();
  //   async function fetchData() {
  //     var a = null,
  //       b = null,
  //       d = [];
  //     trackPromise(
  //       Promise.all([GetAllStores()])
  //         .then(([resp]) => {
  //           setstores(resp.data || []);
  //           d = resp.data || [];
  //           // setstore(resp.data[0].name);
  //           // setstore_id(resp.data[0].store_id);
  //           a = resp.data[0].name;
  //           b = resp.data[0].store_id;
  //           //console.log(a, b);
  //         })
  //         .catch((err) => {
  //           console.log(err.message);
  //         })
  //         .finally(() => {
  //           // console.log(d.length);
  //           if (
  //             !JSON.parse(localStorage.getItem("Sale_Store1")) ||
  //             d.length === 1
  //           ) {
  //             const Sale_Store1 = {
  //               SaleStore1: a,
  //               SaleId: b,
  //             };
  //             localStorage.setItem("Sale_Store1", JSON.stringify(Sale_Store1));
  //           }
  //           const Sale_Store1 = JSON.parse(localStorage.getItem("Sale_Store1"));
  //           //console.log(Sale_Store1["SaleStore1"]);

  //           setstore(Sale_Store1["SaleStore1"]);
  //           setstore_id(Sale_Store1["SaleId"]);
  //           setloading(false);
  //         })
  //     );
  //   }
  //   fetchData();
  // }, []);

  useEffect(() => {
    async function fetchData() {
      if (storeG !== null && storeG.store_id !== 0 && userID && userToken) {
        trackPromise(
          Promise.all([
            GetAllAccountsByStore(storeG.store_id, userID, userToken),
          ])
            .then(([result]) => {
              setAllAccounts(result.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [storeG, userID, userToken]);

  const settings = { checkboxMode: "ResetOnRowClick" };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Accounts" title="GENERAL ACCOUNTS" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {userPermID.includes(5020) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddEmployeesClick}
              />
            </Col>
          )}
          {userPermID.includes(5021) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Update"
                  borderRadius="10px"
                  onClick={handleEditEmployeesClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Delete"
                  borderRadius="10px"
                  onClick={handleViewEmployeesClick1}
                />
              </Col>
            </>
          )}
          {userPermID.includes(5019) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Ledger Report"
                borderRadius="10px"
                onClick={handleAccLedgRepClick}
              />
            </Col>
          )}
          {/* <Col md="auto" style={{ padding: "0" }}>
            <select
              className="select-custom rounded-xl"
              value={store}
              onChange={handleChangeStore}
            >
              {getstores.map((item) => (
                <option key={item.store_id}>{item.name}</option>
              ))}
            </select>
          </Col> */}
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          <GridComponent
            className="custom-grid"
            dataSource={AllAccounts}
            allowPaging={true}
            pageSettings={{ pageSize: 16 }}
            allowSorting
            allowResizing
            toolbar={["Search"]}
            rowSelected={handleRowSelected}
            rowHeight={36}
            selectionSettings={settings}
            recordDoubleClick={
              userPermID.includes(5019) && handleViewEmployeesClick
            }
          >
            <ColumnsDirective>
              {customersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </>
      )}
    </div>
  );
};

export default Account;
