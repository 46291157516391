//Controls Settings of the Application

import { createContext, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  GetAllStores,
  GetUserOnlyPermissions,
  GetUserInfoByID,
  AddUserActivity,
} from "../api";
const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: true,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [activeProdMenu, setActiveProdMenu] = useState(false);
  const [activeProdMenuId, setActiveProdMenuId] = useState({
    product_id: null,
    store_id: null,
  });
  const [activePayment, setActivePayment] = useState(false);
  const [activePaymentId, setActivePaymentId] = useState({
    so_id: null,
    po_id: null,
  });
  const [storeG, setStoreG] = useState({
    store_id: 0,
    name: "",
  });
  const [storesG, setStoresG] = useState([]);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [userID, setUserID] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [userStoreID, setUserStoreID] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  // const [userOrgName, setUserOrgName] = useState("");
  const [userName, setUserName] = useState("");
  const [userPermID, setUserPermID] = useState([]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--my-current-color",
      currentColor
    );
  }, [currentColor]);

  useEffect(() => {
    if (!localStorage.getItem("themeMode")) {
      localStorage.setItem("themeMode", currentMode);
    }
    const storedThemeMode = localStorage.getItem("themeMode");
    setCurrentMode(storedThemeMode);

    if (!localStorage.getItem("colorMode")) {
      localStorage.setItem("colorMode", currentColor);
    }
    const storedColorMode = localStorage.getItem("colorMode");
    setCurrentColor(storedColorMode);

    const userDataString = sessionStorage.getItem("userData");
    if (!userDataString) {
      const userData = { user_id: null, token: null };
      sessionStorage.setItem("userData", JSON.stringify(userData));
    }
    const userData1 = JSON.parse(sessionStorage.getItem("userData"));
    setUserID(userData1.user_id);
    setUserToken(userData1.token);
  }, []);

  useEffect(() => {
    if (storesG.length > 0) {
      if (!JSON.parse(localStorage.getItem("StoreG"))) {
        const Store = {
          store_id: storesG[0].store_id,
          name: storesG[0].name,
        };
        localStorage.setItem("StoreG", JSON.stringify(Store));
      }
      const StoreG = JSON.parse(localStorage.getItem("StoreG"));
      setStoreG(StoreG);
    }
  }, [storesG]);

  useEffect(() => {
    if (userID && userToken && userID !== "" && userToken !== "") {
      const resp = GetAllStores(userID, userToken);
      resp
        .then(function (result) {
          setStoresG(result.data || []);
          //console.log(result.data);
        })
        .catch((err) => {
          console.log(err.message);
        });

      GetUserInfoByID(userID, userID, userToken)
        .then((resp) => {
          setUserName(resp.data[0].username);
          setUserProfile(resp.data[0].profile);
          // setUserOrgName(resp.data[0].org_name);
          setUserStoreID(resp.data[0].store_id);

          if (storesG.length > 0) {
            const s_id = storesG.find(
              (item) => item.store_id === resp.data[0].store_id
            );
            const Store = {
              store_id: s_id.store_id,
              name: s_id.name,
            };
            localStorage.setItem("StoreG", JSON.stringify(Store));
            setStoreG(Store);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      GetUserOnlyPermissions(userID, userID, userToken)
        .then((resp) => {
          var permm = resp.data.map((item) => item.permission_id);
          setUserPermID(permm);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      setUserName("");
      setUserProfile(null);
      // setUserOrgName("");
      setUserStoreID(null);
      setUserPermID([]);
    }
  }, [userID, userToken]);

  const handleLogoutClick = async (event) => {
    // event.preventDefault();
    try {
      AddUserActivity(userID, 2999, "Logout successful", userID, userToken)
        .then((res) => {
          if (res.status === 200) {
            setUserID(null);
            setUserName("");
            // setUserOrgName("");
            setUserPermID([]);
            setUserProfile(null);
            setUserStoreID(null);
            const userData = { user_id: null, token: null };
            sessionStorage.setItem("userData", JSON.stringify(userData));
            window.location.reload();
            return <Navigate to="/login" />;
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
    setThemeSettings(false);
  };

  const setStoreGlobal = (id, name) => {
    if (userPermID.includes(4999)) {
      //setStoreG(id);
      const StoreG = {
        store_id: id,
        name: name,
      };
      //console.log(StoreG);
      setStoreG(StoreG);
      localStorage.setItem("StoreG", JSON.stringify(StoreG));
    }
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
    setThemeSettings(false);
  };

  const setNewUserID = (user_id, user_token) => {
    setUserID(user_id);
    setUserToken(user_token);
    const userData = { user_id: user_id, token: user_token };
    sessionStorage.setItem("userData", JSON.stringify(userData));
  };

  const getUserIDToken = () => {
    return {
      user_id: userID,
      user_token: userToken,
    };
  };

  const handleClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: true });
  };

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        activeProdMenu,
        setActiveProdMenu,
        activeProdMenuId,
        setActiveProdMenuId,
        activePayment,
        setActivePayment,
        activePaymentId,
        setActivePaymentId,
        isClicked,
        setIsClicked,
        handleClick,
        screenSize,
        setScreenSize,
        currentColor,
        currentMode,
        setMode,
        setColor,
        handleLogoutClick,
        themeSettings,
        setThemeSettings,
        storeG,
        setStoreGlobal,
        storesG,
        setStoresG,
        userStoreID,
        userProfile,
        userName,
        userPermID,
        userID,
        userToken,
        setNewUserID,
        getUserIDToken,
        // userOrgName,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
