import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  getAccNamesAssetCash,
  EditSalesReturnApi,
  GetSalesReturnCustomerByID,
  GetSalesReturnDetailsByID,
  GetAccToIdBySalesReturn,
} from "../../api";
import { ValidOrderField } from "../../contexts/Utils";
import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/sale.css";
import { Card } from "react-bootstrap";
import "../../styles/viewCustomer.css";
import { Col, Container, Row } from "react-bootstrap";
import { openNewTab } from "../../contexts/SO_Invoice";

const EditSalesReturn = () => {
  const {
    currentColor,
    setActiveProdMenu,
    setActiveProdMenuId,
    userID,
    userToken,
  } = useStateContext();
  //const navigate = useNavigate();
  //const [GetCustomer, setGetCustomer] = useState([]);
  //const [ProductStr, setProductStr] = useState("");
  //const [Customer, setCustomer] = useState("");
  //const [CustomerOptions, setCustomerOptions] = useState([]);
  //const [GetProduct, setGetProduct] = useState([]);
  //const [product, setProducts] = useState("");
  //const [qty, setqty] = useState(1.0);
  //const [productOptions, setProductOptions] = useState([]);
  //const [p_code, setp_code] = useState("");
  const [customer_ids, setcustomer_ids] = useState("");
  const [CardList, setcartList] = useState([]);
  // const [cardList_old, setcartList_old] = useState([]);
  const [total_amount, settotalAmount] = useState(0);
  const [tax, settax] = useState(0);
  //const [totaldiscount, settotaldiscount] = useState(0.0);
  const [total_item, settotalitem] = useState(0);
  const [index1, setindex1] = useState("");
  //const [status_id, setstatus_id] = useState("");
  const [grandtotal, setgrandtotal] = useState(0);
  const [note, setnote] = useState("");
  //const [CustomerDetail, setCustomerDetail] = useState([]);
  //const [cusPhone, setcusPhone] = useState("");
  const [trackingno, settrackingno] = useState("");
  const [projectname, setprojectname] = useState("");
  const [shipmethod, setshipmethod] = useState("");
  const [cusFlag, setcusFlag] = useState("");
  //const [invoice_id, setinvoice_id] = useState("");
  //const [projectname2, setprojectname2] = useState(0);
  // const [deleteprodList, setdeleteprodList] = useState([]);
  //const [OrderStatus, setOrderStatus] = useState("");
  const [orderstatuslable, setorderstatuslable] = useState("");
  //const [ValError, setValError] = useState([]);
  // const [unshippedList, setunshippedList] = useState([]);
  const [qtyShipped, setqtyShipped] = useState([]);
  //const [projectname1, setprojectname1] = useState("");
  const [acc_from_id, setacc_from_id] = useState("");
  const [acc_from_bal, setacc_from_bal] = useState("");
  //const [acc_to_id, setacc_to_id] = useState("");
  //const [acc_to_bal, setacc_to_bal] = useState("");
  const [amount_paid, setamount_paid] = useState(0);
  const [getacc_tos, setGetacc_tos] = useState([]);
  const [acc_to, setacc_to] = useState("");
  const [store_id_param, setstore_id_param] = useState("");
  const [dot, setdot] = useState(0);
  const [isPageFrozen, setIsPageFrozen] = useState(false);
  const [saveClick, setsaveClick] = useState(false);
  const [ppo_id, setppo_id] = useState(0);
  const [so_id, setso_id] = useState(0);
  const [print_flag, setprint_flag] = useState(false);
  const [sDatetime, setSDatetime] = useState("");
  const [oDatetime, setODatetime] = useState("");

  let param = useParams();
  var sr_id_param = param.sr_id;

  const [formData] = useState({
    sr_id: 0,
    so_id: 0,
    // est_sale: "S",
    customer_id: 0,
    //customer_po_no: "",
    project_name: "",
    ship_method: "",
    tracking_no: "",
    total: 0,
    amount_paid: 0,
    amount_pending: 0,
    user_id: null,
    sr_note: "",
    total_price: 0,
    //discount: 0,
    //tax: 0,
    //status_id: 0,
    // shipment: 0,
    store_id: 0,
    o_datetime: "",
    //invoice_id: 0,
    t_type_id: 709,
    products: [],
    receive_logs: [],
  });

  //   so_id: so_id_param,
  //   store_id: 0,
  //   invoice_id: 0,
  //   product_id: 0,
  // });

  const keypadButtons = [
    "7",
    "8",
    "9",
    "4",
    "5",
    "6",
    "1",
    "2",
    "3",
    ".",
    "0",
    "00",
  ];

  const [activeInput, setActiveInput] = useState(null);
  //const [selectedCustomer, setSelectedCustomer] = useState(null);
  // const [selectedSupplier, setSelectedSupplier] = useState(null);
  // const [supplier_ids, setsupplier_ids] = useState("");
  // const [supplier_name, setsupplier_name] = useState("-");
  // const [SupplierOptions, setSupplierOptions] = useState([]);
  // const [GetSupplier, setGetSupplier] = useState([]);

  const handleInputClick = (inputId, index) => {
    TimeoutUtility.resetTimeout();
    setActiveInput(inputId);
    setindex1(index);
  };
  const handleSpecQtyClick = (inputId, index) => {
    TimeoutUtility.resetTimeout();
    setActiveInput(inputId);
    setindex1(index);
  };
  //   TimeoutUtility.resetTimeout();
  //   const newCartList = [...CardList];
  //   newCartList[index].unit_price = value;
  //   newCartList[index].total =
  //     value * parseFloat(newCartList[index].quantity) -
  //     parseFloat(newCartList[index].quantity) *
  //       parseFloat(newCartList[index].discount);
  //   setcartList(newCartList);
  //   setActiveInput("unit_price");
  //   setindex1(index);
  // };

  const handleKeypadClick = (value) => {
    TimeoutUtility.resetTimeout();
    // try {
    if (activeInput !== null) {
      const newCartList = [...CardList];
      const indexToUpdate = index1;

      if (
        // activeInput === "quantity" ||
        // activeInput === "unit_price" ||
        // activeInput === "discount" ||
        // activeInput === "shipment" ||
        activeInput === "return_qty"
      ) {
        var currentValue = String(
          newCartList[indexToUpdate][activeInput] || ""
        );
        //console.log(currentValue);
        if (value === "." && currentValue.includes(".")) {
          return;
        }

        if (value === "." && !currentValue.includes(".")) {
          if (currentValue === "") {
            currentValue = "0";
          }
          newCartList[indexToUpdate][activeInput] = currentValue + ".0";
          setcartList(newCartList);
          setdot(1);
          return;
        }
        var newValue = currentValue + String(value);
        if (dot === 1) {
          var [integerPart] = currentValue.split(".");
          if (integerPart === "") {
            integerPart = "0";
          }
          newValue = integerPart + "." + value;
          setdot(0);
        }

        if (!isNaN(newValue)) {
          // if (activeInput === "shipment" || activeInput === "spec_order") {
          //   // newValue = String(parseInt(newValue));
          //   if (parseFloat(newValue) > newCartList[indexToUpdate].quantity) {
          //     if (activeInput === "shipment") {
          //       alert("Ship qty must be less or equal to total qty.");
          //     } else {
          //       alert("Special order qty must be less or equal to total qty.");
          //     }

          //     newCartList[indexToUpdate][activeInput] =
          //       newCartList[indexToUpdate][activeInput] + "";
          //     setcartList(newCartList);
          //     setdot(0);
          //     return;
          //   }
          // }
          // newCartList[indexToUpdate][activeInput] = newValue;
          // newCartList[indexToUpdate].total =
          //   newCartList[indexToUpdate].unit_price *
          //     newCartList[indexToUpdate].quantity -
          //   newCartList[indexToUpdate].discount *
          //     newCartList[indexToUpdate].quantity;
          newCartList[indexToUpdate][activeInput] = newValue;
          newCartList[indexToUpdate].return_total =
            newCartList[indexToUpdate].unit_price *
              newCartList[indexToUpdate].return_qty -
            newCartList[indexToUpdate].discount *
              newCartList[indexToUpdate].return_qty;
          //-newCartList[indexToUpdate].discount;

          setcartList(newCartList);
          setdot(0);
        }
        // } else if (activeInput === "tax" && index1 === -2) {
        //   const currentTaxValue = String(tax || "");

        //   if (value === "." && currentTaxValue.includes(".")) {
        //     return;
        //   }

        //   const newValue = currentTaxValue + String(value || "");
        //   if (!isNaN(newValue)) {
        //     settax(newValue);
        //   }
        // } else if (activeInput === "shipment1" && index1 === -3) {
        //   const currentShipmentValue = String(projectname2 || "");

        //   if (value === "." && currentShipmentValue.includes(".")) {
        //     return;
        //   }

        //   const newValue = currentShipmentValue + String(value || "");
        //   if (!isNaN(newValue)) {
        //     setprojectname2(newValue);
        //   }
      } else if (activeInput === "amount_paid" && index1 === -4) {
        const currentShipmentValue = String(amount_paid || "");

        if (value === "." && currentShipmentValue.includes(".")) {
          return;
        }

        const newValue = currentShipmentValue + String(value || "");
        if (!isNaN(newValue)) {
          setamount_paid(newValue);
        }
      }
    }
  };

  //   TimeoutUtility.resetTimeout();
  //   // const isProductInCart = CardList.some(
  //   //   (item) => item.product_id === product.value
  //   // );
  //   // if (!isProductInCart && product.value) {
  //   if (product.value) {
  //     // var supp_id = null;
  //     // if (supplier_ids !== "" && supplier_ids !== null) {
  //     //   supp_id = supplier_ids;
  //     // }
  //     const resp1 = GetProductByIdSale(p_code);
  //     resp1
  //       .then(function (result) {
  //         const defaultProduct = {
  //           product_id: result.data[0].product_id,
  //           name: result.data[0].name,
  //           code: result.data[0].code,
  //           unit_price: result.data[0].unit_price,
  //           cost_price: result.data[0].cost_price,
  //           quantity: qty,
  //           item_no: 0,
  //           discount: result.data[0].discount,
  //           total:
  //             result.data[0].unit_price * qty - result.data[0].discount * qty,
  //           // - result.data[0].discount,
  //           shipment: 0,
  //           image: result.data[0].image,
  //           details: result.data[0].details,
  //           req_delivery_date: null,
  //           supplier_id: supplier_ids,
  //           supplier_name: supplier_name,
  //           spec_qty: 0,
  //           notes: "",
  //         };

  //         setcartList((prevProductList) => [
  //           defaultProduct,
  //           ...prevProductList,
  //         ]);
  //         setProducts("");
  //         setSelectedProduct("");
  //         setSelectedSupplier("");
  //         setqty(1);

  //         setProductOptions((prevOptions) =>
  //           prevOptions.filter(
  //             (option) => option.values !== result.data[0].product_id
  //           )
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }
  //   setSelectedProduct("");
  //   setSelectedSupplier("");
  //   setqty(1);
  // };

  // const handleChangeReqDate = (index, e) => {
  //   const newCartList = [...CardList];
  //   if (e === "undefined") {
  //     newCartList[index].req_delivery_date = null;
  //     //setreq_date(null);
  //   } else {
  //     newCartList[index].req_delivery_date = e;
  //     //setreq_date(e);
  //   }
  //   setcartList(newCartList);
  // };

  // const handleChangeCustomer = (selectedOption) => {
  //   if (selectedOption && selectedOption.value) {
  //     setSelectedCustomer(selectedOption);
  //     setacc_from_id(selectedOption.account_id);
  //     setActiveInput("customer");
  //     setCustomer(selectedOption);
  //     setcustomer_ids(selectedOption.value);
  //     getCustomerDetail(selectedOption.value);
  //     if (selectedOption.value) {
  //       setGetProduct([]);
  //       // getProductsAll();
  //     } else {
  //       setGetProduct([]);
  //     }
  //     setcartList([]);
  //     settax(0);
  //     settotaldiscount(0);
  //     settotalitem(0);
  //     setSelectedProduct("");
  //     setnote("");
  //     settrackingno("");
  //     setprojectname("");
  //     setprojectname1("");
  //     setprojectname2(0);
  //     setshipmethod("");
  //   }
  // };

  // const handleChangeSupplier = (selectedOption) => {
  //   TimeoutUtility.resetTimeout();
  //   if (selectedOption && selectedOption.value) {
  //     setSelectedSupplier(selectedOption);
  //     setActiveInput("customer");
  //     setsupplier_ids(selectedOption.value);
  //     setsupplier_name(selectedOption.label);
  //   } else {
  //     setSelectedSupplier(null);
  //     setActiveInput("customer");
  //     setsupplier_ids("");
  //     setsupplier_name("");
  //   }
  // };

  // const handleAccToChange = (e) => {
  //   TimeoutUtility.resetTimeout();
  //   const acc = getacc_tos.find((item) => item.name === e.target.value);
  //   //console.log(acc.account_id);
  //   setacc_to_bal(acc.end_balance);
  //   setacc_to_id(acc.account_id);
  // };
  const handleSpecQtyChange = (index, value) => {
    const newCartList = [...CardList];
    if (newCartList[index].shipment >= value) {
      const qtyShippedProduct = qtyShipped.find(
        (item) =>
          parseInt(item.item_no) === parseInt(newCartList[index].item_no)
      );
      if (qtyShippedProduct.return_qty <= value) {
        if (!ValidOrderField(value)) {
          if (value !== "") {
            newCartList[index].return_qty = 0;
            newCartList[index].return_total =
              value * parseFloat(newCartList[index].unit_price) -
              value * parseFloat(newCartList[index].discount);
            setcartList(newCartList);
            setActiveInput("quantity");
            setindex1(index);
            return;
          }
        }
        newCartList[index].return_qty = value;
        newCartList[index].return_total =
          value * parseFloat(newCartList[index].unit_price) -
          value * parseFloat(newCartList[index].discount);
        setcartList(newCartList);
        setActiveInput("return_qty");
        setindex1(index);
      } else {
        alert(
          "Return Quantity cannot be less than the previous Returned Quantity"
        );
      }
    } else {
      alert("Return Quantity cannot be more than the Shipped Quantity");
    }
  };

  const handleChangeNote = (e) => {
    setnote(e.target.value);
  };
  const handleChangeProjectName = (e) => {
    setprojectname(e.target.value);
  };
  const handleChangeTrackingNo = (e) => {
    settrackingno(e.target.value);
  };
  const handleChangeShipMethod = (e) => {
    setshipmethod(e.target.value);
  };
  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  //   try {
  //     const resp = await GetSaleCustomerById(id);
  //     setCustomerDetail(resp.data || []);
  //     // console.log(resp.data[0].phone);
  //     setcusPhone(resp.data[0].phone);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const getProductsAll = async () => {
  //   try {
  //     const resp = await GetProductByStoreID(store_id_param);
  //     setGetProduct(resp.data || []);
  //     //console.log(resp.data);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const handleInputChange = (inputValue) => {
  //   // if (customer_ids) {
  //   if (customer_ids) {
  //     if (inputValue && inputValue.length >= 2) {
  //       const searchResults = getProductFilterSubString(
  //         store_id_param,
  //         inputValue,
  //         ProductStr
  //       );
  //       searchResults
  //         .then((response) => {
  //           const productsArray = response.data;
  //           // const filteredProducts = productsArray.filter(
  //           //   (product) =>
  //           //     !CardList.some((item) => item.product_id === product.product_id)
  //           // );
  //           //const newProductOptions = filteredProducts.map((item) => ({
  //           const newProductOptions = productsArray.map((item) => ({
  //             values: item.product_id,
  //             value: `${item.code} ${item.productname} ${
  //               item.details
  //             } ${formatCurrency(item.unit_price)}`.toLowerCase(),
  //             label: (
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   marginTop: "4px",
  //                 }}
  //               >
  //                 <div
  //                   style={{
  //                     flex: "0 0 10%",
  //                   }}
  //                 >
  //                   <img
  //                     className="rounded-xl"
  //                     src={`data:image/jpeg;base64,${item.image}`}
  //                     alt={`Product ${item.code}`}
  //                     style={{
  //                       maxWidth: "50px",
  //                       height: "50px",
  //                       objectFit: "cover",
  //                     }}
  //                   />
  //                 </div>
  //                 <div
  //                   style={{
  //                     flex: "0 0 80%",
  //                     paddingLeft: "0px",
  //                   }}
  //                 >
  //                   <div>
  //                     <div style={{ fontWeight: "bold", fontSize: "18px" }}>{`${
  //                       item.code
  //                     } ${truncate(item.productname, 75)}`}</div>
  //                   </div>
  //                   <div style={{ fontSize: "14px" }}>{`${truncate(
  //                     item.details,
  //                     120
  //                   )}`}</div>
  //                 </div>
  //                 <div
  //                   style={{
  //                     flex: "0 0 10%",
  //                     fontWeight: "500",
  //                     color: currentColor,
  //                     textAlign: "right",
  //                     verticalAlign: "middle",
  //                     paddingTop: "10px",
  //                     paddingBottom: "10px",
  //                     fontSize: "16px",
  //                   }}
  //                 >{`${formatCurrency(item.unit_price)}`}</div>
  //               </div>
  //             ),
  //           }));
  //           setProductOptions(newProductOptions);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching products:", error);
  //         });
  //     } else {
  //       // setProductOptions(productOptions1);
  //     }
  //   }
  //   // }
  // };
  // const customFilter = (option, inputValue) => {
  //   const optionSearchField = String(option.value).toLowerCase();
  //   const lowerCasedInput = inputValue.toLowerCase();
  //   return optionSearchField.includes(lowerCasedInput);
  // };

  // const UpdateStatus = async (stat, id) => {
  //   // imgg.preventDefault();
  //   const response = await EditSaleOrderStatusBYSo_id(stat, id);
  //   if (response.status === 200) {
  //     // if(response.data[0][0].result){
  //     alert("Sale order status updated successfully.");
  //     // }
  //     // else{
  //     //   alert("Order Status Failed to Update!");
  //     // }
  //   } else {
  //     alert("Failed to update sale order status.");
  //   }
  // };

  const handleSaleOrderClick = async (e) => {
    TimeoutUtility.resetTimeout();
    if (CardList.length === 0) {
      alert("Sales order cart is empty.");
      return;
    }
    setIsPageFrozen(true);
    setsaveClick(!saveClick);
  };

  // const handlePrintOrderClick = async (e) => {
  //   TimeoutUtility.resetTimeout();
  //   setprint_flag(true);
  //   handleSaleOrderClick();
  // };

  useEffect(() => {
    async function SaveOrder() {
      if (saveClick && userID && userToken) {
        const updatedFormData = { ...formData };
        updatedFormData.sr_id = sr_id_param;
        updatedFormData.so_id = so_id;
        updatedFormData.customer_id = customer_ids;
        updatedFormData.total = grandtotal;
        updatedFormData.amount_pending = grandtotal - amount_paid;
        updatedFormData.amount_paid = amount_paid;
        updatedFormData.sr_note = note;
        updatedFormData.total_price = grandtotal;
        updatedFormData.tax = tax;
        updatedFormData.tracking_no = trackingno;
        updatedFormData.project_name = projectname;
        updatedFormData.ship_method = shipmethod;
        updatedFormData.store_id = store_id_param;
        updatedFormData.o_datetime = oDatetime.replace("T", " ");
        updatedFormData.acc_from_id = acc_from_id;
        updatedFormData.acc_from_bal = acc_from_bal;
        updatedFormData.user_id = userID;

        updatedFormData.products = [];
        updatedFormData.receive_logs = [];

        var ff = 0,
          xx = null;
        const retList = CardList.filter((v) => v.return_qty > 0);
        const uniqueValues = new Set(
          retList.map((item) => String(item.product_id) + item.return_notes)
        );

        if (uniqueValues.size < retList.length) {
          alert(
            `Duplicate notes for same products found.\nSales Return failed to update.`
          );
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          return;
        } else {
          CardList.forEach((product) => {
            const qtyShippedProduct = qtyShipped.find(
              (item) => parseInt(item.item_no) === parseInt(product.item_no)
            );
            //console.log(qtyShippedProduct);
            if (
              parseFloat(product.return_qty) <= parseFloat(product.shipment)
            ) {
              if (
                parseFloat(qtyShippedProduct.return_qty) <=
                parseFloat(product.return_qty)
              ) {
                if (product.return_qty > qtyShippedProduct.return_qty) {
                  const shipment = {
                    product_id: product.product_id,
                    quantity: product.quantity,
                    quantity_recv:
                      product.return_qty - qtyShippedProduct.return_qty,
                    recv_note: product.return_notes,
                    old_qty_rcv: qtyShippedProduct.return_qty,
                  };
                  updatedFormData.receive_logs.push(shipment);

                  const saleProduct = {
                    item_no: product.item_no,
                    product_id: product.product_id,
                    so_item_no: product.so_item_no,
                    quantity: product.return_qty,
                    unit_price: product.unit_price,
                    cost_price: product.cost_price,
                    discount: product.discount,
                    item_note: product.return_notes,
                  };
                  updatedFormData.products.push(saleProduct);
                }
              } else {
                if (ff === 0) {
                  //console.log(product);
                  xx = product.code;
                  ff = 2;
                }
              }
            } else {
              if (ff === 0) {
                //console.log(product);
                xx = product.code;
                ff = 1;
              }
            }
          });
          if (ff === 1) {
            alert(
              `Return Qty of ${xx} must be less or equal to Shipped Qty.\nSales Return failed to update.`
            );
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          } else if (ff === 2) {
            alert(
              `Return Qty of ${xx} cannot be lesser than the last Returned Qty.\nSales Return failed to update.`
            );
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }

          // console.log(updatedFormData);
          if (updatedFormData.products.length > 0) {
            const response = await EditSalesReturnApi(
              updatedFormData,
              userID,
              userToken
            );
            if (response.status === 200) {
              if (print_flag && sr_id_param) {
                alert("Sales Return updated successfully.");
                await openNewTab(sr_id_param);
                setprint_flag(false);
                // window.location.reload();
                window.close();
              }
              if (!print_flag) {
                alert("Sales Return created successfully.");
              }
            } else {
              alert("Sales Return failed to create.");
              setsaveClick(!saveClick);
              setIsPageFrozen(false);
              return;
            }
          } else {
            alert(
              `Return Qty must be greater than previous Returned Qty in order to return a product and save this Sales Return!`
            );
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          if (!print_flag) {
            window.close();
          }
        }
      }
    }
    SaveOrder();
  }, [saveClick]);

  const CalculateAllFields = () => {
    let total_amt = 0,
      total_itm = 0;
    let total_dis = 0;
    let grandTotal = 0.0;
    let taxx = 0;
    //dd = 0;

    if (tax) {
      taxx = parseFloat(tax);
    }

    for (let i = 0; i < CardList.length; i++) {
      let a = 0,
        b = 0,
        c = 0;
      if (CardList[i].total) {
        a = parseFloat(CardList[i].return_total);
      }
      if (CardList[i].discount) {
        b = parseFloat(CardList[i].discount);
      }
      if (CardList[i].quantity) {
        // c = parseFloat(CardList[i].quantity);
        c = parseFloat(CardList[i].return_qty);
      }
      // if (projectname2) {
      //   dd = parseFloat(projectname2);
      // }
      total_amt = total_amt + a + b * c;
      total_dis = total_dis + b * c;
      total_itm = total_itm + c;

      // grandTotal = ((parseFloat(CardList[i].unit_price) - parseFloat(CardList[i].discount)) * parseFloat(CardList[i].quantity));
    }
    //settotaldiscount(total_dis);
    settotalAmount(total_amt);
    settotalitem(total_itm);
    taxx = (taxx / 100) * (total_amt - total_dis);
    // grandTotal = total_amt - total_dis + taxx + dd;
    grandTotal = total_amt - total_dis + taxx;
    // taxx = (taxx / 100) * total_amt;
    // grandTotal = total_amt + taxx + dd;

    setgrandtotal(grandTotal);
  };

  const handleToggle = (value) => {
    TimeoutUtility.resetTimeout();
    // console.log(value);
    // setToggle((pre) => !pre);
    // if (!viewOrderProductOpen) {
    //   openViewOrderProduct();
    // }
    setActiveProdMenuId({
      product_id: value.product_id,
      store_id: store_id_param,
    });
    setActiveProdMenu(true);
    //setproductID(value.product_id);
  };

  const handleRetNotesChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].return_notes = value;
    setcartList(newCartList);
    setActiveInput("return_notes");
    setindex1(index);
  };

  const handleBackClick = async () => {
    TimeoutUtility.resetTimeout();
    if (
      window.confirm(
        `Are you sure you want to Close without saving the changes?`
      )
    ) {
      //console.log(CardList);
      window.close();
    }
  };

  const formatCurrency = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (
        store_id_param &&
        store_id_param !== "" &&
        oDatetime &&
        oDatetime !== "" &&
        userID &&
        userToken
      ) {
        await getAccNamesAssetCash(store_id_param, userID, userToken)
          .then((resp) => {
            const filteredAccTos = resp.data.filter(
              (item) => new Date(item.open_datetime) <= new Date(oDatetime)
            );
            setGetacc_tos(filteredAccTos);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [store_id_param, oDatetime, userID, userToken]);

  useEffect(() => {
    async function fetchData() {
      try {
        setacc_to(getacc_tos[0]);
        //var bal = getacc_tos[0];
        //setacc_to_id(bal.account_id);
        //setacc_to_bal(bal.end_balance);
        //console.log(bal.end_balance);
      } catch (err) {}
    }
    fetchData();
  }, [getacc_tos]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (sr_id_param && userID && userToken) {
        await GetAccToIdBySalesReturn(sr_id_param, userID, userToken)
          .then((resp) => {
            setacc_from_bal(resp.data[0].end_balance);
            setacc_to(resp.data[0].name);
          })
          .catch((err) => {
            console.log(err.message);
          });

        await GetSalesReturnCustomerByID(sr_id_param, userID, userToken)
          .then((resp) => {
            //console.log(resp);
            setstore_id_param(resp.data[0].store_id);
            setso_id(resp.data[0].so_id);
            setcusFlag(resp.data[0].customer_id + " " + resp.data[0].name);
            setcustomer_ids(resp.data[0].customer_id);
            settrackingno(resp.data[0].tracking_no);
            setprojectname(resp.data[0].project_name);
            setshipmethod(resp.data[0].ship_method);
            settax(resp.data[0].tax);
            setnote(resp.data[0].sr_note);
            setamount_paid(resp.data[0].amount_paid);
            setacc_from_id(resp.data[0].account_id);
            setppo_id(resp.data[0].po_id);
            if (resp.data[0].status_id === 81) {
              setorderstatuslable("CLOSE");
            } else {
              setorderstatuslable("OPEN");
            }
            var d1 = new Date(resp.data[0].sale_date);
            d1.setMinutes(d1.getMinutes() - d1.getTimezoneOffset());
            setSDatetime(d1.toISOString().slice(0, 19));
            d1 = new Date(resp.data[0].return_date);
            d1.setMinutes(d1.getMinutes() - d1.getTimezoneOffset());
            setODatetime(d1.toISOString().slice(0, 19));
          })
          .catch((err) => {
            console.log(err.message);
          });

        await GetSalesReturnDetailsByID(sr_id_param, userID, userToken).then(
          function (result) {
            //console.log(result.data);
            if (result.data) {
              const productList = result.data.map((item) => {
                return {
                  product_id: item.product_id,
                  name: item.product_name,
                  code: item.code,
                  unit_price: item.unit_price,
                  cost_price: item.cost_price,
                  quantity: item.quantity,
                  discount: item.discount,
                  total: (item.unit_price - item.discount) * item.quantity,
                  so_item_no: item.so_item_no,
                  item_no: item.item_no,
                  shipment: item.product_shipped,
                  image: item.image,
                  details: item.details,
                  return_qty: item.return_qty,
                  return_total:
                    (item.unit_price - item.discount) * item.return_qty,
                  return_notes: item.return_note,
                  notes: item.item_note,
                  supplier_id: item.vendor_id,
                  supplier_name: item.vendor,
                  spec_qty: item.quantity_req,
                };
              });

              setcartList(() => [...productList]);

              const qtyShippedList = productList.map((product) => ({
                product_id: product.product_id,
                return_qty: product.return_qty,
                item_no: product.item_no,
                so_item_no: product.so_item_no,
                return_notes: product.notes,
              }));

              setqtyShipped(() => [...qtyShippedList]);
              // const unshippedSet = new Set();

              // productList.forEach((product, index) => {
              //   if (product.shipment > 0) {
              //     unshippedSet.add({
              //       product_id: product.product_id,
              //       item_no: product.item_no,
              //     });
              //   }
              // });
              // const unshippedArray = Array.from(unshippedSet);
              // setunshippedList(unshippedArray);

              //console.log(unshippedArray);
            }
          }
        );
      }
    }
    fetchData();
  }, [sr_id_param, userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    CalculateAllFields();
    //console.log(cusFlag);
    //setSelectedCustomer(cusFlag);
    // const productIdsInCart = CardList.map((product) => product.product_id);
    // setProductOptions((prevOptions) =>
    //   prevOptions.filter((option) => !productIdsInCart.includes(option.values))
    // );
  }, [CardList]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      {isPageFrozen && <div className="overlay-freeze" />}
      <Container fluid className="g-0 p-0 justify-end">
        <Row
          xs={1}
          sm={1}
          // className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={4} className="container-col">
            <Header title="UPDATE SALES RETURN" />
          </Col>
          <Col md={2} className="container-col">
            <label className="label" style={{ fontSize: "12px" }}>
              <span style={{ paddingLeft: "8px" }}>Sales Order DateTime:</span>
            </label>
            <input
              disabled
              className="input"
              id="o_date"
              type="datetime-local"
              style={{
                textAlign: "left",
                fontSize: "12px",
                width: "178px",
                background: "lightgray",
              }}
              value={sDatetime}
            />
          </Col>
          <Col md={2} className="container-col">
            <label className="label" style={{ fontSize: "12px" }}>
              <span style={{ paddingLeft: "8px" }}>Return DateTime:</span>
            </label>
            <input
              disabled
              className="input"
              id="o_date"
              type="datetime-local"
              style={{
                textAlign: "left",
                fontSize: "12px",
                width: "178px",
                background: "lightgray",
              }}
              value={oDatetime}
            />
          </Col>
          <Col md={4} className="container-col">
            <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
              }}
            >
              ORDER STATUS: {orderstatuslable}
            </label>
            <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
                // textAlign: "right",
              }}
            >
              SR# {sr_id_param}
            </label>
            <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
                // textAlign: "right",
              }}
            >
              SO# {so_id}
            </label>
          </Col>
        </Row>
        <Row
          xs={1}
          sm={1}
          className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={10} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <label className="label" style={{ fontSize: "18px" }}>
                    Customer:
                  </label>
                  <div className="sale-input-div">
                    <div className="sale-input">
                      <input
                        className="input"
                        style={{
                          width: "100%",
                          height: "36px",
                        }}
                        type="text"
                        value={cusFlag}
                        readOnly
                        disabled
                      />
                    </div>
                    <div
                      className="sale-bal ml-4"
                      style={{ backgroundColor: currentColor }}
                    >
                      <label>{formatCurrency(acc_from_bal)}</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              {/* <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="ProductSelect"
                      className="label"
                      style={{ fontSize: "18px", flex: "56%" }}
                    >
                      Product:
                    </label>
                    <label
                      className="label"
                      style={{ fontSize: "18px", flex: "12%" }}
                    >
                      Quantity:
                    </label>
                    <label
                      className="label"
                      style={{ fontSize: "18px", flex: "32%" }}
                    >
                      Supplier:
                    </label>
                  </div>
                  <div className="sale-input-div">
                    <div className="sale-input">
                      <Select
                        className="myreact-select-prod"
                        id="product"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        value={selectedProduct}
                        onChange={handleChangeProduct}
                        options={productOptions}
                        isSearchable
                        placeholder="Search Product With Name / Code"
                        //isClearable
                        styles={customStyles}
                        onKeyDown={(event) =>
                          handleChangeProduct1(selectedProduct, event)
                        }
                        filterOption={customFilter}
                        onInputChange={handleInputChange}
                      />
                      <input
                        id="qty"
                        type="number"
                        step="1"
                        min="0"
                        max="1000000"
                        className="input myreact-select-prod-qty"
                        //defaultValue={1.0}
                        value={qty}
                        placeholder="Quantity"
                        //onClick={() => handleInputClick("tax", -2)}
                        onChange={handleChangeQty}
                      />
                      <Select
                        className="myreact-select-supp"
                        id="supplier"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        value={selectedSupplier}
                        onChange={handleChangeSupplier}
                        options={SupplierOptions}
                        isSearchable
                        placeholder="Product Supplier"
                        isClearable
                        styles={customStyles}
                      />
                    </div>
                    <button
                      className="sale-bal ml-4"
                      type="button"
                      style={{
                        backgroundColor: currentColor,
                        fontWeight: "1000",
                        fontSize: "18px",
                        height: "100%",
                      }}
                      onClick={handleAddcartClick}
                    >
                      +
                    </button>
                  </div>
                </Col>
              </Row> 
              <br />
              <br />*/}
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale"
                style={{ height: "57vh" }}
              >
                <div className="m-0 p-0">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="table-sale-tr">
                        <th style={{ width: "80px" }}>CODE</th>
                        <th style={{ width: "320px" }}>PRODUCT</th>
                        <th style={{ width: "75px" }}>PRICE</th>
                        <th style={{ width: "75px" }}>DISC</th>
                        <th style={{ width: "65px" }}>QTY</th>
                        <th style={{ width: "90px" }}>SUPPLIER</th>
                        <th style={{ width: "180px" }}>ITEM NOTES</th>
                        <th style={{ width: "65px" }}>SHIP QTY</th>
                        <th style={{ width: "90px" }}>RET QTY</th>
                        <th style={{ width: "90px" }}>RET TOTAL</th>
                        <th style={{ width: "240px" }}>RETURN NOTES</th>
                      </tr>
                    </thead>
                    <tbody className="pos-table-body">
                      {CardList?.map((item, index) => (
                        <tr key={index}>
                          <td onClick={() => handleToggle(item)}>
                            {item.code}
                          </td>
                          <td onClick={() => handleToggle(item)}>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {item.image && (
                                <div
                                  className="image flex gap-4"
                                  style={{
                                    maxWidth: "72px",
                                    height: "72px",
                                    flex: "0 0 20%",
                                  }}
                                >
                                  <img
                                    className="rounded-xl"
                                    src={`data:image/jpeg;base64,${item.image}`}
                                    alt={`Product ${item.code}`}
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  flex: item.image && "0 0 80%",
                                  paddingTop: !item.image && "8px",
                                  paddingBottom: !item.image && "7px",
                                  alignSelf: "center",
                                }}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  {truncate(item.name, item.image ? 38 : 44)}
                                </div>
                                <div>
                                  {truncate(item.details, item.image ? 78 : 88)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>{formatCurrency(item.unit_price)}</td>
                          <td>{formatCurrency(item.discount)}</td>
                          <td>{item.quantity}</td>
                          <td>{item.supplier_name}</td>
                          <td>{item.notes}</td>
                          <td>{item.shipment}</td>
                          <td>
                            <div className="centered-input">
                              <input
                                disabled={ppo_id}
                                className="input"
                                id="return_qty"
                                type="number"
                                min="0"
                                max={item.shipment}
                                step="1.00"
                                value={item.return_qty}
                                onClick={() =>
                                  handleSpecQtyClick("return_qty", index)
                                }
                                onChange={(e) =>
                                  handleSpecQtyChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>{formatCurrency(item.return_total)}</td>
                          <td>
                            <div className="centered-input">
                              <textarea
                                className="textarea"
                                id="return_notes"
                                type="text"
                                value={item.return_notes}
                                onClick={() =>
                                  handleInputClick("return_notes", index)
                                }
                                onChange={(e) =>
                                  handleRetNotesChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale1"
              >
                <table className="borderless w-100 h-100">
                  <tbody>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ paddingTop: "8px" }}
                      >
                        SUB TOTAL:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          paddingTop: "8px",
                        }}
                      >
                        {formatCurrency(total_amount)}
                      </td>
                      <td
                        className="table-grand-sum"
                        rowSpan="7"
                        style={{ backgroundColor: currentColor }}
                      >
                        <div className="tgs-label">
                          GRAND TOTAL
                          <br />
                          <span className="tgs-value">
                            {formatCurrency(grandtotal)}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">TAX:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"%"}
                        </label>
                        <input
                          id="tax"
                          type="number"
                          step="1"
                          min="0"
                          max="100"
                          className="input table-sum-tb"
                          disabled
                          // defaultValue={0.0}
                          value={tax}
                          // onClick={() => handleInputClick("tax", -2)}
                          // onChange={handleChangeTax}
                          style={{
                            color: currentColor,
                            background: "lightgray",
                          }}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="table-sum-label">SHIPPING:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"$"}
                        </label>
                        <input
                          // defaultValue={0.0}
                          type="number"
                          name="shipment"
                          min="0"
                          step="1.00"
                          value={projectname2}
                          onChange={handleChangeProjectName2}
                          onClick={() => handleInputClick("shipment1", -3)}
                          className="input table-sum-tb"
                          style={{
                            color: currentColor,
                            width: "182px",
                          }}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td className="table-sum-label">AMOUNT PAID:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"$"}
                        </label>
                        <input
                          // defaultValue={0.0}
                          type="number"
                          name="shipment"
                          min="0"
                          step="1.00"
                          value={amount_paid}
                          disabled
                          //onChange={handleChangeAmountPaid}
                          //onClick={() => handleInputClick("amount_paid", -4)}
                          className="input table-sum-tb"
                          style={{
                            color: currentColor,
                            marginLeft: "13px",
                            background: "lightgray",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">PAID FROM:</td>
                      <td className="table-sum-cash">
                        <select
                          className="select table-sum-tb"
                          style={{
                            color: currentColor,
                            background: "lightgray",
                            marginLeft: "22px",
                          }}
                          defaultValue={acc_to}
                          disabled
                          //onChange={handleAccToChange}
                        >
                          {getacc_tos.map((item) => (
                            <option key={item.account_id}>{item.name}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ marginBottom: "8px" }}
                      >
                        TOTAL ITEMS:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          marginBottom: "8px",
                        }}
                      >
                        {total_item || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </div>
          </Col>

          <Col md={2} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleSaleOrderClick}
                  >
                    <div className="action-btn">SAVE</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: "gray",
                      // background: currentColor,
                    }}
                    // onClick={handlePrintOrderClick}
                  >
                    <div className="action-btn">PRINT</div>
                  </Card>
                </Col>
              </Row>
              {/* <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleNewClick}
                  >
                    <div className="action-btn">NEW</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleClearClick}
                  >
                    <div className="action-btn">CLEAR</div>
                  </Card>
                </Col>
              </Row> */}
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                {/* <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      disabled: true,
                      background: "gray",
                    }}
                    //onClick={handleStatusClick}
                  >
                    <div className="action-btn">{OrderStatus}</div>
                  </Card>
                </Col> */}
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleBackClick}
                  >
                    <div className="action-btn">CLOSE</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <Col md={12} className="container-col right-table-field">
                  <label>Ship Method:</label>
                  <input
                    type="text"
                    name="shipmethod"
                    placeholder="Ship-Method"
                    value={shipmethod}
                    onChange={handleChangeShipMethod}
                    className="input"
                  />
                </Col>
                <Col md={12} className="container-col right-table-field">
                  <label>Tracking No:</label>
                  <input
                    type="text"
                    name="tracking_no"
                    value={trackingno}
                    onChange={handleChangeTrackingNo}
                    placeholder="Tracking No."
                    className="input"
                  />
                </Col>
                <Col md={12} className="container-col right-table-field">
                  <label>Project Name:</label>
                  <input
                    type="text"
                    name="project name"
                    value={projectname}
                    onChange={handleChangeProjectName}
                    placeholder="Project Name"
                    className="input"
                  />
                </Col>
                {/* <Col
                  md={12}
                  className="container-col"
                  style={{
                    paddingRight: "5px",
                    paddingLeft: "5px",
                    paddingBottom: "6px",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    Receive By:
                  </label>
                  <input
                    type="text"
                    name="Customer_po"
                    value={projectname1}
                    onChange={handleChangeProjectName1}
                    placeholder="Receive By"
                    className="input"
                    style={{
                      width: "100%",
                    }}
                  />
                </Col> */}
                <Col
                  md={12}
                  className="container-col right-table-field"
                  style={{
                    paddingBottom: "16px",
                  }}
                >
                  <label>Sales Return Note:</label>
                  <textarea
                    placeholder="Sales Return Note"
                    id="noteTextarea"
                    value={note}
                    onChange={handleChangeNote}
                    className="textarea"
                  />
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{ padding: "0px" }}
              >
                {keypadButtons.map((number, index) => (
                  <Col
                    md={4}
                    className="container-col"
                    key={index}
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <Card
                      className="keypad-button1"
                      style={{
                        border: "1px solid " + currentColor,
                        color: currentColor,
                      }}
                      key={number}
                      onClick={() => handleKeypadClick(number)}
                    >
                      {number}
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          {/* {toggle && (
            <div className="overlay1">
              <Sidebar
                close={() => setToggle(false)}
                product_id={productID}
                store_id={store_id_param}
              />
            </div>
          )} */}
        </Row>
      </Container>
    </div>
  );
};
export default EditSalesReturn;
