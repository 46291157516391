import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  GetAllSalesByID,
  EditSaleOrderStatusBYSo_id,
  GetSaleOrderDetailsByID,
  DeleteSaleOrderApi,
} from "../../api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const Sales = () => {
  const [AllAccounts, setAllAccounts] = useState("");
  const [so_id, setso_id] = useState("");
  const [SR, setSR] = useState(0);
  const { currentColor, storeG, userPermID, userID, userToken } =
    useStateContext();
  const navigate = useNavigate();
  const [OrderStatus, setOrderStatus] = useState("");
  const [OrderDate, setOrderDate] = useState("");
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(false);

  const ProductGridactiveStatus = (props) => (
    <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
      {props.status}
      {props.Status === "Close" ? (
        <button
          type="button"
          style={{ background: "green" }}
          className="text-white py-2 px-2 capitalize rounded-2xl text-md"
        >
          <label>Close</label>
        </button>
      ) : (
        <button
          type="button"
          style={{ background: "red" }}
          className="text-white py-2 px-2 capitalize rounded-2xl text-md"
        >
          <label>Open</label>
        </button>
      )}
    </div>
  );
  const isCurrentYear = (date) =>
    new Date(
      date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
    ).getUTCFullYear() === new Date().getUTCFullYear();

  const customersGrid = [
    // { headerTemplate: ` `, type: "checkbox", width: "50" },
    {
      headerText: "SO#",
      field: "Q-S #",
      minWidth: "110",
      width: "110",
      maxWidth: "120",
      textAlign: "right",
    },
    // {
    //   field: "Quote Date",
    //   headerText: "Estimate Date",
    //   minWidth: "170",
    //   width: "170",
    //   maxWidth: "180",
    //   textAlign: "center",
    // },
    {
      field: "Sale_Date",
      headerText: "Sales Date",
      minWidth: "160",
      width: "170",
      maxWidth: "180",
      textAlign: "Center",
    },
    {
      field: "Customer",
      headerText: "Customer",
      minWidth: "160",
      width: "180",
      maxWidth: "360",
      textAlign: "left",
    },
    {
      field: "Customer PO",
      headerText: "Cust PO",
      minWidth: "130",
      width: "140",
      maxWidth: "180",
      textAlign: "right",
    },

    {
      field: "Proj",
      headerText: "Project Name",
      minWidth: "140",
      width: "140",
      maxWidth: "150",
      textAlign: "left",
    },

    {
      field: "Status",
      headerText: "Status",
      template: ProductGridactiveStatus,
      minWidth: "120",
      width: "120",
      maxWidth: "130",
      textAlign: "left",
    },
    {
      field: `total`,
      headerText: "Total",
      format: "C2",
      minWidth: "120",
      width: "130",
      maxWidth: "150",
      textAlign: "right",
    },
    {
      field: `amt`,
      headerText: "Amt Paid",
      format: "C2",
      minWidth: "135",
      width: "135",
      maxWidth: "150",
      textAlign: "right",
    },
    {
      field: "amount_pending",
      format: "C2",
      headerText: "Amt Pend",
      minWidth: "140",
      width: "140",
      maxWidth: "150",
      textAlign: "right",
    },
  ];

  const handleAddEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("Add new");
      const path = `/Sales/AddSaleOrder/${storeG.store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const UpdateStatus = async (stat, id) => {
    const response = await EditSaleOrderStatusBYSo_id(
      stat,
      id,
      userID,
      userToken
    );
    if (response.status === 200) {
      // alert("Sale order status updated successfully.");
    } else {
      alert("Failed to update sale order status.");
      return;
    }
  };

  const handlestatusClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("status order");
      if (so_id !== "" && userID && userToken) {
        if (OrderStatus === "Open") {
          var v = 2;
          await GetSaleOrderDetailsByID(so_id, userID, userToken).then(
            function (result) {
              // console.log(result.data);
              var k = result.data;
              if (result.data) {
                v = 0;
                k.forEach((element) => {
                  if (
                    parseFloat(element.quantity) >
                    parseFloat(element.product_shipped)
                  ) {
                    v = 1;
                  }
                });
              }
            }
          );
          if (v === 0) {
            UpdateStatus(81, so_id);
          } else {
            if (
              window.confirm(
                `Order is not completely Shipped.\nStill you want to continue?`
              )
            ) {
              UpdateStatus(81, so_id);
            } else {
              return;
            }
          }
        } else {
          // if (
          //   window.confirm(
          //     `Order is already Closed.\nStill you want to continue?`
          //   )
          // ) {
          //   UpdateStatus(80, so_id);
          // }
          alert("Order is already Closed.");
          return;
        }
        window.location.reload();
      } else {
        alert("Please select sale order.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddDupClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("edit new");
      if (so_id !== "") {
        // const so_ids = so_id + "_" + store_id;
        const so_ids = so_id;
        const path = `/Sales/AddSaleOrder/${so_ids}`;
        window.open(path, "_blank");
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("edit new");
      if (so_id !== "") {
        if (SR === 0) {
          // const so_ids = so_id + "_" + store_id;
          const so_ids = so_id;
          const path = `/Sales/EditSaleOrder/${so_ids}`;
          window.open(path, "_blank");
        } else {
          alert(
            "This Sales Order has been used in Sales Return!\n\nIt cannot be updated!"
          );
        }
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      if (so_id !== "" && userID && userToken) {
        if (SR === 0) {
          if (isCurrentYear(OrderDate)) {
            if (
              window.confirm(`Are you sure you want to Delete SO#${so_id}?`)
            ) {
              const response = await DeleteSaleOrderApi(
                so_id,
                userID,
                userToken
              );
              if (response.status === 200) {
                window.location.reload();
                alert("Sales Order deleted successfully.");
              } else {
                alert("Sales Order failed to delete.");
              }
            }
          } else {
            alert(
              `SO#${so_id} was created previous year!\nIt can not be deleted.`
            );
          }
        } else {
          alert(
            "This Sales Order has been used in Sales Return!\n\nIt cannot be deleted!"
          );
        }
      } else {
        alert("Please select Sales order.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("view saleorder");
      if (so_id !== "") {
        if (SR === 0) {
          //const so_ids = so_id + "_" + store_id;
          navigate(`/Sales/Shipment/${so_id}`);
        } else {
          alert(
            "This Sales Order has been used in Sales Return!\n\nIt cannot be shipped!"
          );
        }
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick2 = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("view shipment");
      if (so_id !== "") {
        //const so_ids = so_id + "_" + store_id;
        navigate(`/Sales/ViewShipment/${so_id}`);
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick3 = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      if (so_id !== "") {
        if (SR === 0) {
          if (OrderStatus === "Close") {
            const path = `/SalesReturn/AddSalesReturn/${so_id}`;
            window.open(path, "_blank");
          } else {
            alert(
              "This Sales Order's status is OPEN and cannot be Returned!\n\nMark this order CLOSE!"
            );
          }
        } else {
          alert(
            "This Sales Order has already been used in Sales Return!\n\nGo to View Sales Returns to edit this Sales Return!"
          );
        }
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick4 = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      navigate(`/SalesReturn`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewSaleClick = async (event) => {
    TimeoutUtility.resetTimeout();
    // event.preventDefault();
    try {
      // console.log("view saleorder");
      if (so_id !== "") {
        navigate(`/Sales/ViewSaleOrder/${so_id}`);
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleest_saleChange = (event) => {
  //   setest_sale(event.target.value);
  //   console.log(event.target.value);
  //   const Sale_Est = {
  //     saleEst: event.target.value,
  //   };
  //   localStorage.setItem("Sale_Est", JSON.stringify(Sale_Est));
  // };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    const selectedRowData = args.data;
    setso_id(selectedRowData.so_id);
    setSR(selectedRowData.SR);
    //console.log(selectedRowData.so_id);
    setOrderStatus(selectedRowData.Status);
    setOrderDate(selectedRowData.Sale_Date);
  };

  useEffect(() => {
    async function fetchData() {
      if (storeG !== null && storeG.store_id !== 0 && userID && userToken) {
        trackPromise(
          Promise.all([
            GetAllSalesByID(storeG.store_id, "S", userID, userToken),
          ])
            .then(([result]) => {
              setAllAccounts(result.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [storeG, userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Orders" title="SALES ORDERS" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {userPermID.includes(5014) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddEmployeesClick}
              />
            </Col>
          )}
          {userPermID.includes(5015) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Update"
                  borderRadius="10px"
                  onClick={handleEditEmployeesClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Delete"
                  borderRadius="10px"
                  onClick={handleDeleteEmployeesClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Close SO"
                  borderRadius="10px"
                  onClick={handlestatusClick}
                />
              </Col>
            </>
          )}
          {userPermID.includes(5014) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Shipment"
                borderRadius="10px"
                onClick={handleViewEmployeesClick}
              />
            </Col>
          )}
          {userPermID.includes(5013) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="View Shipment"
                borderRadius="10px"
                onClick={handleViewEmployeesClick2}
              />
            </Col>
          )}
          {userPermID.includes(5014) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Add Duplicate"
                  borderRadius="10px"
                  onClick={handleAddDupClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Sales Return"
                  borderRadius="10px"
                  onClick={handleViewEmployeesClick3}
                />
              </Col>
            </>
          )}
          {userPermID.includes(5013) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="View Sales Returns"
                borderRadius="10px"
                onClick={handleViewEmployeesClick4}
              />
            </Col>
          )}
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          <GridComponent
            className="custom-grid"
            dataSource={AllAccounts}
            recordDoubleClick={userPermID.includes(5013) && handleViewSaleClick}
            allowPaging={true}
            pageSettings={{ pageSize: 16 }}
            allowSorting
            allowResizing
            toolbar={["Search"]}
            rowSelected={handleRowSelected}
            rowHeight={36}
          >
            {/* {loading && promiseInProgress ? (
          <LoadingIndicator />
        ) : (
          <> */}
            <ColumnsDirective>
              {customersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            {/* </>
        )} */}
            <Inject
              services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </>
      )}
    </div>
  );
};

export default Sales;
