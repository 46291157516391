import React, { useState, useEffect } from "react";
import {
  GetAccLedgerReport,
  GetAccountListforRep,
  GetAllStores,
  GetInvStkReport,
} from "../../api";
import { Col, Container, Row } from "react-bootstrap";
import { useStateContext } from "../../contexts/ContextProvider";
import { Header, Button } from "../../components";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { useNavigate } from "react-router-dom";

const Report = () => {
  const [pdfURL, setPdfURL] = useState(null);
  const { currentColor, userID, userToken } = useStateContext();
  const navigate = useNavigate();

  const [sDatetime, setSDatetime] = useState("");
  const [sMinDatetime, setSMinDatetime] = useState("");
  const [sMaxDatetime, setSMaxDatetime] = useState("");
  const [eDatetime, setEDatetime] = useState("");
  const [eMinDatetime, setEMinDatetime] = useState("");
  const [eMaxDatetime, setEMaxDatetime] = useState("");
  const [dTNow, setDTNow] = useState(1);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState("");
  // const [accountTypes, setAccountTypes] = useState([]);
  // const [accountType, setAccountType] = useState("");
  // const [accountCategories, setAccountCategories] = useState([]);
  // const [accountCategory, setAccountCategory] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState("");
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState("");

  const [invStk, setInvStk] = useState(false);
  const [accLed, setAccLed] = useState(false);
  const [cusSal, setCusSal] = useState(false);
  const [supPur, setSupPur] = useState(false);

  const [title, setTitle] = useState("");
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(true);

  const fetchAndDisplayPDF = async () => {
    TimeoutUtility.resetTimeout();
    if (userID && userToken) {
      var sDt = sDatetime.replace("T", " ");
      var eDt = null;
      if (dTNow) {
        var d = new Date();
        eDt =
          [
            d.getFullYear(),
            (d.getMonth() + 1).toString().padStart(2, "0"),
            d.getDate().toString().padStart(2, "0"),
          ].join("-") +
          " " +
          [
            d.getHours().toString().padStart(2, "0"),
            d.getMinutes().toString().padStart(2, "0"),
            d.getSeconds().toString().padStart(2, "0"),
          ].join(":");
      } else {
        eDt = eDatetime.replace("T", " ");
      }

      if (invStk) {
        //console.log(sDt, eDt, store);
        trackPromise(
          Promise.all([
            GetInvStkReport(sDt, eDt, store || 0, userID, userToken),
          ])
            .then(([result]) => {
              setloading(true);
              //console.log(result);
              const pdfBlob = new Blob([result.data], {
                type: "application/pdf",
              });
              const pdfURL = URL.createObjectURL(pdfBlob);
              setPdfURL(pdfURL);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      } else if (accLed) {
        if (!store || store === 0 || store === "0") {
          alert("Select a Store to continue!");
        } else if (!account || account === 0 || account === "0") {
          alert("Select an Account to continue!");
        } else if (
          store &&
          account &&
          store !== 0 &&
          store !== "0" &&
          account !== 0 &&
          account !== "0"
        ) {
          trackPromise(
            Promise.all([
              GetAccLedgerReport(
                sDt,
                eDt,
                store || 0,
                account || 0,
                userID,
                userToken
              ),
            ])
              .then(([result]) => {
                setloading(true);
                //console.log(result);
                const pdfBlob = new Blob([result.data], {
                  type: "application/pdf",
                });
                const pdfURL = URL.createObjectURL(pdfBlob);
                setPdfURL(pdfURL);
              })
              .catch((err) => {
                console.log(err.message);
              })
              .finally(() => {
                setloading(false);
              })
          );
        }
      }
    }
  };

  const handleDownloadPDF = () => {
    TimeoutUtility.resetTimeout();
    if (pdfURL) {
      const link = document.createElement("a");
      link.href = pdfURL;
      link.download = `InventoryReport.pdf`;
      link.click();
    }
  };

  const handleChangeDTNow = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setEDatetime(now.toISOString().slice(0, 19));
    setSMaxDatetime(now.toISOString().slice(0, 19));

    if (dTNow === 1) {
      setEMaxDatetime(now.toISOString().slice(0, 19));
      //now = new Date(now.getFullYear(), 0, 2);
      setEMinDatetime(sDatetime);
    }
    setDTNow((prev) => (prev === 0 ? 1 : 0));
  };

  const handleChangeSDateTime = (e) => {
    if (e === "undefined") {
      setSDatetime(null);
    } else {
      if (
        new Date(e) > new Date(sMinDatetime) &&
        new Date(e) < new Date(sMaxDatetime)
      ) {
        setSDatetime(e);
        setEMinDatetime(e);
      }
    }
  };

  const handleChangeEDateTime = (e) => {
    if (e === "undefined") {
      setEDatetime(null);
    } else {
      if (
        new Date(e) > new Date(eMinDatetime) &&
        new Date(e) < new Date(eMaxDatetime)
      ) {
        setEDatetime(e);
        setSMaxDatetime(e);
      }
    }
  };

  const handleChangeStore = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0) {
      setStore(null);
    } else {
      setStore(e.target.value);
    }
  };

  // const handleChangeAccountType = (e) => {
  //   TimeoutUtility.resetTimeout();
  //   if (e.target.value === 0) {
  //     setAccountType(null);
  //   } else {
  //     setAccountType(e.target.value);
  //   }
  // };

  // const handleChangeAccountCategory = (e) => {
  //   TimeoutUtility.resetTimeout();
  //   if (e.target.value === 0) {
  //     setAccountCategory(null);
  //   } else {
  //     setAccountCategory(e.target.value);
  //   }
  // };

  const handleChangeAccount = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0) {
      setAccount(null);
    } else {
      setAccount(e.target.value);
    }
  };

  const handleChangeCustomer = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0) {
      setCustomer(null);
    } else {
      setCustomer(e.target.value);
    }
  };

  const handleChangeSupplier = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0) {
      setSupplier(null);
    } else {
      setSupplier(e.target.value);
    }
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    if (accLed) {
      try {
        navigate("/Account");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      TimeoutUtility.resetTimeout();
      if (
        userID &&
        userToken &&
        store &&
        accLed &&
        store !== 0 &&
        store !== "0"
      ) {
        //console.log(userID, userToken, store, accLed);
        await GetAccountListforRep(store, userID, userToken)
          .then((resp) => {
            setAccount(0);
            setAccounts(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else if (store === 0 || store === "0") {
        setAccount(0);
      }
    }
    fetchData();
  }, [userID, userToken, store, accLed]);

  useEffect(() => {
    async function fetchData() {
      TimeoutUtility.resetTimeout();
      if (userID && userToken) {
        let tmp = window.location.href.slice(
          window.location.href.lastIndexOf("/") + 1,
          window.location.href.length
        );
        // console.log(tmp);
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        setEDatetime(now.toISOString().slice(0, 19));
        setSMaxDatetime(now.toISOString().slice(0, 19));
        now = new Date(now.getFullYear(), now.getMonth(), 2);
        setSDatetime(now.toISOString().slice(0, 11) + "00:00:00");
        setEMinDatetime(now.toISOString().slice(0, 11) + "00:00:00");
        now = new Date(now.getFullYear(), 0, 2);
        setSMinDatetime(now.toISOString().slice(0, 11) + "00:00:00");

        if (tmp === "InventoryStockReport") {
          setInvStk(true);
          setTitle("Inventory Stock Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "LedgerReport") {
          setAccLed(true);
          setTitle("Account Ledger Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          // await GetAllAccounts(userID, userToken)
          //   .then((resp) => {
          //     setStores(resp.data || []);
          //   })
          //   .catch((err) => {
          //     console.log(err.message);
          //   });
        }
      }
    }
    fetchData();
  }, [userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Report" title={title} />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {(invStk || accLed || cusSal || supPur) && (
            <Col md="auto">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Date From:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <input
                  className="select-custom-rep rounded-xl"
                  id="s_date"
                  type="datetime-local"
                  value={sDatetime}
                  min={sMinDatetime}
                  max={sMaxDatetime}
                  onChange={(e) => handleChangeSDateTime(e.target.value)}
                />
              </Row>
            </Col>
          )}
          {(invStk || accLed || cusSal || supPur) && (
            <Col md="auto">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">
                  <span style={{ paddingRight: "42px" }}>Date To: </span>
                  <span>
                    Now{" "}
                    <input
                      type="checkbox"
                      checked={dTNow === 1}
                      onChange={handleChangeDTNow}
                      style={{
                        width: "12px",
                        height: "12px",
                      }}
                    />
                  </span>
                </label>
              </Row>
              <Row style={{ width: "100%" }}>
                <input
                  disabled={dTNow}
                  className="select-custom-rep rounded-xl"
                  id="e_date"
                  type="datetime-local"
                  style={{
                    background: dTNow && "lightgray",
                  }}
                  value={eDatetime}
                  min={eMinDatetime}
                  max={eMaxDatetime}
                  onChange={(e) => handleChangeEDateTime(e.target.value)}
                />
              </Row>
            </Col>
          )}
          {(invStk || accLed || cusSal || supPur) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Store:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={store}
                  onChange={handleChangeStore}
                >
                  <option value={0}>{"All Stores"}</option>
                  {stores.map((item, index) => (
                    <option key={index} value={item.store_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {/* {accLed && (
            <Col md="auto" style={{ padding: "0" }}>
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Account Type:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={accountType}
                  onChange={handleChangeAccountType}
                >
                  <option value={0}>{"All Account Types"}</option>
                  {accountTypes.map((item, index) => (
                    <option key={index} value={item.type_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {accLed && (
            <Col md="auto" style={{ padding: "0" }}>
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Account Category:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={accountCategory}
                  onChange={handleChangeAccountCategory}
                >
                  <option value={0}>{"All Account Categories"}</option>
                  {accountCategories.map((item, index) => (
                    <option key={index} value={item.category_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )} */}
          {accLed && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Account:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={account}
                  onChange={handleChangeAccount}
                >
                  <option value={0}>{"All Accounts"}</option>
                  {accounts.map((item, index) => (
                    <option key={index} value={item.account_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {cusSal && (
            <Col md="auto" style={{ padding: "0" }}>
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Customer:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={customer}
                  onChange={handleChangeCustomer}
                >
                  <option value={0}>{"All Customers"}</option>
                  {customers.map((item, index) => (
                    <option key={index} value={item.customer_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {supPur && (
            <Col md="auto" style={{ padding: "0" }}>
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Supplier:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={supplier}
                  onChange={handleChangeSupplier}
                >
                  <option value={0}>{"All Suppliers"}</option>
                  {suppliers.map((item, index) => (
                    <option key={index} value={item.vendor_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          <Col md="auto" style={{ paddingRight: "0" }}>
            <Button
              margin="6px"
              color="white"
              bgColor={currentColor}
              text="Update & View"
              borderRadius="10px"
              onClick={fetchAndDisplayPDF}
            />
          </Col>
          <Col md="auto" style={{ padding: "0" }}>
            <Button
              margin="6px"
              color="white"
              disabled={!pdfURL}
              bgColor={!pdfURL ? "gray" : currentColor}
              text="Download"
              borderRadius="10px"
              onClick={handleDownloadPDF}
            />
          </Col>
          {accLed && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                // disabled={!pdfURL}
                bgColor={currentColor}
                text="Back"
                borderRadius="10px"
                onClick={handleBackClick}
              />
            </Col>
          )}
        </Row>
      </Container>
      {loading && promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          {pdfURL && (
            <div>
              <iframe
                src={`${pdfURL}#toolbar=0`}
                title="Inventory Report"
                width="80%"
                height="720px"
                style={{
                  border: "1px solid #ddd",
                  marginTop: "24px",
                  margin: "auto",
                  marginBottom: "24px",
                }}
              ></iframe>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Report;
