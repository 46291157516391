import React, { useEffect, useState } from "react";
import TimeoutUtility from "../contexts/TimeoutUtility";
import { useNavigate } from "react-router-dom";
// import { BsCurrencyDollar } from 'react-icons/bs';
// import { GoPrimitiveDot } from "react-icons/go";
// import { IoIosMore } from 'react-icons/io';
// import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import { Stacked, Header } from "../components";
// import { Stacked, Pie, Button, LineChart, SparkLine } from '../components';
// import { earningData, SparklineAreaData } from "../data/dummy";
// import { earningData, medicalproBranding, recentTransactions, weeklyStats, dropdownData, SparklineAreaData, ecomPieChartDat } from '../data/dummy';
//import { useStateContext } from "../contexts/ContextProvider";
import {
  // GetNumCust,
  // GetNumSupp,
  // GetNumProd,
  // GetNumSO,
  // GetNumPO,
  // GetNumOpenSO,
  // GetNumOpenPO,
  // GetAccBal,
  // GetCashBal,
  // GetSaleRevByMonth,
  // GetPurchCostByMonth,
  GetOverviewCounts,
  GetOverviewAccounts,
  //GetAllStores,
} from "../api";
import {
  MdOutlineShoppingCart,
  MdOutlineSupervisorAccount,
} from "react-icons/md";
import { Col, Container, Row } from "react-bootstrap";
import { BsBoxSeam, BsCurrencyDollar } from "react-icons/bs";
import { FiBarChart } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi";
//import { usePromiseTracker, trackPromise } from "react-promise-tracker";
//import LoadingIndicator from "./LoadingIndicator";
import { useStateContext } from "../contexts/ContextProvider";

const Overview = () => {
  const { storeG, userID, userToken } = useStateContext();
  const [numCust, setNumCust] = useState("");
  const [numSupp, setNumSupp] = useState("");
  const [numProd, setNumProd] = useState("");
  const [numSO, setNumSO] = useState("");
  const [numPO, setNumPO] = useState("");
  const [numOpenSO, setNumOpenSO] = useState("");
  const [numOpenPO, setNumOpenPO] = useState("");
  //const [accBal, setAccBal] = useState([]);
  const [cashBal, setCashBal] = useState("");
  const [salRev, setSalRev] = useState("");
  const [costGs, setCostGs] = useState("");
  //const [saleRevByMonth, setSaleRevByMonth] = useState(null);
  //const [purcRevByMonth, setPurcRevByMonth] = useState(null);
  const [dataByMonth, setDataByMonth] = useState(null);
  const [dataByMonth1, setDataByMonth1] = useState([]);
  const [dataHeaders, setDataHeaders] = useState([]);
  // const [store, setstore] = useState("");
  // const [store_id, setstore_id] = useState("");
  // const [getstores, setstores] = useState([]);
  //const { promiseInProgress } = usePromiseTracker();
  //const [loading, setloading] = useState(false);

  const [dateR, setDateR] = useState("Today");
  //const { promiseInProgress1 } = usePromiseTracker();
  //const [loading1, setloading1] = useState(false);
  const navigate = useNavigate();
  //const [role, setRole] = useState("");

  const earningData = [
    {
      icon: <MdOutlineSupervisorAccount />,
      amount: numCust,
      // percentage: "-4%",
      title: "Customers",
      link: "/Customer",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <HiOutlineUsers />,
      amount: numSupp,
      // percentage: "-4%",
      title: "Suppliers",
      link: "/Supplier",
      iconColor: "#4d4dff",
      iconBg: "#e6e6ff",
      pcColor: "red-600",
    },
    {
      icon: <BsBoxSeam />,
      amount: numProd,
      // percentage: "+23%",
      title: "Products",
      link: "/Product",
      iconColor: "#fec90f",
      iconBg: "#fff4cc",
      pcColor: "green-600",
    },
    {
      icon: <FiBarChart />,
      amount: numOpenSO + " / " + numSO,
      // percentage: "+38%",
      title: "Sales",
      link: "/Sales",
      iconColor: "rgb(228, 106, 118)",
      iconBg: "rgb(255, 244, 229)",

      pcColor: "green-600",
    },
    {
      icon: <MdOutlineShoppingCart />,
      amount: numOpenPO + " / " + numPO,
      // percentage: "-12%",
      title: "Purchase",
      link: "/Purchase",
      iconColor: "#ff33ff",
      iconBg: "#ffe6ff",
      pcColor: "red-600",
    },
  ];

  // const handleChangeStore = (e) => {
  //   setstore(e.target.value);
  //   const s_id = getstores.find((item) => item.name === e.target.value);
  //   setstore_id(s_id.store_id);
  // };

  const navigateShortCut = (link) => {
    TimeoutUtility.resetTimeout();
    try {
      navigate(link);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    // if (
    //   saleRevByMonth &&
    //   purcRevByMonth &&
    //   saleRevByMonth.length === purcRevByMonth.length &&
    //   (saleRevByMonth.length > 0 || purcRevByMonth.length > 0)
    // ) {
    // let stc = [];
    // let stcdata = [];
    // let headerData = [];
    // saleRevByMonth.map((item) => stc.push({ x: item.P, y: item.SR }));
    // stcdata.push(stc);
    // console.log(stc);
    // headerData.push("Net Sales");
    // stc = [];
    // purcRevByMonth.map((item) => stc.push({ x: item.P, y: item.CG }));
    // stcdata.push(stc);
    // console.log(stc);
    // headerData.push("Cost of Goods Sold");
    // console.log(headerData);
    // console.log(stcdata);
    // setDataHeaders(headerData || []);
    // setDataByMonth(stcdata || []);
    //}
    setDataByMonth(dataByMonth1);
  }, [dataByMonth1]);

  // window.location.reload();
  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (storeG !== null && storeG.store_id !== 0 && userID && userToken) {
        // const userDataString = JSON.parse(sessionStorage.getItem("userData"));
        // setRole(userDataString.role);
        // if (
        //   userDataString.role === "B2" ||
        //   userDataString.role === "B3" ||
        //   userDataString.role === "S2" ||
        //   userDataString.role === "S3"
        // ) {
        // console.log(storeG.store_id, userID, userToken);
        const resp = GetOverviewCounts(storeG.store_id, userID, userToken);
        resp
          .then(function (result) {
            //console.log(result.data);
            setNumCust(result.data[0][0].Co || []);
            setNumSupp(result.data[1][0].Co || []);
            setNumProd(result.data[2][0].Co || []);
            setNumSO(result.data[3][0].Co || []);
            setNumPO(result.data[4][0].Co || []);
            setNumOpenSO(result.data[5][0].Co || []);
            setNumOpenPO(result.data[6][0].Co || []);
            setCashBal(result.data[7][0].Co || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        // const resp1 = GetNumSupp();
        // resp1
        //   .then(function (result) {
        //     // console.log(result.data[0].Co);
        //     setNumSupp(result.data[0].Co || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp2 = GetNumProd();
        // resp2
        //   .then(function (result) {
        //     // console.log(result.data[0].Co);
        //     setNumProd(result.data[0].Co || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp3 = GetNumSO();
        // resp3
        //   .then(function (result) {
        //     // console.log(result.data[0].Co);
        //     setNumSO(result.data[0].Co || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp4 = GetNumPO();
        // resp4
        //   .then(function (result) {
        //     // console.log(result.data[0].Co);
        //     setNumPO(result.data[0].Co || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp5 = GetNumOpenSO();
        // resp5
        //   .then(function (result) {
        //     // console.log(result.data[0].Co);
        //     setNumOpenSO(result.data[0].Co || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp6 = GetNumOpenPO();
        // resp6
        //   .then(function (result) {
        //     // console.log(result.data[0].Co);
        //     setNumOpenPO(result.data[0].Co || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp7 = GetAccBal();
        // resp7
        //   .then(function (result) {
        //     // console.log(result.data);
        //     setAccBal(result.data || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp8 = GetCashBal();
        // resp8
        //   .then(function (result) {
        //     // console.log(result.data);
        //     setCashBal(result.data[0].end_balance || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp9 = GetSaleRevByMonth();
        // resp9
        //   .then(function (result) {
        //     //console.log(result.data);
        //     //result.data.map((item) => stc.push({ x: item.Month, y: item.Total }));
        //     //console.log(stc);
        //     setSaleRevByMonth(result.data || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        // const resp10 = GetPurchCostByMonth();
        // resp10
        //   .then(function (result) {
        //     //console.log(result.data);
        //     //result.data.map((item) => stc.push({ x: item.Month, y: item.Total }));
        //     //console.log(stc);
        //     setPurcRevByMonth(result.data || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
      }
    }
    fetchData();
  }, [storeG, userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (
        storeG !== null &&
        storeG.store_id !== 0 &&
        dateR !== "" &&
        userID &&
        userToken
      ) {
        //console.log(dateR);
        // const userDataString = JSON.parse(sessionStorage.getItem("userData"));
        // setRole(userDataString.role);
        // if (
        //   userDataString.role === "B2" ||
        //   userDataString.role === "B3" ||
        //   userDataString.role === "S2" ||
        //   userDataString.role === "S3"
        // ) {
        const resp = GetOverviewAccounts(
          storeG.store_id,
          dateR,
          userID,
          userToken
        );
        resp
          .then(function (result) {
            //console.log(result.data);
            setSalRev(result.data[0][0].SR || []);
            setCostGs(result.data[1][0].CG || []);
            //setSaleRevByMonth(result.data[2] || []);
            //setPurcRevByMonth(result.data[3] || []);

            let stc = [];
            let stcdata = [];
            let headerData = [];

            result.data[3].map((item) => stc.push({ x: item.P, y: item.CG }));
            stcdata.push(stc);
            //console.log(stc);
            headerData.push("Cost of Goods Sold");

            stc = [];
            result.data[4].map((item) => stc.push({ x: item.P, y: item.GS }));
            stcdata.push(stc);
            //console.log(stc);
            headerData.push("Gross Profit");

            //console.log(headerData);
            //console.log(stcdata);
            setDataHeaders(headerData || []);
            setDataByMonth1(stcdata || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [dateR, storeG, userID, userToken]);

  // useEffect(() => {
  //   TimeoutUtility.resetTimeout();
  //   async function fetchData() {
  //     var a = null,
  //       b = null,
  //       d = [];
  //     trackPromise(
  //       Promise.all([GetAllStores()])
  //         .then(([resp]) => {
  //           setstores(resp.data || []);
  //           d = resp.data || [];
  //           // setstore(resp.data[0].name);
  //           // setstore_id(resp.data[0].store_id);
  //           a = resp.data[0].name;
  //           b = resp.data[0].store_id;
  //           //console.log(a, b);
  //         })
  //         .catch((err) => {
  //           console.log(err.message);
  //         })
  //         .finally(() => {
  //           // console.log(d.length);
  //           if (
  //             !JSON.parse(localStorage.getItem("Sale_Store1")) ||
  //             d.length === 1
  //           ) {
  //             const Sale_Store1 = {
  //               SaleStore1: a,
  //               SaleId: b,
  //             };
  //             localStorage.setItem("Sale_Store1", JSON.stringify(Sale_Store1));
  //           }
  //           const Sale_Store1 = JSON.parse(localStorage.getItem("Sale_Store1"));
  //           //console.log(Sale_Store1["SaleStore1"]);

  //           setstore(Sale_Store1["SaleStore1"]);
  //           setstore_id(Sale_Store1["SaleId"]);
  //           //setDateR("Today");
  //           setloading(false);
  //           setloading1(false);
  //         })
  //     );
  //   }
  //   fetchData();
  // }, []);

  return (
    <div className="m-0 md:m-4 p-4 md:p-0 rounded-3xl">
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row font-normal">
          <Col md="6" style={{ padding: "0" }}>
            <Header category="" title="OVERVIEW" />
          </Col>
          {/* <Col md="6" style={{ padding: "0" }}>
            <div style={{ width: "100%", textAlign: "right" }}>
              <select
                className="select-custom rounded-xl"
                style={{}}
                value={store}
                onChange={handleChangeStore}
              >
                {getstores.map((item) => (
                  <option key={item.store_id}>{item.name}</option>
                ))}
              </select>
            </div>
          </Col> */}
        </Row>
      </Container>
      {/* {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : ( */}
      <div className="mt-0">
        <div className="flex flex-wrap lg:flex-nowrap justify-center">
          <div
            className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-36 rounded-xl 
                       w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center"
          >
            <div className="flex justify-between items-center">
              <div className="col-md-8">
                <p className="font-body text-gray-400">Cash Balance</p>
                <p className="text-2xl">{formatter.format(cashBal)}</p>
              </div>
              <div className="col-md-4">
                <button
                  type="button"
                  style={{
                    color: "darkgreen",
                    backgroundColor: "lightgreen",
                  }}
                  className="text-2xl opacity-0.9 rounded-full hover:drop-shadow-xl"
                >
                  <BsCurrencyDollar />
                </button>
              </div>
            </div>

            {/* <div className="mt-6">
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Download"
                  borderRadius="10px"
                  size="md"
                />
              </div> */}
          </div>
          <br />
          <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
            {earningData.map((item) => (
              <div
                key={item.title}
                className="bg-white dark:text-gray dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl"
                // onClick={navigateShortCut}
                onDoubleClick={() => navigateShortCut(item.link)}
              >
                <button
                  type="button"
                  disabled
                  style={{
                    color: item.iconColor,
                    backgroundColor: item.iconBg,
                  }}
                  className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
                >
                  {item.icon}
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{item.amount}</span>
                  <span className={`text-sm text-${item.pcColor} ml-2`}>
                    {item.percentage}
                  </span>
                </p>
                <p className="text-md text-gray-500 mt-1">{item.title}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex gap-10 flex-wrap justify-center">
          {/* {loading1 || promiseInProgress1 ? (
              <LoadingIndicator />
            ) : ( */}
          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780">
            <div className="flex justify-between">
              <p className="font-semibold text-xl m-2">Account Summary</p>
              {/* <div className="flex items-center gap-4">
                    <p className="flex items-center gap-2 text-gray-600 hover:drop-shadow-xl">
                      <span>{/* <GoPrimitiveDot /> </span>
                      <span>Purchase Cost</span>
                    </p>
                    <p className="flex items-center gap-2 text-green-400 hover:drop-shadow-xl">
                      <span>{/* <GoPrimitiveDot /></span>
                      <span>Sales Revenue</span>
                    </p>
                  </div> */}
              <div className="flex items-center gap-4">
                <select
                  className="select-custom rounded-xl"
                  value={dateR}
                  onChange={(e) => setDateR(e.target.value)}
                >
                  <option key="d">Today</option>
                  <option key="w">Past Week</option>
                  <option key="mtd">This Month</option>
                  <option key="m">Past Month</option>
                  <option key="m3">Past 3 Months</option>
                  <option key="m6">Past 6 Months</option>
                  <option key="ytd">This Year</option>
                  <option key="y">Past Year</option>
                </select>
              </div>
            </div>
            <div className="mt-6 flex gap-6 flex-wrap justify-center">
              <div className="border-r-1 border-color m-4 pr-10">
                {/* {accBal.map((item) => (
                      <div key={item.Type}>
                        <p>
                          <span className="text-3xl font-semibold">
                            {formatter.format(item.Bal)}
                          </span>
                          <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
                            23%
                          </span>
                        </p>
                        <p className="text-gray-500 mt-1">{item.Type}</p>
                        <br />
                      </div>
                    ))} */}
                <div>
                  <p>
                    <span className="text-3xl font-semibold">
                      {formatter.format(salRev)}
                    </span>
                    {/* <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
                          23%
                        </span> */}
                  </p>
                  <p className="text-gray-500 mt-1">Net Sales</p>
                  <br />
                </div>
                <div>
                  <p>
                    <span className="text-3xl font-semibold">
                      {formatter.format(costGs)}
                    </span>
                    {/* <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
                          23%
                        </span> */}
                  </p>
                  <p className="text-gray-500 mt-1">Cost of Goods Sold</p>
                  <br />
                </div>
                <div>
                  <p>
                    <span className="text-3xl font-semibold">
                      {formatter.format(salRev - costGs)}
                    </span>
                    {/* <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
                          23%
                        </span> */}
                  </p>
                  <p className="text-gray-500 mt-1">Gross Profit</p>
                  <br />
                </div>
                {/* <div>
                      <p>
                        <span className="text-3xl font-semibold">$48,438</span>
                      </p>
                      <p className="text-gray-500 mt-1">Expense</p>
                    </div> */}
                {/* <div className="mt-5">
                      <SparkLine
                        currentColor={currentColor}
                        id="line-sparkline"
                        type="Line"
                        height="80px"
                        width="250px"
                        data={SparklineAreaData}
                        color={currentColor}
                      />
                    </div> */}
                {/* <div className="mt-10">
                      <Button
                        color="white"
                        bgColor={currentColor}
                        text="Download Report"
                        borderRadius="10px"
                      />
                    </div> */}
              </div>
              <div>
                {dataByMonth && (
                  <Stacked
                    width="420px"
                    height="360px"
                    data={dataByMonth}
                    headers={dataHeaders}
                  />
                )}
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default Overview;

// import React from "react";
// import TimeoutUtility from "../contexts/TimeoutUtility";
// // import { BsCurrencyDollar } from 'react-icons/bs';
// // import { GoPrimitiveDot } from "react-icons/go";
// // import { IoIosMore } from 'react-icons/io';
// // import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

// import { Stacked, Button, SparkLine } from "../components";
// // import { Stacked, Pie, Button, LineChart, SparkLine } from '../components';
// import { earningData, SparklineAreaData } from "../data/dummy";
// // import { earningData, medicalproBranding, recentTransactions, weeklyStats, dropdownData, SparklineAreaData, ecomPieChartDat } from '../data/dummy';
// import { useStateContext } from "../contexts/ContextProvider";

// const Overview = () => {
//   const { currentColor } = useStateContext();
//   // window.location.reload();
//   return (
//     <div className="mt-12">
//       <div className="flex flex-wrap lg:flex-nowrap justify-center">
//         <div
//           className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl
//         w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center"
//         >
//           <div className="flex justify-between items-center">
//             <div>
//               <p className="font-body text-gray-400">Earnings</p>
//               <p className="text-2xl">$63,448</p>
//             </div>
//           </div>
//           <div className="mt-6">
//             <Button
//               color="white"
//               bgColor={currentColor}
//               text="Download"
//               borderRadius="10px"
//               size="md"
//             />
//           </div>
//         </div>
//         <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
//           {earningData.map((item) => (
//             <div
//               key={item.title}
//               className="bg-white dark:text-gray dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl"
//             >
//               <button
//                 type="button"
//                 style={{ color: item.iconColor, backgroundColor: item.iconBg }}
//                 className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
//               >
//                 {item.icon}
//               </button>
//               <p className="mt-3">
//                 <span className="text-lg font-semibold">{item.amount}</span>
//                 <span className={`text-sm text-${item.pcColor} ml-2`}>
//                   {item.percentage}
//                 </span>
//               </p>
//               <p className="text-sm text-gray-400 mt-1">{item.title}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="flex gap-10 flex-wrap justify-center">
//         <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780">
//           <div className="flex justify-between">
//             <p className="font-semibold text-xl">Revenue Updates</p>
//             <div className="flex items-center gap-4">
//               <p className="flex items-center gap-2 text-gray-600 hover:drop-shadow-xl">
//                 <span>{/* <GoPrimitiveDot /> */}</span>
//                 <span>Expense</span>
//               </p>
//               <p className="flex items-center gap-2 text-green-400 hover:drop-shadow-xl">
//                 <span>{/* <GoPrimitiveDot /> */}</span>
//                 <span>Budget</span>
//               </p>
//             </div>
//           </div>
//           <div className="mt-10 flex gap-10 flex-wrap justify-center">
//             <div className="border-r-1 border-color m-4 pr-10">
//               <div>
//                 <p>
//                   <span className="text-3xl font-semibold">$93,438</span>
//                   <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
//                     23%
//                   </span>
//                 </p>
//                 <p className="text-gray-500 mt-1">Budget</p>
//               </div>
//               <div className="mt-8">
//                 <p>
//                   <span className="text-3xl font-semibold">$48,438</span>
//                 </p>
//                 <p className="text-gray-500 mt-1">Expense</p>
//               </div>
//               <div className="mt-5">
//                 {/* <SparkLine
//                   currentColor={currentColor}
//                   id="line-sparkline"
//                   type="Line"
//                   height="80px"
//                   width="250px"
//                   data={SparklineAreaData}
//                   color={currentColor}
//                 /> */}
//               </div>
//               <div className="mt-10">
//                 <Button
//                   color="white"
//                   bgColor={currentColor}
//                   text="Download Report"
//                   borderRadius="10px"
//                 />
//               </div>
//             </div>
//             <div>
//               <Stacked width="320px" height="360px" />
//             </div>
//           </div>
//         </div>
//         <div></div>
//       </div>
//     </div>
//   );
// };

// export default Overview;
