import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = "https://blcapi.codenexsys.com";

export const GetProductsByStoreVendorId = async (
  store_id,
  vendor_id,
  user_id,
  token
) => {
  try {
    //console.log("GetProductsByStoreVendorId");
    // console.log(store_id + " cssc " + vendor_id);
    const response = await axios.post(
      `${BASE_URL}/purchase/getProductsByStoreVendorId`,
      {
        store_id: store_id,
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetVendorProductFilterSubString = async (
  vendor_id,
  store_id,
  str,
  user_id,
  token
) => {
  try {
    //console.log("GetProductsByStoreVendorId");
    const response = await axios.post(
      `${BASE_URL}/product/getVendorProductFilterSubString`,
      {
        vendor_id: vendor_id,
        store_id: store_id,
        sub_string: str,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckVendNameExist = async (name, user_id, token) => {
  try {
    //console.log("CheckVendNameExist");
    const response = await axios.post(
      `${BASE_URL}/supplier/CheckVendNameExist`,
      {
        name: name,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllVendorsByStore = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllAccounts");
    const response = await axios.post(
      `${BASE_URL}/supplier/getAllSuppliersByStore`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllPurchaseByVenID = async (
  store_id,
  vendor_id,
  user_id,
  token
) => {
  try {
    //console.log("getAllPurchaseByVenID");
    const response = await axios.post(
      `${BASE_URL}/purchase/getAllPurchaseByVenID`,
      {
        store_id: store_id,
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetVendorIDProductsDetail = async (vendor_id, user_id, token) => {
  try {
    //console.log("GetVendorIDProductsDetail");
    const response = await axios.post(
      `${BASE_URL}/supplier/GetVendorIDProductsDetail`,
      {
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetVendorDetail = async (vendor_id, user_id, token) => {
  try {
    //console.log("GetVendorDetail");
    const response = await axios.post(
      `${BASE_URL}/supplier/GetVendorDetail`,
      {
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPurchaseOrderVendorByID = async (po_id, user_id, token) => {
  try {
    //console.log("GetPurchaseOrderVendorByID");
    const response = await axios.post(
      `${BASE_URL}/purchase/getPurchaseOrderVendorByID`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetVendorList = async (user_id, token) => {
  try {
    // console.log(payment_id);
    const response = await axios.post(
      `${BASE_URL}/special/GetVendorList`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllVendorsName = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllVendorsName");
    const response = await axios.post(
      `${BASE_URL}/supplier/getAllVendorsName`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllVendorsNameByPurchaseProduct = async (
  product_id,
  user_id,
  token
) => {
  try {
    // console.log("GetAllVendorsNamebyPP", product_id);
    const response = await axios.post(
      `${BASE_URL}/supplier/getAllVendorsNameByPurchaseProduct`,
      {
        product_id: product_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllVendorsNameByProduct = async (
  product_id,
  user_id,
  token
) => {
  try {
    //console.log("GetAllVendorsName");
    const response = await axios.post(
      `${BASE_URL}/supplier/getAllVendorsNameByProduct`,
      {
        product_id: product_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckVenDeleteStatus = async (vendor_id, user_id, token) => {
  try {
    //console.log("CheckVenDeleteStatus");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/supplier/CheckVenDeleteStatus`,
      {
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteVendor = async (vendor_id, user_id, token) => {
  try {
    //console.log("DeleteVendor");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/supplier/DeleteVendor`,
      {
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addAssignProductVen = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/supplier/addAssignProductVen`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const DeleteVendorTypeById = async (type_id, user_id, token) => {
  try {
    //console.log("DeleteUnitById");
    const response = await axios.post(
      `${BASE_URL}/supplier/deleteVendorTypeById`,
      {
        type_id: type_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddVendorTypeApi = async (names, user_id, token) => {
  try {
    //console.log("AddUnit");
    //console.log(names);
    const response = await axios.post(
      `${BASE_URL}/supplier/addVendorType`,
      {
        name: names,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllVendorTypes = async (user_id, token) => {
  try {
    //console.log("GetAllUnits");
    const response = await axios.post(
      `${BASE_URL}/supplier/getAllVendorTypes`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllSuppliers = async (user_id, token) => {
  try {
    //console.log("GetAllSuppliers");
    const response = await axios.post(
      `${BASE_URL}/supplier/getAllSupplier`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddSupplierApi = async (
  name,
  phone,
  website,
  contact_name,
  contact_phone,
  contact_email,
  profile,
  note,
  r_street,
  r_city,
  r_state,
  r_zip,
  r_country,
  r_phone,
  s_street,
  s_city,
  s_state,
  s_zip,
  s_country,
  attention_name,
  s_phone,
  acc_type_id,
  type_id,
  opening_balance,
  store_id,
  user_id,
  token
) => {
  try {
    //console.log("hello");
    //console.log(profile);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("website", website);
    formData.append("contact_name", contact_name);
    formData.append("contact_phone", contact_phone);
    formData.append("contact_email", contact_email);
    formData.append("profile", profile);
    formData.append("notes", note);
    formData.append("r_street", r_street);
    formData.append("r_city", r_city);
    formData.append("r_state", r_state);
    formData.append("r_zip", r_zip);
    formData.append("r_country", r_country);
    formData.append("r_phone", r_phone);
    formData.append("s_street", s_street);
    formData.append("s_city", s_city);
    formData.append("s_state", s_state);
    formData.append("s_zip", s_zip);
    formData.append("s_country", s_country);
    formData.append("s_attention_name", attention_name);
    formData.append("acc_type_id", acc_type_id);
    formData.append("s_phone", s_phone);
    formData.append("type_id", type_id);
    formData.append("opening_balance", opening_balance);
    formData.append("store_id", store_id);

    const response = await axios.post(
      `${BASE_URL}/supplier/addSupplier`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );

    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditSupplierApi = async (
  supplier_id,
  name,
  phone,
  website,
  contact_name,
  contact_phone,
  contact_email,
  profile,
  note,
  r_id,
  r_street,
  r_city,
  r_state,
  r_zip,
  r_country,
  r_phone,
  s_id,
  s_street,
  s_city,
  s_state,
  s_zip,
  s_country,
  attention_name,
  s_phone,
  account_id,
  acc_type_id,
  type_id,
  opening_balance,
  user_id,
  token
) => {
  try {
    //console.log("EditSupplierApi");

    const formData = new FormData();
    formData.append("supplier_id", supplier_id);
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("website", website);
    formData.append("contact_name", contact_name);
    formData.append("contact_phone", contact_phone);
    formData.append("contact_email", contact_email);
    formData.append("profile", profile);
    formData.append("notes", note);
    formData.append("remitting_address_id", r_id);
    formData.append("r_street", r_street);
    formData.append("r_city", r_city);
    formData.append("r_state", r_state);
    formData.append("r_zip", r_zip);
    formData.append("r_country", r_country);
    formData.append("r_phone", r_phone);
    formData.append("shipping_address_id", s_id);
    formData.append("s_street", s_street);
    formData.append("s_city", s_city);
    formData.append("s_state", s_state);
    formData.append("s_zip", s_zip);
    formData.append("s_country", s_country);
    formData.append("s_attention_name", attention_name);
    formData.append("s_phone", s_phone);
    formData.append("account_id", account_id);
    formData.append("acc_type_id", acc_type_id);
    formData.append("type_id", type_id);
    formData.append("opening_balance", opening_balance);

    //console.log(formData);
    const response = await axios.put(
      `${BASE_URL}/supplier/editSupplier`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );

    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const GetNumSupp = async (user_id, token) => {
  try {
    // const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getNumSupp`,
      {
        //   token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSupplierById = async (supplier_id, user_id, token) => {
  try {
    //console.log("GetSupplierById");
    const response = await axios.post(
      `${BASE_URL}/supplier/getSupplierByID`,
      {
        supplier_id: supplier_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllProductListSup = async (user_id, token) => {
  try {
    //console.log("GetAllProducts");
    const response = await axios.post(
      `${BASE_URL}/product/GetAllProductListSup`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
