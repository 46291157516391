import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = "https://blcapi.codenexsys.com";

export const GetAllStoreDetails = async (user_id, token) => {
  try {
    //console.log("GetAllProducts");
    const response = await axios.post(
      `${BASE_URL}/stores/getAllStoreDetails`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckStoreNameExist = async (name, user_id, token) => {
  try {
    //console.log("CheckStoreNameExist");
    const response = await axios.post(
      `${BASE_URL}/stores/CheckStoreNameExist`,
      {
        name: name,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetStoreByID = async (store_id, user_id, token) => {
  try {
    //console.log("getStoreByID");
    const response = await axios.post(
      `${BASE_URL}/stores/getStoreByID`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckStoreBeforeDel = async (store_id, user_id, token) => {
  try {
    //console.log("CheckStoreBeforeDel");
    const response = await axios.post(
      `${BASE_URL}/stores/CheckStoreBeforeDel`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DelStore = async (store_id, user_id, token) => {
  try {
    //console.log("DelStore");
    const response = await axios.post(
      `${BASE_URL}/stores/DelStore`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditStoreApi = async (
  store_id,
  name,
  email,
  contact,
  manager_id,
  location_id,
  street_address,
  city,
  state,
  postal_code,
  tax,
  user_id,
  token
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/stores/editStoreApi`,
      {
        store_id: store_id,
        name: name,
        email: email,
        contact: contact,
        manager_id: manager_id,
        location_id: location_id,
        street_address: street_address,
        city: city,
        state: state,
        postal_code: postal_code,
        tax: tax,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    // console.error(err);
    return false;
  }
};

export const GetAllStores = async (user_id, token) => {
  try {
    //console.log("GetAllStores");
    const response = await axios.post(
      `${BASE_URL}/stores/getAllStores`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetTaxByStoreId = async (store_id, user_id, token) => {
  try {
    //console.log("GetTaxByStoreId");
    const response = await axios.post(
      `${BASE_URL}/sale/GetTaxByStoreId`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddStoreApi = async (
  name,
  email,
  contact,
  manager_id,
  street_address,
  city,
  state,
  postal_code,
  tax,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/stores/addStore`,
      {
        name: name,
        email: email,
        contact: contact,
        manager_id: manager_id,
        street_address: street_address,
        city: city,
        state: state,
        postal_code: postal_code,
        tax: tax,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    //console.error(err);
    return err.response;
  }
};

export const GetAllInventory = async (user_id, token) => {
  try {
    //console.log("GetAllInventory");
    const response = await axios.post(
      `${BASE_URL}/inventory/getallinventory`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddOpeningBalance = async (
  store_id,
  product_id,
  min_stock,
  max_stock,
  opening_balance,
  t_type_id,
  t_note,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/inventory/AddOpeningBalance`,
      {
        store_id: store_id,
        product_id: product_id,
        min_stock: min_stock,
        max_stock: max_stock,
        opening_balance: opening_balance,
        t_type_id: t_type_id,
        t_note: t_note,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    // console.error(err);
    return false;
  }
};

export const AddOutStock = async (
  store_id,
  product_id,
  unit_instock,
  t_type_id,
  t_note,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/inventory/AddOutStock`,
      {
        store_id: store_id,
        product_id: product_id,
        unit_instock: unit_instock,
        t_type_id: t_type_id,
        t_note: t_note,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    // console.error(err);
    return false;
  }
};

export const AddInStock = async (
  store_id,
  product_id,
  unit_instock,
  t_type_id,
  t_note,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/inventory/AddInStock`,
      {
        store_id: store_id,
        product_id: product_id,
        unit_instock: unit_instock,
        t_type_id: t_type_id,
        t_note: t_note,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    // console.error(err);
    return false;
  }
};

export const GetinvStock = async (product_id, store_id, user_id, token) => {
  try {
    //console.log("GetinvStock");
    // const headers = {
    //   "Content-Type": "application/json",
    //   product_id: product_id,
    //   store_id: store_id,
    // };
    const response = await axios.post(
      `${BASE_URL}/inventory/getinvStock`,
      {
        //headers,
        product_id: product_id,
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    // console.log(err);
    return err;
  }
};
