import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = "https://blcapi.codenexsys.com";

export const GetPaymentDetailByID = async (payment_id, user_id, token) => {
  try {
    // console.log(payment_id);
    const response = await axios.post(
      `${BASE_URL}/payment/getPaymentDetailByID`,
      {
        payment_id: payment_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllPaymentSO = async (so_id, user_id, token) => {
  try {
    //console.log("GetAllPaymentSO");
    const response = await axios.post(
      `${BASE_URL}/payment/getAllPaymentSO`,
      {
        so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllPaymentPO = async (po_id, user_id, token) => {
  try {
    //console.log("GetAllPaymentPO");
    const response = await axios.post(
      `${BASE_URL}/payment/getAllPaymentPO`,
      {
        po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllPaymentDetail = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllPaymentDetail");
    const response = await axios.post(
      `${BASE_URL}/payment/getAllPaymentDetail`,
      { store_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllJournals = async (store_id, user_id, token) => {
  try {
    //console.log("getAllJournals");
    const response = await axios.post(
      `${BASE_URL}/journal/getAllJournals`,
      {
        store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllSO_CustRec = async (
  store_id,
  account_id,
  user_id,
  token
) => {
  try {
    //console.log("GetAllPaymentDetail_Pay");
    const response = await axios.post(
      `${BASE_URL}/payment/getAllSO_CustRec`,
      {
        store_id,
        account_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllPO_VendPay = async (
  store_id,
  account_id,
  user_id,
  token
) => {
  try {
    //console.log("GetAllPaymentDetail_Pay");
    const response = await axios.post(
      `${BASE_URL}/payment/getAllPO_VendPay`,
      {
        store_id,
        account_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllPaymentDetail_Pay = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllPaymentDetail_Pay");
    const response = await axios.post(
      `${BASE_URL}/payment/getAllPaymentDetail_Pay`,
      { store_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllPaymentDetail_Exp = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllPaymentDetail_Pay");
    const response = await axios.post(
      `${BASE_URL}/payment/getAllPaymentDetail_Exp`,
      { store_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPaymentOrders = async (
  payment_id,
  type_id,
  acc_id,
  date_time,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/getPaymentOrders`,
      {
        payment_id: payment_id,
        type_id: type_id,
        acc_id: acc_id,
        date_time: date_time,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getJournalById = async (journal_id, user_id, token) => {
  try {
    //console.log("getJournalById");
    const response = await axios.post(
      `${BASE_URL}/journal/getJournalById`,
      {
        journal_id: journal_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addPayment = async (
  acc_from_id,
  acc_to_id,
  amt_paid,
  p_datetime,
  acc_notes,
  type_id,
  order_exist,
  tableData,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/addPayment`,
      {
        acc_from_id: acc_from_id,
        acc_to_id: acc_to_id,
        amt_paid: amt_paid,
        p_datetime: p_datetime,
        acc_notes: acc_notes,
        type_id: type_id,
        order_exist: order_exist,
        orders: tableData,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const editPayment = async (
  payment_id,
  acc_from_id,
  acc_to_id,
  type_id,
  amount_paid,
  acc_notes,
  order_exist,
  tableData,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/editPayment`,
      {
        payment_id: payment_id,
        acc_from_id: acc_from_id,
        acc_to_id: acc_to_id,
        type_id: type_id,
        amount_paid: amount_paid,
        acc_notes: acc_notes,
        order_exist: order_exist,
        orders: tableData,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const deletePayment = async (payment_id, user_id, token) => {
  try {
    //console.log("DeletePayment");
    const response = await axios.post(
      `${BASE_URL}/payment/deletePayment`,
      {
        payment_id: payment_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deleteJournalById = async (journal_id, user_id, token) => {
  try {
    //console.log("deleteJournalById");
    const response = await axios.post(
      `${BASE_URL}/journal/deleteJournalById`,
      {
        journal_id: journal_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addJournalApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/journal/addJournal`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const editJournalApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/journal/editJournal`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};
