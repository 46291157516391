import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { ValidText } from "../../contexts/Utils";
import {
  GetProductsByStoreId,
  GetAllStores,
  GetinvStock,
  Verifyopeningbalexist,
  AddInStock,
} from "../../api";
import Select from "react-select";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";

const InStock = () => {
  const { currentColor, userID, userToken } = useStateContext();
  const navigate = useNavigate();
  const [GetProduct, setGetProduct] = useState([]);
  const [GetStore, setGetStore] = useState([]);
  const [product, setProducts] = useState("");
  const [p_code, setp_code] = useState("");
  const [store_ids, setstore_ids] = useState("");
  const [store, setStores] = useState("");
  const [productOptions, setProductOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [OldBal, setOldBal] = useState("-");
  const [invExist, setinvExist] = useState(0);
  const [note, setNote] = useState("");
  const [ValError, setValError] = useState([]);
  const [loadBtn, setLoadBtn] = useState(false);

  const handleChangeProduct = (selectedOption) => {
    TimeoutUtility.resetTimeout();
    if (selectedOption && selectedOption.value && userID && userToken) {
      const updatedErrors = [...ValError];
      updatedErrors[1] = "";
      setValError(updatedErrors);
      //setOldBal("-");
      setProducts(selectedOption);
      const selectedProduct = selectedOption.value;
      setp_code(selectedProduct);

      checkBalExist(selectedOption.value, store_ids);
    }
    // setp_code(selectedProduct.split(' ')[0]);
  };
  const checkBalExist = async (pro_id, so_id) => {
    try {
      const resp = await Verifyopeningbalexist(
        pro_id,
        so_id,
        userID,
        userToken
      );
      setinvExist(resp.data[0].status);
    } catch (err) {
      console.log(err.message);
    }
  };
  const ValidText1 = (txt, ii) => {
    const updatedErrors = [...ValError];
    if (txt.trim().length === 0) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return false;
    }
    if (ValidText(txt)) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return true;
    }
    updatedErrors[ii] = "Invalid Field!";
    setValError(updatedErrors);
    return false;
  };

  const getpreviousbal = async (pro_id, so_id) => {
    try {
      const resp = await GetinvStock(pro_id, so_id, userID, userToken);
      setOldBal(resp.data[0].unit_instock);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };
  const handleChangeStore = (selectedOption) => {
    //const selectedStore = e.target.value;

    if (selectedOption && selectedOption.value) {
      const updatedErrors = [...ValError];
      updatedErrors[0] = "";
      setValError(updatedErrors);
      //console.log(selectedOption.value);
      setStores(selectedOption);
      setOldBal("-");
      setstore_ids(selectedOption.value);
      if (selectedOption.value) {
        setGetProduct([]);
        getProductfromstore(selectedOption.value);
      } else {
        setGetProduct([]);
      }
    }
  };
  const getProductfromstore = async (selectedStore) => {
    //const storeid = GetStore.find((item) => item.name === selectedStore);
    if (selectedStore && userID && userToken) {
      try {
        const resp = await GetProductsByStoreId(
          selectedStore,
          userID,
          userToken
        );
        setGetProduct(resp.data || []);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("Back");
      navigate("/Inventory");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    TimeoutUtility.resetTimeout();
    setLoadBtn(true);
    var resp = 0;
    try {
      const { unit_instock } = document.forms[0];

      setValError([]);
      const updatedErrors = [...ValError];
      if (store === "") {
        updatedErrors[0] = "Please! Select Store";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[0] = "";
      if (product === "") {
        updatedErrors[1] = "Please! Select Product";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[1] = "";

      if (note !== "") {
        if (ValidText1(note, 2) === false) {
          return;
        }
      }
      updatedErrors[2] = "";

      if (unit_instock.value === "") {
        updatedErrors[3] = "Please! Enter In-Stock";
        setValError(updatedErrors);
        return;
      }
      if (unit_instock.value <= 0) {
        updatedErrors[3] = "In-Stock must be greater than 0";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[3] = "";

      const response = await AddInStock(
        store_ids,
        p_code,
        unit_instock.value,
        703,
        note,
        userID,
        userToken
      );

      //console.log(response, "Response");
      resp = response.status;
      if (resp === 200) {
        alert("In Stock Added Successfully");
      } else {
        alert("In Stock Failed to Add");
      }
      window.location.reload();
    } catch (err) {
    } finally {
      setLoadBtn(false);
      if (resp === 200) {
        navigate("/Inventory");
      }
    }
  };
  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken) {
        GetAllStores(userID, userToken)
          .then((resp) => {
            setGetStore(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken]);

  // useEffect(() => {
  //   TimeoutUtility.resetTimeout();
  //   if (store_ids) {
  //     //const storeid = GetStore.find((item) => item.name === store);
  //     console.log("getProductfromstore");
  //     console.log(store);
  //     GetProductsByStoreId(store_ids, userID, userToken)
  //       .then((resp) => {
  //         console.log(resp.data);
  //         setGetProduct(resp.data || []);
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }
  // }, [store, userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    if (invExist === 1) {
      getpreviousbal(p_code, store_ids);
    } else {
      setOldBal("-");
    }
  }, [invExist, p_code, store_ids]);

  useEffect(() => {
    const fetchProductOptions = async () => {
      const fetchedProductOptions = GetProduct.map((item) => ({
        label: `${item.code} ${item.name}`,
        value: item.product_id,
      }));
      setProductOptions(fetchedProductOptions);
      setProducts(null);
      setp_code(null);
      //checkBalExist(p_code, store_ids);
    };

    fetchProductOptions();
  }, [GetProduct, store_ids]);

  useEffect(() => {
    const fetchStoreOptions = async () => {
      const fetchedStoreOptions = GetStore.map((item) => ({
        label: `${item.name}`,
        value: item.store_id,
      }));
      setStoreOptions(fetchedStoreOptions);
    };

    fetchStoreOptions();
  }, [GetStore]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="INVENTORY IN-STOCK" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="StoreSelect">
                    Store:
                  </label>
                  <div className="flex mb-2">
                    <Select
                      className="myreact-select container-select"
                      value={store}
                      onChange={handleChangeStore}
                      options={storeOptions}
                      isSearchable
                      placeholder="Search Store"
                      //isClearable
                      autoFocus
                    />
                    <span className="label-imp-select">*</span>
                  </div>
                  {ValError[0] && <p style={{ color: "red" }}>{ValError[0]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="ProductSelect">
                    Product:
                  </label>
                  <div className="flex mb-2">
                    <Select
                      className="myreact-select container-select"
                      value={product}
                      onChange={handleChangeProduct}
                      options={productOptions}
                      isSearchable
                      placeholder="Search Product"
                      // isClearable
                    />
                    <span className="label-imp-select">*</span>
                  </div>
                  {ValError[1] && <p style={{ color: "red" }}>{ValError[1]}</p>}
                </div>
              </div>
              <br />

              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Note: </label>
                  <textarea
                    placeholder="Note"
                    id="noteTextarea"
                    value={note}
                    onChange={handleChangeNote}
                    className="textarea"
                    onBlur={(e) => ValidText1(e.target.value, 2)}
                  />
                  {ValError[2] && <p style={{ color: "red" }}>{ValError[2]}</p>}
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Current Inventory: </label>
                  <input
                    type="text"
                    value={OldBal}
                    placeholder="Previous Balance"
                    className="input"
                    min="0"
                    disabled
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Add In-Stock: </label>
                  <input
                    type="number"
                    step="1.00"
                    min="0"
                    defaultValue={0}
                    name="unit_instock"
                    placeholder="Add Stock"
                    className="input"
                  />
                  <span className="label-imp">*</span>
                  {ValError[3] && <p style={{ color: "red" }}>{ValError[3]}</p>}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button mr-2"
          bgColor={currentColor}
          text="Add"
          borderRadius="10px"
          onClick={handleSubmit}
          disabled={loadBtn}
        />
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button mr-2"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default InStock;
