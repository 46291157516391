import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = "https://blcapi.codenexsys.com";

export const EditSaleOrderStatus = async (so_id, user_id, token) => {
  try {
    //console.log("EditSaleOrderStatus");
    const response = await axios.post(
      `${BASE_URL}/sale/EditSaleOrderStatus`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditPurchaseStatusBYPo_id = async (
  po_id,
  status_id,
  user_id,
  token
) => {
  try {
    //console.log("EditPurchaseStatusBYPo_id");
    const response = await axios.post(
      `${BASE_URL}/purchase/EditPurchaseStatusBYPo_id`,
      {
        po_id: po_id,
        status_id: status_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditSaleOrderStatusBYSo_id = async (
  status_id,
  so_id,
  user_id,
  token
) => {
  try {
    //console.log("EditSaleOrderStatusBYSo_id");
    const response = await axios.post(
      `${BASE_URL}/sale/EditSaleOrderStatusBYSo_id`,
      {
        so_id: so_id,
        status_id: status_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllSalesByID = async (store_id, est_sale, user_id, token) => {
  try {
    //console.log("GetAllSalesByID");
    const response = await axios.post(
      `${BASE_URL}/sale/getAllSalesByID`,
      {
        store_id: store_id,
        est_sale: est_sale,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSpecialOrderDetail = async (
  store_id,
  vendor_id,
  user_id,
  token
) => {
  try {
    //console.log("GetSpecialOrderDetail", store_id, vendor_id);
    const response = await axios.post(
      `${BASE_URL}/special/GetSpecialOrderDetail`,
      {
        store_id: store_id,
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const SpecialOrderApi = async (store_id, user_id, token) => {
  try {
    //console.log("SpecialOrder");
    const response = await axios.post(
      `${BASE_URL}/supplier/SpecialOrder`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSpecialOrderMinDate = async (
  store_id,
  vendor_id,
  user_id,
  token
) => {
  try {
    //console.log("GetSpecialOrderMinDate");
    const response = await axios.post(
      `${BASE_URL}/special/GetSpecialOrderMinDate`,
      {
        store_id: store_id,
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetInvoiceDetailBySoId = async (so_id, user_id, token) => {
  try {
    //console.log("GetInvoiceDetailBySoId");
    const response = await axios.post(
      `${BASE_URL}/Invoice/GetInvoiceDetailBySoId`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPODetailByPoId = async (po_id, user_id, token) => {
  try {
    //console.log("GetPODetailByPoId");
    const response = await axios.post(
      `${BASE_URL}/Invoice/GetPODetailByPoId`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllPurchaseByID = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllPurchaseByID");
    const response = await axios.post(
      `${BASE_URL}/purchase/getAllPurchaseByID`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleOrderDetailShipmentByID = async (so_id, user_id, token) => {
  try {
    //console.log("GetSaleOrderDetailShipmentByID");
    const response = await axios.post(
      `${BASE_URL}/sale/getSaleOrderDetailShipmentByID`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPurchaseOrderDetailRecByID = async (po_id, user_id, token) => {
  try {
    //console.log("GetPurchaseOrderDetailRecByID");
    const response = await axios.post(
      `${BASE_URL}/purchase/getPurchaseOrderDetailRecByID`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetShipmentSaleOrderByID = async (so_id, user_id, token) => {
  try {
    //console.log("GetShipmentSaleOrderByID");
    const response = await axios.post(
      `${BASE_URL}/sale/getShipmentSaleOrderByID`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetReceiveLogPurchaseOrderByID = async (po_id, user_id, token) => {
  try {
    //console.log("GetReceiveLogPurchaseOrderByID");
    const response = await axios.post(
      `${BASE_URL}/purchase/getReceiveLogPurchaseOrderByID`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleOrderDetail = async (so_id, user_id, token) => {
  try {
    //console.log("GetSaleOrderDetail");
    const response = await axios.post(
      `${BASE_URL}/sale/getSaleOrderDetail`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPurchaseOrderDetail = async (po_id, user_id, token) => {
  try {
    //console.log("GetPurchaseOrderDetail");
    const response = await axios.post(
      `${BASE_URL}/purchase/getPurchaseOrderDetail`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetShipmentDetailByid = async (so_id, user_id, token) => {
  try {
    //console.log("GetShipmentDetailByid");
    const response = await axios.post(
      `${BASE_URL}/sale/GetShipmentDetailByid`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetReceiveLogDetailByid = async (po_id, user_id, token) => {
  try {
    //console.log("GetReceiveLogDetailByid");
    const response = await axios.post(
      `${BASE_URL}/purchase/GetReceiveLogDetailByid`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleOrderDetailsByID = async (so_id, user_id, token) => {
  try {
    //console.log("GetSaleOrderDetailsByID");
    const response = await axios.post(
      `${BASE_URL}/sale/getSaleOrderDetailsByID`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPurchaseOrderDetailsByID = async (po_id, user_id, token) => {
  try {
    //console.log("GetPurchaseOrderDetailsByID");
    const response = await axios.post(
      `${BASE_URL}/purchase/getPurchaseOrderDetailsByID`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteUnitById = async (unit_id, user_id, token) => {
  try {
    //console.log("DeleteUnitById");
    const response = await axios.post(
      `${BASE_URL}/unit/deleteunitbyid`,
      {
        unit_id: unit_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddUnitApi = async (names, user_id, token) => {
  try {
    //console.log("AddUnit");
    //console.log(names);
    const response = await axios.post(
      `${BASE_URL}/unit/addUnit`,
      {
        name: names,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteCategoryById = async (category_id, user_id, token) => {
  try {
    //console.log("DeleteCategoryById");
    //console.log(category_id);
    const response = await axios.post(
      `${BASE_URL}/category/deletecategorybyid`,
      {
        category_id: category_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddCategoryApi = async (names, user_id, token) => {
  try {
    //console.log("AddCategoryApi");
    //console.log(names);
    const response = await axios.post(
      `${BASE_URL}/category/addCategory`,
      {
        name: names,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteBrandById = async (brand_id, user_id, token) => {
  try {
    //console.log("DeleteBrandById");
    //console.log(brand_id);
    const response = await axios.post(
      `${BASE_URL}/brand/deletebrandbyid`,
      {
        brand_id: brand_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddBrandApi = async (names, user_id, token) => {
  try {
    //console.log("AddBrandApi");
    //console.log(names);
    const response = await axios.post(
      `${BASE_URL}/brand/addBrand`,
      {
        name: names,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddSpecialOrder = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/special/AddSpecialOrder`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditSpecialOrder = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/special/EditSpecialOrder`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const AddSaleOrderApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/addSaleOrder`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditSpecialOrderPurchase = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/special/EditSpecialOrderPurchase`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const AddEstimationApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/addEstimation`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const AddPurchaseOrderApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/purchase/addPurchaseOrder`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditSaleOrderApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/EditSaleOrder`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const DeleteSaleOrderApi = async (so_id, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/DeleteSaleOrder`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const DeletePurchaseOrderApi = async (po_id, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/purchase/DeletePurchaseOrder`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const ConvertEst = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/ConvertEst`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditEstimationApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/EditEstimation`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditPurchaseOrderAPi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/purchase/EditPurchaseOrder`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const AddSalesReturnApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/salesReturn/AddSalesReturn`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditSalesReturnApi = async (JsonObject, user_id, token) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/salesReturn/EditSalesReturn`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const GetAllSalesReturnByID = async (
  store_id,
  customer_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/salesReturn/getAllSalesReturnByID`,
      {
        store_id: store_id,
        customer_id: customer_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSalesReturnDetailsByID = async (sr_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/salesReturn/GetSalesReturnDetailsByID`,
      {
        sr_id: sr_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSalesReturnCustomerByID = async (sr_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/salesReturn/GetSalesReturnCustomerByID`,
      {
        sr_id: sr_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSalesReturnDetailRecByID = async (sr_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/salesReturn/GetSalesReturnDetailRecByID`,
      {
        sr_id: sr_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSalesReturnDetail = async (sr_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/salesReturn/GetSalesReturnDetail`,
      {
        sr_id: sr_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditShipment = async (JsonObject, user_id, token) => {
  try {
    //console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/EditShipment`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditReceive_Log = async (JsonObject, user_id, token) => {
  try {
    //console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/purchase/EditReceive_Log`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const GetAllUnits = async (user_id, token) => {
  try {
    //console.log("GetAllUnits");
    const response = await axios.post(
      `${BASE_URL}/unit/getallunits`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllSales = async (user_id, token) => {
  try {
    //console.log("GetAllSales");
    const response = await axios.post(
      `${BASE_URL}/sale/getAllSales`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllBrands = async (user_id, token) => {
  try {
    //console.log("GetAllBrands");
    const response = await axios.post(
      `${BASE_URL}/brand/getallbrands`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllCategories = async (user_id, token) => {
  try {
    //console.log("GetAllCategories");
    const response = await axios.post(
      `${BASE_URL}/category/getallcategories`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetOverviewCounts = async (store_id, user_id, token) => {
  try {
    //const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getOverviewCounts`,
      {
        store_id: store_id,
        //token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetNumSO = async (user_id, token) => {
  try {
    // const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getNumSO`,
      {
        //  token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetNumPO = async (user_id, token) => {
  try {
    //const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getNumPO`,
      {
        //token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetNumOpenSO = async (user_id, token) => {
  try {
    //const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getNumOpenSO`,
      {
        //token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetNumOpenPO = async (user_id, token) => {
  try {
    //const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getNumOpenPO`,
      {
        //token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleRevByMonth = async (user_id, token) => {
  try {
    //const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getSaleRevByMonth`,
      {
        //token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPurchCostByMonth = async (user_id, token) => {
  try {
    //const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getPurchCostByMonth`,
      {
        //token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
