import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = "https://blcapi.codenexsys.com";

export const CheckEmpNameExist = async (name, user_id, token) => {
  try {
    //console.log("CheckAccNameExist");
    const response = await axios.post(
      `${BASE_URL}/employee/CheckEmpNameExist`,
      {
        name: name,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllEmployeesByStore = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllAccounts");
    const response = await axios.post(
      `${BASE_URL}/employee/getAllEmployeesByStore`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetEmployeeById = async (employee_id, user_id, token) => {
  try {
    //console.log("GetEmployeeById");
    const response = await axios.post(
      `${BASE_URL}/employee/getEmployeeByID`,
      {
        employee_id: employee_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllEmployees = async (user_id, token) => {
  try {
    //console.log("GetAllEmployees");
    const response = await axios.post(
      `${BASE_URL}/employee/getAllEmployees`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteEmp = async (employee_id, user_id, token) => {
  try {
    //console.log("DeleteEmp");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/employee/DeleteEmp`,
      {
        employee_id: employee_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddEmployeeApi = async (
  name,
  email,
  contact,
  hire_date,
  manager_id,
  salary,
  store_id,
  street_address,
  city,
  state,
  postal_code,
  acc_type_id,
  acc_notes,
  opening_balance,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/employee/addEmployee`,
      {
        name: name,
        email: email,
        contact: contact,
        hire_date: hire_date,
        manager_id: manager_id,
        salary: salary,
        store_id: store_id,
        street_address: street_address,
        city: city,
        state: state,
        postal_code: postal_code,
        acc_type_id: acc_type_id,
        acc_notes: acc_notes,
        opening_balance: opening_balance,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditEmployeeApi = async (
  employee_id,
  name,
  email,
  contact,
  hire_date,
  manager_id,
  salary,
  location_id,
  store_id,
  street_address,
  city,
  state,
  postal_code,
  account_id,
  acc_type_id,
  acc_notes,
  opening_balance,
  ledger_type_id,
  user_id,
  token
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/employee/editEmployee`,
      {
        employee_id: employee_id,
        name: name,
        email: email,
        contact: contact,
        hire_date: hire_date,
        manager_id: manager_id,
        salary: salary,
        location_id: location_id,
        store_id: store_id,
        street_address: street_address,
        city: city,
        state: state,
        postal_code: postal_code,
        account_id: account_id,
        acc_type_id: acc_type_id,
        acc_notes: acc_notes,
        opening_balance: opening_balance,
        ledger_type_id: ledger_type_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const GetAllEmpNameNoUsers = async (p_user_id, user_id, token) => {
  try {
    //console.log("GetAllPurchaseByID");
    const response = await axios.post(
      `${BASE_URL}/user/getAllEmpNameNoUsers`,
      {
        p_user_id: p_user_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllManagers = async (user_id, token) => {
  try {
    //console.log("GetAllManagers");
    const response = await axios.post(
      `${BASE_URL}/employee/getAllManagers`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllManagersByID = async (employee_id, user_id, token) => {
  try {
    //console.log("getAllManagersByID");
    const response = await axios.post(
      `${BASE_URL}/employee/getAllManagersByID`,
      {
        employee_id: employee_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
