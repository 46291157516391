import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { ValidText } from "../../contexts/Utils";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { addJournalApi, getAccNamesCash } from "../../api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";
import Select from "react-select";

const AddJournal = () => {
  const navigate = useNavigate();
  let param = useParams();
  const store_id = param.store_id;
  const { currentColor, userID, userToken } = useStateContext();
  const [acc_notes, setAccNotes] = useState("");
  const [AllAccounts, setAllAccounts] = useState("");
  //const [r_id, setr_id] = useState("");
  const [to_acc, setto_acc] = useState("");
  const [amount_paid, setamount_paid] = useState(0);
  const [to_acc_Options, setto_acc_Options] = useState([]);
  const [GetAccount_To, setGetAccount_To] = useState([]);
  const [from_acc, setfrom_acc] = useState("");
  const [from_acc_Options, setfrom_acc_Options] = useState([]);
  const [ValError, setValError] = useState([]);
  const [to_acc_bal, setto_acc_bal] = useState("");
  const [oDatetime, setODatetime] = useState("");
  const [oMinDatetime, setOMinDatetime] = useState("");
  const [oMaxDatetime, setOMaxDatetime] = useState("");
  const [dTNow, setDTNow] = useState(1);
  const [loadBtn, setLoadBtn] = useState(false);
  //const [from_acc_bal, setfrom_acc_bal] = useState("");
  //const [flag, setflag] = useState(0);
  //const [acc_from_id, setacc_from_id] = useState(null);
  //const [acc_to_id, setacc_to_id] = useState(null);

  const [Journal] = useState({
    journal: [],
  });

  const customersGrid = [
    {
      field: "account",
      headerText: "Account",
      width: "110",
      textAlign: "Center",
    },

    {
      field: "credit",
      headerText: "Credit",
      width: "100",
      format: "C2",
      textAlign: "Center",
    },
    {
      field: "debit",
      headerText: "Debit",
      width: "100",
      format: "C2",
      textAlign: "Center",
    },

    {
      field: "note",
      headerText: "Note",
      // template: customerGridImage,
      width: "200",
      textAlign: "Center",
    },
  ];

  const handleChangeDTNow = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    // console.log(now.toISOString().slice(0, 19));
    //console.log(oDatetime);
    setODatetime(now.toISOString().slice(0, 19));

    if (dTNow === 1) {
      setOMaxDatetime(now.toISOString().slice(0, 19));
      now = new Date(now.getFullYear(), 0, 2);
      //console.log(now.toISOString().slice(0, 19));
      setOMinDatetime(now.toISOString().slice(0, 19));
    }
    setDTNow((prev) => (prev === 0 ? 1 : 0));
  };

  const handleChangeODateTime = (e) => {
    if (e === "undefined") {
      setODatetime(null);
    } else {
      setODatetime(e);
    }
  };

  const handleChangeAccNote = (e) => {
    setAccNotes(e.target.value);
  };

  const handleChangeAmount = (e) => {
    setamount_paid(e.target.value);
  };

  const handleChangeAcc_To = async (selectedOption) => {
    // selectedOption.preventDefault();
    if (selectedOption === null) {
      setto_acc("");
      setto_acc_bal("");
      //setacc_to_id("");
    } else {
      if (selectedOption.label !== "") {
        const updatedErrors = [...ValError];
        updatedErrors[0] = "";
        setValError(updatedErrors);
      }
      if (selectedOption && selectedOption.value) {
        if (selectedOption.value) {
          // try {
          //   const resp = await getAcc1BalFrom(selectedOption.value);
          setto_acc_bal(selectedOption.bal);
          // } catch (err) {
          //   console.log(err.message);
          // }
          setto_acc(selectedOption);
          //setacc_to_id(selectedOption.value);
        }
      }
    }
  };

  const ValidText1 = (txt, ii) => {
    const updatedErrors = [...ValError];
    if (txt.trim().length === 0) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return false;
    }
    if (ValidText(txt)) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return true;
    }
    updatedErrors[ii] = "Invalid field!";
    setValError(updatedErrors);
    return false;
  };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    //const selectedRowData = args.data;
    //setr_id(selectedRowData.journal_id);
    //console.log(selectedRowData.journal_id);
    const updatedAccounts = [...AllAccounts];
    updatedAccounts.splice(args.rowIndex, 1);
    setAllAccounts([...updatedAccounts]);
    //console.log(updatedAccounts);
  };

  const handleChangeAcc_From = async (selectedOption) => {
    // selectedOption.preventDefault();
    //setfrom_acc_bal("");
    if (selectedOption === null) {
      setfrom_acc("");
      //setacc_from_id("");
    } else {
      if (selectedOption.label !== "") {
        const updatedErrors = [...ValError];
        updatedErrors[1] = "";
        setValError(updatedErrors);
      }
      if (selectedOption && selectedOption.value) {
        if (selectedOption.value) {
          // try {
          //   const resp = await getAcc1BalFrom(selectedOption.value);
          //setfrom_acc_bal(selectedOption.bal);
          // } catch (err) {
          //   console.log(err.message);
          // }

          setfrom_acc(selectedOption);
          //setacc_from_id(selectedOption.value);
        }
      }
    }
  };

  const formatCurrency = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      // console.log("Back");
      navigate("/Journal");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddItem1 = async (e) => {
    e.preventDefault();
    TimeoutUtility.resetTimeout();
    setValError([]);
    const updatedErrors = [...ValError];

    if (!to_acc) {
      updatedErrors[0] = "Please select account.";
      setValError(updatedErrors);
      return;
    }
    updatedErrors[0] = "";

    if (!from_acc) {
      updatedErrors[1] = "Please select type.";
      setValError(updatedErrors);
      return;
    }
    updatedErrors[1] = "";

    if (amount_paid === "" || amount_paid === "0" || amount_paid === 0) {
      updatedErrors[2] = "Amount must be greater than 0.";
      setValError(updatedErrors);
      return;
    }
    updatedErrors[2] = "";

    if (acc_notes) {
      if (ValidText1(acc_notes, 3) === false) {
        return;
      }
    }
    updatedErrors[3] = "";

    const updatedItem = [...AllAccounts];
    var a = 0,
      b = 0;
    if (from_acc.value === "902") {
      a = parseFloat(amount_paid);
    } else {
      b = parseFloat(amount_paid);
    }
    const newitem = {
      account: to_acc.label,
      credit: a,
      debit: b,
      note: acc_notes,
      type_id: from_acc.value,
      acc_id: to_acc.value,
      open_datetime: to_acc.o_datetime,
    };
    setAllAccounts([...updatedItem, newitem]);
    setfrom_acc("");
    setAccNotes("");
    setto_acc("");
    setamount_paid(0);
    setto_acc_bal("");
    //setflag(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    TimeoutUtility.resetTimeout();
    setLoadBtn(true);
    var resp = 0;
    try {
      const journallist = { ...Journal };
      journallist.journal = [];
      //setValError([]);
      const updatedErrors = [...ValError];
      // console.log(AllAccounts.length);
      // return;
      if (AllAccounts.length < 1) {
        alert("Journal vouchar is empty.");
        return;
      }
      var a = 0,
        b = 0;
      AllAccounts.forEach((element, index) => {
        a = a + parseFloat(element.credit);
        b = b + parseFloat(element.debit);
      });

      if (a !== b) {
        alert("Credit and debit are not equal");
        return;
      }

      if (!oDatetime || oDatetime === "") {
        updatedErrors[4] = "Please select DateTime!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[4] = "";

      var Rdt = null;
      if (dTNow) {
        var d = new Date();
        Rdt =
          [
            d.getFullYear(),
            (d.getMonth() + 1).toString().padStart(2, "0"),
            d.getDate().toString().padStart(2, "0"),
          ].join("-") +
          " " +
          [
            d.getHours().toString().padStart(2, "0"),
            d.getMinutes().toString().padStart(2, "0"),
            d.getSeconds().toString().padStart(2, "0"),
          ].join(":");
      } else {
        Rdt = oDatetime.replace("T", " ");
      }

      AllAccounts.forEach((element, index) => {
        var d = 0;
        if (parseFloat(element.credit) > 0) {
          d = parseFloat(element.credit);
        }
        if (parseFloat(element.debit) > 0) {
          d = parseFloat(element.debit);
        }
        const newitem1 = {
          account_id: element.acc_id,
          l_datetime: Rdt,
          amount_paid: d,
          type_id: element.type_id,
          note: element.note,
        };
        journallist.journal.push(newitem1);
      });
      // console.log(journallist);

      const response = await addJournalApi(journallist, userID, userToken);
      // console.log(response, "Response");
      resp = response.status;
      if (resp === 200) {
        alert("Journal Ledger Added Successfully");
      } else {
        alert("Journal Ledger Failed to Add");
      }
    } catch (err) {
    } finally {
      setLoadBtn(false);
      if (resp === 200) {
        navigate("/Journal");
      }
    }
  };

  useEffect(() => {
    if (dTNow === 0) {
      if (GetAccount_To.length > 0) {
        const filteredAccTo = GetAccount_To.filter(
          (item) => new Date(item.open_datetime) <= new Date(oDatetime)
        );
        const fetchedAccToOption = filteredAccTo.map((item) => ({
          label: item.name,
          value: item.account_id,
          bal: item.end_balance,
          o_datetime: item.open_datetime,
        }));
        setto_acc_Options(fetchedAccToOption);
        if (
          to_acc !== "" &&
          new Date(to_acc.o_datetime) > new Date(oDatetime)
        ) {
          setto_acc("");
          setto_acc_bal("");
        }
      }
      if (AllAccounts.length > 0) {
        const filteredOrders = AllAccounts.filter(
          (item) => new Date(item.open_datetime) < new Date(oDatetime)
        );
        setAllAccounts(filteredOrders);
      }
    } else {
      if (GetAccount_To.length > 0) {
        const fetchedAccToOption = GetAccount_To.map((item) => ({
          label: item.name,
          value: item.account_id,
          bal: item.end_balance,
          o_datetime: item.open_datetime,
        }));
        setto_acc_Options(fetchedAccToOption);
      }
    }
  }, [oDatetime]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    const fetchAcc_ToOptions = async () => {
      if (GetAccount_To.length > 0) {
        const fetchedProductOptions = GetAccount_To.map((item) => ({
          label: item.name,
          value: item.account_id,
          bal: item.end_balance,
          o_datetime: item.open_datetime,
        }));
        setto_acc_Options(fetchedProductOptions);
      }
    };
    fetchAcc_ToOptions();
  }, [GetAccount_To]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken && store_id && store_id !== 0) {
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        setODatetime(now.toISOString().slice(0, 19));

        getAccNamesCash(store_id, userID, userToken)
          .then((resp) => {
            setGetAccount_To(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });

        const ba = [
          { label: "Credit", value: "902", bal: "0" },
          {
            label: "Debit",
            value: "901",
            bal: "0",
          },
        ];
        setfrom_acc_Options(ba);
      }
    }
    fetchData();
  }, [userID, userToken, store_id]);

  const settings = { checkboxMode: "ResetOnRowClick" };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="ADD JOURNAL" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">
                    <span style={{ paddingLeft: "8px" }}>DateTime:</span>
                    <span style={{ float: "right", paddingRight: "22%" }}>
                      Now{" "}
                      <input
                        type="checkbox"
                        checked={dTNow === 1}
                        onChange={handleChangeDTNow}
                        style={{
                          width: "14px",
                          height: "14px",
                        }}
                      />
                    </span>
                  </label>
                  <div className="flex mb-2">
                    <input
                      disabled={dTNow}
                      className="input"
                      id="o_date"
                      type="datetime-local"
                      style={{
                        background: dTNow && "lightgray",
                      }}
                      value={oDatetime}
                      min={oMinDatetime}
                      max={oMaxDatetime}
                      onChange={(e) => handleChangeODateTime(e.target.value)}
                    />
                    <span className="label-imp">*</span>
                  </div>
                  {ValError[4] && (
                    <p className="label-error-dt">{ValError[4]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="ProductSelect">
                    Account:
                  </label>
                  <Select
                    className="myreact-select container-select"
                    value={to_acc}
                    onChange={handleChangeAcc_To}
                    options={to_acc_Options}
                    isSearchable
                    placeholder="Select Account"
                    isClearable
                  />
                  {ValError[0] && (
                    <p className="label-error-select">{ValError[0]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12 mt-3 mb-3">
                <div className="form-group">
                  <label className="label" htmlFor="ProductSelect">
                    Type:
                  </label>
                  <Select
                    className="myreact-select container-select"
                    value={from_acc}
                    onChange={handleChangeAcc_From}
                    options={from_acc_Options}
                    isSearchable
                    placeholder="Select Type"
                    isClearable
                  />
                  {ValError[1] && (
                    <p className="label-error-select">{ValError[1]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Amount:</label>
                  <input
                    type="number"
                    step="1.00"
                    min="0"
                    value={amount_paid}
                    onChange={handleChangeAmount}
                    // defaultValue={0.0}
                    name="amount_paid"
                    placeholder="Enter Amount"
                    className="input"
                  />
                  <div>
                    {ValError[2] && (
                      <p className="label-error">{ValError[2]}</p>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Note:</label>
                  <textarea
                    placeholder="Enter Note"
                    id="noteTextarea"
                    value={acc_notes}
                    onChange={handleChangeAccNote}
                    className="textarea"
                    onBlur={(e) => ValidText1(e.target.value, 3)}
                  />
                  {ValError[3] && (
                    <p className="label-error-dt">{ValError[3]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12 ">
                <div className="form-group" style={{ textAlign: "center" }}>
                  <Button
                    color="white"
                    className="custom-button center"
                    bgColor={currentColor}
                    text="Insert"
                    borderRadius="10px"
                    onClick={handleAddItem1}
                  />
                </div>
              </div>
              <br />
            </Col>
            <Col md={2} className="container-col">
              <br />
              <br />
              <br />
              <div className="col-lg-12 mt-10">
                <div className="form-group mt-8">
                  <label className="label">
                    Bal: {formatCurrency(to_acc_bal)}
                  </label>
                </div>
              </div>
              <br />
            </Col>
            <Col md={7} className="container-col">
              <GridComponent
                className="custom-grid"
                dataSource={AllAccounts}
                allowPaging={true}
                pageSettings={{ pageSize: 16 }}
                allowSorting
                allowResizing
                // toolbar={["Search"]}
                recordDoubleClick={handleRowSelected}
                selectionSettings={settings}
                rowHeight={36}
              >
                <ColumnsDirective>
                  {customersGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject
                  services={[Resize, Page, Toolbar, Selection, Sort, Filter]}
                />
              </GridComponent>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button"
          bgColor={currentColor}
          text="Add"
          borderRadius="10px"
          onClick={handleSubmit}
          disabled={loadBtn}
        />
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default AddJournal;
