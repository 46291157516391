import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = "https://blcapi.codenexsys.com";

export const GetOrderProductDetailById = async (
  product_id,
  store_id,
  user_id,
  token
) => {
  try {
    //console.log("GetOrderProductDetailById");
    const response = await axios.post(
      `${BASE_URL}/product/GetOrderProductDetailById`,
      {
        product_id: product_id,
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductByStoreID = async (store_id, user_id, token) => {
  try {
    //console.log("GetProductByStoreID");
    const response = await axios.post(
      `${BASE_URL}/stores/getProductByStoreID`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllProductByStoreID = async (store_id, user_id, token) => {
  try {
    //console.log("getAllProductByStoreID");
    const response = await axios.post(
      `${BASE_URL}/stores/getAllProductByStoreID`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getProductFilterSubString = async (
  store_id,
  sub_string,
  str,
  user_id,
  token
) => {
  try {
    //console.log("getProductFilterSubString");
    const response = await axios.post(
      `${BASE_URL}/stores/getProductFilterSubString`,
      {
        store_id: store_id,
        sub_string: sub_string,
        str: str,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductById = async (product_id, user_id, token) => {
  try {
    //console.log("GetProductById");
    const response = await axios.post(
      `${BASE_URL}/product/getProductById`,
      {
        product_id: product_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckProdNameCodeExist = async (name, code, user_id, token) => {
  try {
    //console.log("CheckProdNameCodeExist");
    const response = await axios.post(
      `${BASE_URL}/product/CheckProdNameCodeExist`,
      {
        name: name,
        code: code,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductImagesById = async (product_id, user_id, token) => {
  try {
    //console.log("GetProductImagesById");
    const response = await axios.post(
      `${BASE_URL}/product/getProductImagesById`,
      {
        product_id: product_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductVideoById = async (product_id, user_id, token) => {
  try {
    //console.log("GetProductVideoById");
    const response = await axios.post(
      `${BASE_URL}/product/getProductVideoById`,
      {
        product_id: product_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductByIdSale = async (
  product_id,
  store_id,
  user_id,
  token
) => {
  try {
    //console.log("GetProductByIdSale");
    const response = await axios.post(
      `${BASE_URL}/product/getProductByIdSale`,
      {
        product_id: product_id,
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSpecialOrderProdDetail = async (
  store_id,
  vendor_id,
  user_id,
  token
) => {
  try {
    //console.log("SpecialOrder");
    const response = await axios.post(
      `${BASE_URL}/purchase/GetSpecialOrderProdDetail`,
      {
        store_id: store_id,
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSpecialOrderProdByStoreID = async (
  store_id,
  vendor_id,
  user_id,
  token
) => {
  try {
    //console.log("GetSpecialOrderProdByStoreID");
    const response = await axios.post(
      `${BASE_URL}/special/GetSpecialOrderProdByStoreID`,
      {
        store_id: store_id,
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetInvoiceProdBySoId = async (so_id, user_id, token) => {
  try {
    //console.log("GetInvoiceProdBySoId");
    const response = await axios.post(
      `${BASE_URL}/Invoice/GetInvoiceProdBySoId`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPOProdByPoId = async (po_id, user_id, token) => {
  try {
    //console.log("GetPOProdByPoId");
    const response = await axios.post(
      `${BASE_URL}/Invoice/GetPOProdByPoId`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetShipmentProductsBySO_ID = async (so_id, user_id, token) => {
  try {
    //console.log("GetShipmentProductsBySO_ID");
    const response = await axios.post(
      `${BASE_URL}/sale/getShipmentProductsBySO_ID`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetReceiveProductsByPO_ID = async (po_id, user_id, token) => {
  try {
    //console.log("GetReceiveProductsByPO_ID");
    const response = await axios.post(
      `${BASE_URL}/purchase/getReceiveProductsByPO_ID`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteEditSaleOrderProduct = async (
  JsonObject,
  user_id,
  token
) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/deleteEditSaleOrderProduct`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const DeleteEditPurchaseOrderProduct = async (
  JsonObject,
  user_id,
  token
) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/purchase/deleteEditPurchaseOrderProduct`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const CheckProductBeforeDel = async (product_id, user_id, token) => {
  try {
    //console.log("CheckProductBeforeDel");
    const response = await axios.post(
      `${BASE_URL}/product/CheckProductBeforeDel`,
      {
        product_id: product_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DelProduct = async (product_id, user_id, token) => {
  try {
    //console.log("DelProduct");
    const response = await axios.post(
      `${BASE_URL}/product/DelProduct`,
      {
        product_id: product_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getProductsByFilter = async (
  unit_id,
  brand_id,
  category_id,
  user_id,
  token
) => {
  try {
    //console.log("getProductsByFilter");
    const response = await axios.post(
      `${BASE_URL}/product/getProductsByFilter`,
      {
        unit_id: unit_id,
        brand_id: brand_id,
        category_id: category_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllProducts = async (user_id, token) => {
  try {
    //console.log("GetAllProducts");
    const response = await axios.post(
      `${BASE_URL}/product/getallproducts`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllProductsInv = async (user_id, token) => {
  try {
    //console.log("GetAllProductsInv");
    const response = await axios.post(
      `${BASE_URL}/product/getAllProductsInv`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAssignedProductDetailById = async (
  vendor_id,
  user_id,
  token
) => {
  try {
    //console.log("GetAssignedProductDetailById");
    const response = await axios.post(
      `${BASE_URL}/product/GetAssignedProductDetailById`,
      {
        vendor_id: vendor_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const ProductInAllStores = async (product_id, user_id, token) => {
  try {
    //console.log("ProductInAllStores");
    const response = await axios.post(
      `${BASE_URL}/product/ProductInAllStores`,
      {
        product_id: product_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductNameCode = async (user_id, token) => {
  try {
    //console.log("getProductNameCode");
    const response = await axios.post(
      `${BASE_URL}/product/getProductNameCode`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductNameCodeInv = async (user_id, token) => {
  try {
    //console.log("GetProductNameCodeInv");
    const response = await axios.post(
      `${BASE_URL}/product/getProductNameCodeInv`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductsByStoreId = async (store_id, user_id, token) => {
  try {
    //console.log("GetProductNameIdCode");
    //console.log(store_id);
    // const headers = {
    //   "Content-Type": "application/json",
    //   store_id: store_id,
    // };
    const response = await axios.post(
      `${BASE_URL}/product/getProductsByStoreId`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    // console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteProductById = async (unit_id, user_id, token) => {
  try {
    //console.log("DeleteUnitById");
    const response = await axios.post(
      `${BASE_URL}/unit/deleteunitbyid?id`,
      {
        unit_id: unit_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddProductApi = async (
  ProductName,
  ProductCode,
  Details,
  Discount,
  UnitPrice,
  Unit,
  Brand,
  Category,
  DisplayProduct,
  ActiveProduct,
  video,
  cost_price,
  user_id,
  token
) => {
  try {
    const formData = new FormData();
    formData.append("video", video);
    formData.append("name", ProductName);
    formData.append("code", ProductCode);
    formData.append("details", Details);
    formData.append("unit_price", UnitPrice);
    formData.append("discount", Discount);
    formData.append("unit_id", Unit);
    formData.append("brand_id", Brand);
    formData.append("category_id", Category);
    formData.append("display_product", DisplayProduct);
    formData.append("active_product", ActiveProduct);
    formData.append("cost_price", cost_price);
    const response = await axios.post(
      `${BASE_URL}/product/addproduct`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );

    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const AddProductImage = async (
  product_id,
  ProductImage,
  user_id,
  token
) => {
  try {
    //console.log("addapi here");
    const formData = new FormData();
    formData.append("product_id", product_id);
    formData.append("product_image", ProductImage);
    //console.log(formData);
    const response = await axios.post(
      `${BASE_URL}/product/addProductImage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );

    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const RemoveProd_fromShipmentTrans = async (
  JsonObject,
  user_id,
  token
) => {
  try {
    // console.log(JSON.stringify(JsonObject));
    const response = await axios.post(
      `${BASE_URL}/sale/RemoveProd_fromShipmentTrans`,
      JsonObject,
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditProductApi = async (
  product_id,
  code,
  name,
  details,
  unit_price,
  discount,
  unit_id,
  category_id,
  brand_id,
  display_product,
  active_product,
  video,
  flag,
  user_id,
  token
) => {
  try {
    //console.log("EditProductApi");

    const formData = new FormData();
    formData.append("video", video);
    formData.append("name", name);
    formData.append("code", code);
    formData.append("details", details);
    formData.append("unit_price", unit_price);
    formData.append("discount", discount);
    formData.append("unit_id", unit_id);
    formData.append("brand_id", brand_id);
    formData.append("category_id", category_id);
    formData.append("display_product", display_product);
    formData.append("active_product", active_product);
    formData.append("product_id", product_id);
    formData.append("flag", flag);

    const response = await axios.put(
      `${BASE_URL}/product/editProductApi`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );

    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditProductImagesById = async (
  image_id,
  ProductImage,
  user_id,
  token
) => {
  try {
    //console.log("EditProductImagesById");
    const formData = new FormData();
    formData.append("image_id", image_id);
    formData.append("product_image", ProductImage);
    //console.log(formData);
    const response = await axios.post(
      `${BASE_URL}/product/editProductImagesById`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );

    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const GetNumProd = async (user_id, token) => {
  try {
    // const userDataString = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.post(
      `${BASE_URL}/overview/getNumProd`,
      {
        // token: userDataString.token,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
