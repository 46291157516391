import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = "https://blcapi.codenexsys.com";

export const GetInvStkReport = async (
  dateFrom,
  dateTo,
  store_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/inventoryStockReport`,
      { date_from: dateFrom, date_to: dateTo, store_id: store_id },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccLedgerReport = async (
  dateFrom,
  dateTo,
  store_id,
  account_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/accountLedgerReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        store_id: store_id,
        account_id: account_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccountListforRep = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllAccounts");
    const response = await axios.post(
      `${BASE_URL}/report/getAccountListforRep`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
