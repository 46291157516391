import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
// import { FiShoppingCart } from "react-icons/fi";
// import { BsChatLeft } from "react-icons/bs";
// import { RiNotification3Line } from "react-icons/ri";
// import { MdKeyboardArrowDown } from "react-icons/md";
// import { TfiNewWindow } from "react-icons/tfi";
// import { ImNewTab } from "react-icons/im";
// import { RxOpenInNewWindow } from "react-icons/rx";
// import { MdOpenInNew } from "react-icons/md";

import { MdPointOfSale } from "react-icons/md";
import { TbShoppingCartPlus } from "react-icons/tb";
import { FcMoneyTransfer } from "react-icons/fc";
import { TbCashRegister } from "react-icons/tb";
import { LuFileBox } from "react-icons/lu";
import { BsCashCoin } from "react-icons/bs";
//import { PiCashRegisterLight } from "react-icons/pi";

import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { IoMdLogOut } from "react-icons/io";

// import avatar from "../data/avatar.jpg";
// import { Cart, Chat, Notification, UserProfile } from '.';
import { useStateContext } from "../contexts/ContextProvider";
import { Col, Row } from "react-bootstrap";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-2 m-1 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    userPermID,
    userName,
    userProfile,
    setScreenSize,
    screenSize,
    handleLogoutClick,
    storeG,
    setStoreGlobal,
    storesG,
  } = useStateContext();

  useEffect(() => {
    //console.log(storeG);
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 1100) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  const handleNewSale = () => {
    try {
      const path = `/Sales/AddSaleOrder/${storeG.store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNewPurchase = () => {
    try {
      const path = `/Purchase/AddPurchaseOrder/${storeG.store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNewExpense = () => {
    try {
      const path = `/Expense/AddExpense/${storeG.store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNewReceipt = () => {
    try {
      const path = `/Receipt/AddReceipt/${storeG.store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNewPayment = () => {
    try {
      const path = `/Payment/AddPayment/${storeG.store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleStockReport = () => {
    try {
      const path = `/Inventory/InventoryStockReport`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSetStore = (e) => {
    const s_id = storesG.find((item) => item.name === e.target.value);
    setStoreGlobal(s_id.store_id, e.target.value);
    //console.log(s_id.store_id, e.target.value);
  };

  return (
    <div
      fluid="true"
      className="g-0 p-1 w-full md:ml-6 md:mr-6"
      style={{ maxWidth: "calc(100% - 72px)" }}
    >
      <Row>
        <Col sm={1}>
          <NavButton
            title="Menu"
            customFunc={handleActiveMenu}
            color={currentColor}
            icon={<AiOutlineMenu />}
            style={{ alignSelf: "left" }}
          />
        </Col>
        <Col
          sm={true}
          className="flex justify-end"
          // style={{ disply: "flex", justifyContent: "right" }}
        >
          {/* <NavButton
            title="Chat"
            dotColor={currentColor}
            customFunc={() => handleClick("chat")}
            color={currentColor}
            icon={<IoMdLogOut />}
          />
          <NavButton
            title="Notification"
            dotColor="rgb(254, 201, 15)"
            customFunc={() => handleClick("notification")}
            color={currentColor}
            icon={<RiNotification3Line />}
          /> */}
          {userPermID.includes(5014) && (
            <NavButton
              title="New Sale"
              customFunc={handleNewSale}
              color="gray"
              icon={
                <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                  <MdPointOfSale />
                </div>
              }
            />
          )}
          {userPermID.includes(5017) && (
            <NavButton
              title="New Purchase"
              customFunc={handleNewPurchase}
              color="gray"
              icon={
                <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                  <TbShoppingCartPlus />
                </div>
              }
            />
          )}
          {userPermID.includes(5041) && (
            <NavButton
              title="New Expense"
              customFunc={handleNewExpense}
              color="gray"
              icon={
                <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                  <FcMoneyTransfer />
                </div>
              }
            />
          )}
          {userPermID.includes(5032) && (
            <NavButton
              title="New Receipt"
              customFunc={handleNewReceipt}
              color="gray"
              icon={
                <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                  <TbCashRegister />
                </div>
              }
            />
          )}
          {userPermID.includes(5035) && (
            <NavButton
              title="New Payment"
              customFunc={handleNewPayment}
              color="gray"
              icon={
                <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                  <BsCashCoin />
                </div>
              }
            />
          )}
          {userPermID.includes(5004) && (
            <NavButton
              title="Stock Report"
              customFunc={handleStockReport}
              color="gray"
              icon={
                <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                  <LuFileBox />
                </div>
              }
            />
          )}
          <div
            style={{
              textAlign: "right",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          >
            <select
              className="select-custom rounded-xl"
              value={storeG.name}
              onChange={handleSetStore}
              disabled={!userPermID.includes(4999)}
              style={{
                fontSize: "15px",
                padding: "7px",
                background: !userPermID.includes(4999) && "lightgrey",
              }}
            >
              {storesG?.map((item) => (
                <option key={item.store_id}>{item.name}</option>
              ))}
            </select>
          </div>
          <TooltipComponent content="Profile" position="BottomCenter">
            <div
              // className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
              className="flex items-center gap-2 p-1 h-100 hover:bg-light-gray rounded-lg text-gray-400 font-bold text-14"
              //onClick={() => handleClick("userProfile")}
            >
              {userProfile && (
                <img
                  className="rounded-full w-10 h-10"
                  src={`data:image/jpeg;base64,${userProfile}`}
                  alt="user-profile"
                />
              )}
              {userName}
              {/* <p>
                {/* <span className="text-gray-400 text-14">Hi,</span>
                <span className="text-gray-400 font-bold ml-1 text-14">
                  Michael
                </span>
              </p> */}
              {/* <MdKeyboardArrowDown className="text-gray-400 text-14" /> */}
            </div>
          </TooltipComponent>
          <NavButton
            title="Sign Out"
            customFunc={handleLogoutClick}
            // onClick={handleBackClick}
            color={currentColor}
            icon={
              <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                <IoMdLogOut />
              </div>
            }
          />
          {/* 
        {isClicked.cart && (<Cart />)}
        {isClicked.chat && (<Chat />)}
        {isClicked.notification && (<Notification />)}
        {isClicked.userProfile && (<UserProfile />)} */}
        </Col>
      </Row>
    </div>
  );
};

export default Navbar;
