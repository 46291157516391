import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { ValidText } from "../../contexts/Utils";
import {
  //GetAllPaymentDetail,
  editPayment,
  //GetPaymentDetailByID,
  getAcc1BalFrom,
  getAccNamesAssetCash,
  getAccNamesVen,
  getPaymentOrders,
} from "../../api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";
import Select from "react-select";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Toolbar,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { trackPromise } from "react-promise-tracker";

const EditPayment = () => {
  const navigate = useNavigate();
  const { currentColor, userID, userToken } = useStateContext();
  const [acc_notes, setAccNotes] = useState("");
  const [amount_paid, setamount_paid] = useState(0);
  // const [gettoacc, setgettoacc] = useState("");
  // const [getfromacc, setgetfromacc] = useState("");
  const [ValError, setValError] = useState([]);
  const [AllAccounts, setAllAccounts] = useState("");
  const [r_id, setr_id] = useState([]);

  const [to_acc, setto_acc] = useState("");
  const [to_acc_Options, setto_acc_Options] = useState([]);
  const [GetAccount_To, setGetAccount_To] = useState([]);
  const [from_acc, setfrom_acc] = useState("");
  const [from_acc_Options, setfrom_acc_Options] = useState([]);
  const [GetAccount_From, setGetAccount_From] = useState([]);
  const [loadBtn, setLoadBtn] = useState(false);

  const [to_acc_bal, setto_acc_bal] = useState("");
  const [from_acc_bal, setfrom_acc_bal] = useState("");
  const [store_id, setStore_id] = useState(0);
  const [acc_from_id, setacc_from_id] = useState(null);
  const [acc_to_id, setacc_to_id] = useState(null);
  const [acc_from_id1, setacc_from_id1] = useState(null);
  const [acc_to_id1, setacc_to_id1] = useState(null);
  let param = useParams();
  const rid = param.r_id;
  const grid = useRef(null);
  const [oDatetime, setODatetime] = useState("");

  const customerGridImage2 = (props) => <div>{"P" + props.po_id}</div>;

  const customersGrid = [
    {
      headerTemplate: ` `,
      type: "checkbox",
      minWidth: "35",
      width: "35",
      maxWidth: "35",
      textAlign: "Center",
    },
    {
      headerText: "PO#",
      field: "po_id",
      template: customerGridImage2,
      minWidth: "100",
      width: "100",
      maxWidth: "110",

      textAlign: "center",
    },
    {
      field: "order_date",
      headerText: "DateTime",
      minWidth: "140",
      width: "140",
      maxWidth: "160",
      textAlign: "Center",
    },
    {
      field: "amt_paid",
      headerText: "Paid Amt",
      minWidth: "120",
      width: "120",
      maxWidth: "140",
      format: "C2",
      textAlign: "right",
    },
    {
      field: "amount_paid",
      headerText: "Amt Paid",
      minWidth: "120",
      width: "120",
      maxWidth: "140",
      format: "C2",
      textAlign: "right",
    },
    {
      field: "amount_pending",
      headerText: "Amt Pend",
      minWidth: "120",
      width: "120",
      maxWidth: "140",
      format: "C2",
      textAlign: "right",
    },
  ];

  const handleChangeAccNote = (e) => {
    setAccNotes(e.target.value);
  };

  const handleChangeAmountPaid = (e) => {
    setamount_paid(e.target.value);
  };

  const handleChangeAcc_To = async (selectedOption) => {
    TimeoutUtility.resetTimeout();
    setto_acc_bal("");
    if (selectedOption === null) {
      setto_acc("");
      setacc_to_id("");
    } else {
      if (selectedOption.label !== "") {
        const updatedErrors = [...ValError];
        updatedErrors[0] = "";
        setValError(updatedErrors);
      }
      if (selectedOption && selectedOption.value) {
        if (selectedOption.value) {
          // try {
          //   const resp = await getAcc1BalFrom(selectedOption.value, userID, userToken);
          setto_acc_bal(selectedOption.bal);
          // } catch (err) {
          //   console.log(err.message);
          // }
          setto_acc(selectedOption);
          setacc_to_id(selectedOption.value);
        }
      }
    }
  };

  const handleChangeAcc_From = async (selectedOption) => {
    TimeoutUtility.resetTimeout();
    setfrom_acc_bal("");
    if (selectedOption === null) {
      setfrom_acc("");
      setacc_from_id("");
    } else {
      if (selectedOption.label !== "") {
        const updatedErrors = [...ValError];
        updatedErrors[1] = "";
        setValError(updatedErrors);
      }
      if (selectedOption && selectedOption.value) {
        if (selectedOption.value) {
          // try {
          //   const resp = await getAcc1BalFrom(selectedOption.value, userID, userToken);
          setfrom_acc_bal(selectedOption.bal);
          // } catch (err) {
          //   console.log(err.message);
          // }

          setfrom_acc(selectedOption);
          setacc_from_id(selectedOption.value);
        }
      }
    }
  };

  const ValidText1 = (txt, ii) => {
    const updatedErrors = [...ValError];
    if (txt.trim().length === 0) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return false;
    }
    if (ValidText(txt)) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return true;
    }
    updatedErrors[ii] = "Invalid field!";
    setValError(updatedErrors);
    return false;
  };

  const formatCurrency = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    setr_id(args.rowIndexes);
  };

  const handleRowDeSelected = (args) => {
    TimeoutUtility.resetTimeout();
    setr_id(r_id.filter((item) => item !== args.rowIndex));
  };

  const handleBackClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("Back");
      navigate("/Payment");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    TimeoutUtility.resetTimeout();
    e.preventDefault();
    setLoadBtn(true);
    var resp = 0;
    try {
      setValError([]);
      const updatedErrors = [...ValError];

      if (to_acc === "") {
        updatedErrors[0] = "Please select Paid To Account!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[0] = "";

      if (from_acc === "") {
        updatedErrors[1] = "Please select Paid From Account!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[1] = "";

      if (amount_paid === "") {
        updatedErrors[2] = "Please enter Amount Paid!";
        setValError(updatedErrors);
        return;
      }
      if (amount_paid < 1) {
        updatedErrors[2] = "Amount paid must be greater than 0!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[2] = "";

      if (acc_notes) {
        if (ValidText1(acc_notes, 3) === false) {
          return;
        }
      }
      updatedErrors[3] = "";

      var aa = null;
      var b = null;
      if (acc_from_id === null) {
        aa = acc_from_id1;
      } else {
        aa = acc_from_id;
      }
      if (acc_to_id === null) {
        b = acc_to_id1;
      } else {
        b = acc_to_id;
      }
      r_id.sort((a, b) => a - b);
      //console.log(r_id);
      const tableData = r_id.map((i) => AllAccounts[i]);
      //console.log(tableData);

      const sumAmt = tableData
        .map((i) => i.amount_pending + i.amt_paid)
        .reduce((a, b) => a + b, 0);
      //console.log(sumAmt, amount_paid.value);

      if (sumAmt < amount_paid && r_id.length !== AllAccounts.length) {
        if (
          window.confirm(
            "Amount Paid should be less than or equal to selected Purchase Orders Amount Pending!" +
              "\n\nSelect more or all Purchase Orders."
          )
        ) {
          const response = await editPayment(
            param.r_id,
            aa,
            b,
            11,
            amount_paid,
            acc_notes,
            true,
            tableData,
            userID,
            userToken
          );
          resp = response.status;
          if (resp === 200) {
            alert("Payment Update Successfully");
          } else {
            alert("Payment Failed to Update");
          }
        }
      } else {
        const response = await editPayment(
          param.r_id,
          aa,
          b,
          11,
          amount_paid,
          acc_notes,
          true,
          tableData,
          userID,
          userToken
        );
        // console.log(response, "Response");
        resp = response.status;
        if (resp === 200) {
          alert("Payment Update Successfully");
        } else {
          alert("Payment Failed to Update");
        }
      }
    } catch (err) {
    } finally {
      setLoadBtn(false);
      if (resp === 200) {
        navigate("/Payment");
      }
    }
  };

  useEffect(() => {
    const fetchAcc_ToOptions = async () => {
      const fetchedProductOptions = GetAccount_To.map((item) => ({
        label: item.name,
        value: item.account_id,
        bal: item.end_balance,
      }));
      setto_acc_Options(fetchedProductOptions);
    };
    fetchAcc_ToOptions();
  }, [GetAccount_To]);

  useEffect(() => {
    const fetchAcc_ToOptions = async () => {
      const fetchedProductOptions = GetAccount_From.map((item) => ({
        label: item.name,
        value: item.account_id,
        bal: item.end_balance,
      }));
      setfrom_acc_Options(fetchedProductOptions);
    };
    fetchAcc_ToOptions();
  }, [GetAccount_From]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (AllAccounts && grid.current) {
        const indic = AllAccounts.reduce((arr, e, i) => {
          e.amt_paid > 0 && arr.push(i);
          return arr;
        }, []);
        grid.current.selectionModule.selectedRowIndexes = indic;
      }
    }
    fetchData();
  }, [AllAccounts]);

  useEffect(() => {
    async function fetchData() {
      if (userID && userToken && store_id && store_id !== 0) {
        var to_det = null;
        var from_det = null;
        try {
          await getAccNamesVen(store_id, userID, userToken)
            .then((resp) => {
              //setGetAccount_To(resp.data || []);
              to_det = resp.data;
            })
            .catch((err) => {
              console.log(err.message);
            });

          await getAccNamesAssetCash(store_id, userID, userToken)
            .then((resp1) => {
              //setGetAccount_From(resp1.data || []);
              from_det = resp1.data;
            })
            .catch((err) => {
              console.log(err.message);
            });
          const resp1 = await getAcc1BalFrom(rid, userID, userToken);

          // setgetfromacc(resp1.data[0].from);
          setAccNotes(resp1.data[0].note);
          setamount_paid(resp1.data[0].amount_paid);
          //console.log(to_det);
          //console.log(resp1.data[0].acc_to_id);

          var d1 = new Date(resp1.data[0].datetime);
          d1.setMinutes(d1.getMinutes() - d1.getTimezoneOffset());
          setODatetime(d1.toISOString().slice(0, 19));

          var d2 = d1.toISOString().slice(0, 19);
          if (to_det.length > 0) {
            const filteredAccTo = to_det.filter(
              (item) => new Date(item.open_datetime) <= new Date(d2)
            );
            setGetAccount_To(filteredAccTo);
          }
          if (from_det.length > 0) {
            const filteredAccFrom = from_det.filter(
              (item) => new Date(item.open_datetime) <= new Date(d2)
            );
            setGetAccount_From(filteredAccFrom);
          }

          const toAccOption = to_det.find(
            (option) => option.account_id === resp1.data[0].acc_to_id
          );
          //console.log(toAccOption);

          const toAccOption1 = {
            label: toAccOption.name,
            value: toAccOption.account_id,
            bal: toAccOption.end_balance,
            o_datetime: toAccOption.open_datetime,
          };
          setto_acc(toAccOption1);
          setto_acc_bal(toAccOption1.bal);

          const fromAccOption = from_det.find(
            (option) => option.account_id === resp1.data[0].acc_from_id
          );
          //console.log(fromAccOption);

          const fromAccOption1 = {
            label: fromAccOption.name,
            value: fromAccOption.account_id,
            bal: fromAccOption.end_balance,
            o_datetime: toAccOption.open_datetime,
          };

          setfrom_acc(fromAccOption1);
          setfrom_acc_bal(fromAccOption1.bal);
          setacc_to_id1(toAccOption.account_id);
          setacc_from_id1(fromAccOption.account_id);
          setacc_to_id(toAccOption.account_id);
          setacc_from_id(fromAccOption.account_id);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
    fetchData();
  }, [rid, userID, userToken, store_id]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (rid && userID && userToken) {
        const resp1 = await getAcc1BalFrom(rid, userID, userToken);
        setStore_id(resp1.data[0].store_id);
      }
    }
    fetchData();
  }, [rid, userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (
        acc_to_id &&
        acc_to_id !== "" &&
        rid &&
        oDatetime !== "" &&
        userID &&
        userToken
      ) {
        var Rdt = oDatetime.replace("T", " ");
        trackPromise(
          Promise.all([
            getPaymentOrders(rid, 11, acc_to_id, Rdt, userID, userToken),
          ])
            .then(([result]) => {
              setAllAccounts(result.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              //setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [rid, acc_to_id, oDatetime, userID, userToken]);

  const settings = { checkboxMode: "Default" };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="EDIT PAYMENT" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={4} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">DateTime:</label>
                  <div className="flex mb-2">
                    <input
                      disabled
                      className="input"
                      id="o_date"
                      type="datetime-local"
                      value={oDatetime}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="ProductSelect">
                    Paid To:
                  </label>
                  <div className="flex mb-2">
                    <Select
                      value={to_acc}
                      onChange={handleChangeAcc_To}
                      options={to_acc_Options}
                      isSearchable
                      placeholder="Select Paid To Account"
                      isClearable
                      className="myreact-select container-select"
                    />
                    <span className="label-imp-select">*</span>
                  </div>
                  {ValError[0] && (
                    <p className="label-error-select">{ValError[0]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="ProductSelect">
                    Received From:
                  </label>
                  <div className="flex mb-2">
                    <Select
                      className="myreact-select container-select"
                      value={from_acc}
                      onChange={handleChangeAcc_From}
                      options={from_acc_Options}
                      isSearchable
                      placeholder="Select Paid From Account"
                      isClearable
                    />
                    <span className="label-imp-select">*</span>
                  </div>
                  {ValError[1] && (
                    <p className="label-error-select">{ValError[1]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Amount Paid:</label>
                  <input
                    type="number"
                    step="1.00"
                    min="0"
                    // defaultValue={0.0}
                    value={amount_paid}
                    onChange={handleChangeAmountPaid}
                    name="amount_paid"
                    placeholder="Enter Amount Paid"
                    className="input"
                  />
                  <span className="label-imp">*</span>
                  {ValError[2] && <p className="label-error">{ValError[2]}</p>}
                </div>
              </div>
              <br />

              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Note:</label>
                  <textarea
                    placeholder="Enter Note"
                    id="noteTextarea"
                    value={acc_notes}
                    onChange={handleChangeAccNote}
                    className="textarea"
                    onBlur={(e) => ValidText1(e.target.value, 3)}
                  />
                  {ValError[3] && (
                    <p className="label-error-dt">{ValError[3]}</p>
                  )}
                </div>
              </div>
              <br />
            </Col>
            <Col md={2} className="container-col">
              <br />
              <br />
              <br />
              <br />
              <div className="col-lg-12">
                <div className="form-group mt-3">
                  <label className="label">
                    Bal: {formatCurrency(to_acc_bal)}
                  </label>
                </div>
              </div>
              <br />

              <div className="col-lg-12">
                <div className="form-group mt-8">
                  <label className="label">
                    Bal: {formatCurrency(from_acc_bal)}
                  </label>
                </div>
              </div>
              <br />
            </Col>
            <Col md={6} className="container-col">
              <GridComponent
                selectionSettings={settings}
                ref={grid}
                className="custom-grid1"
                dataSource={AllAccounts}
                allowPaging={false}
                allowResizing
                rowSelected={handleRowSelected}
                rowDeselected={handleRowDeSelected}
                rowHeight={18}
                height={640}
              >
                <ColumnsDirective>
                  {customersGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject services={[Resize, Page, Toolbar, Selection, Filter]} />
              </GridComponent>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button ml-2"
          bgColor={currentColor}
          text="Update"
          borderRadius="10px"
          onClick={handleSubmit}
          disabled={loadBtn}
        />
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button ml-2"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default EditPayment;
