import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GetAllCustomersName,
  getProductFilterSubString,
  GetTaxByStoreId,
  CheckCusNameExist,
  AddCustomerApi,
  getAllVendorsNameByPurchaseProduct,
  AddEstimationApi,
  GetProductByIdSale,
  GetAllCustomerTypes,
  //GetProductNameCodeInv,
  //GetSaleCustomerById,
  //GetProductByStoreID,
  //EditSaleOrderStatus,
} from "../../api";
import {
  validateEmail,
  validateName,
  ValidPhone,
  ValidWebsite,
  ValidText,
} from "../../contexts/Utils";
import { Header } from "../../components";
import Select from "react-select";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/sale.css";
import { Card } from "react-bootstrap";
//import Sidebar from "../../components/ViewOrderProduct";
import "../../styles/viewCustomer.css";
import { Col, Container, Row } from "react-bootstrap";
import { openNewTab } from "../../contexts/SO_Invoice";

const AddEstimation = () => {
  const {
    currentColor,
    setActiveProdMenu,
    setActiveProdMenuId,
    userID,
    userToken,
  } = useStateContext();
  //const navigate = useNavigate();
  //const [Customer, setCustomer] = useState("");
  //const [GetProduct, setGetProduct] = useState([]);
  //const [CustomerDetail, setCustomerDetail] = useState([]);
  //const [cusPhone, setcusPhone] = useState("");
  //const [projectname1, setprojectname1] = useState("");
  //const [trackingno, settrackingno] = useState("");
  //const [shipmethod, setshipmethod] = useState("");
  //const [OrderStatus, setOrderStatus] = useState("MARK AS CLOSE");
  //const [orderstatuslable, setorderstatuslable] = useState("OPEN");
  //const [productID, setproductID] = useState("");
  //const [toggle, setToggle] = useState(false);
  //const [req_date, setreq_date] = useState();
  const [GetCustomer, setGetCustomer] = useState([]);
  const [CustomerOptions, setCustomerOptions] = useState([]);
  const [product, setProducts] = useState("");
  const [qty, setqty] = useState(1.0);
  const [productOptions, setProductOptions] = useState([]);
  const [p_code, setp_code] = useState("");
  const [customer_ids, setcustomer_ids] = useState("");
  const [CardList, setcartList] = useState([]);
  const [total_amount, settotalAmount] = useState(0.0);
  const [tax, settax] = useState(0.0);
  const [totaldiscount, settotaldiscount] = useState(0.0);
  const [total_item, settotalitem] = useState(0.0);
  const [index1, setindex1] = useState("");
  const [grandtotal, setgrandtotal] = useState(0.0);
  const [note, setnote] = useState("");
  const [ProductStr, setProductStr] = useState("");
  const [projectname, setprojectname] = useState("");
  const [acc_from_bal, setacc_from_bal] = useState("");
  const [projectname2, setprojectname2] = useState(0);
  const [ValError, setValError] = useState([]);
  const [ActiveProduct, setActiveProduct] = useState(0);
  //const [aexist, setaexist] = useState("");
  const [name, setname] = useState("");
  const [getcType, setGetcType] = useState([]);
  const [cType, setcType] = useState("select");
  const [dot, setdot] = useState(0);
  const [isPageFrozen, setIsPageFrozen] = useState(false);
  const [saveClick, setsaveClick] = useState(false);
  const [so_idss, setso_idss] = useState("");
  const [print_flag, setprint_flag] = useState(false);
  let param = useParams();
  const store_id = param.store_id;

  const [formData, setFormData] = useState({
    est_sale: "E",
    customer_id: 0,
    project_name: "",
    total: "",
    amount_paid: "",
    amount_pending: "",
    user_id: null,
    so_note: "",
    total_price: 0,
    discount: "",
    tax: 0,
    shipment: 0,
    o_datetime: "",
    store_id: "",
    sale_products: [],
  });

  const keypadButtons = [
    "7",
    "8",
    "9",
    "4",
    "5",
    "6",
    "1",
    "2",
    "3",
    ".",
    "0",
    "00",
  ];

  const [activeInput, setActiveInput] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [defaultCustomer, setDefaultCustomer] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  //const [defaultSupplier, setDefaultSupplier] = useState(null);
  const [supplier_ids, setsupplier_ids] = useState("");
  const [supplier_name, setsupplier_name] = useState("-");
  const [SupplierOptions, setSupplierOptions] = useState([]);
  const [GetSupplier, setGetSupplier] = useState([]);
  const [oDatetime, setODatetime] = useState("");
  const [oMinDatetime, setOMinDatetime] = useState("");
  const [oMaxDatetime, setOMaxDatetime] = useState("");
  const [dTNow, setDTNow] = useState(1);
  //const [ship_qty, setship_qty] = useState(0);

  const handleInputClick = (inputId, index) => {
    setActiveInput(inputId);
    setindex1(index);
  };

  const handleCopyClick = (e) => {
    e.preventDefault();
    TimeoutUtility.resetTimeout();
    console.log(document.forms[0]);
    document.forms[0]["sstreet"].value = document.forms[0]["bstreet"].value;
    document.forms[0]["scity"].value = document.forms[0]["bcity"].value;
    document.forms[0]["sstate"].value = document.forms[0]["bstate"].value;
    document.forms[0]["szip"].value = document.forms[0]["bzip"].value;
    document.forms[0]["sphone"].value = document.forms[0]["bphone1"].value;
    document.forms[0]["scountry"].value = document.forms[0]["bcountry"].value;
  };

  const handleChangeActive = (e) => {
    if (ActiveProduct !== 0) {
      if (defaultCustomer) {
        setSelectedCustomer(defaultCustomer);
        setActiveInput("customer");
        setcustomer_ids(defaultCustomer.value);
      }
      //getCustomerDetail(defaultCustomer.value);
    } else {
      setSelectedCustomer(null);
      setcustomer_ids("");
    }
    setname("");
    setcartList([]);
    setProductStr("");
    settotaldiscount(0);
    settotalitem(0);
    setSelectedProduct("");
    setSelectedSupplier("");
    setqty(1);
    setnote("");
    setprojectname("");
    setprojectname2(0);
    setValError([]);
    setActiveProduct((prev) => (prev === 0 ? 1 : 0));
  };

  const ValidText1 = (txt, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (txt.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (ValidText(txt)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const validateEmail1 = (mail, ii) => {
    try {
      const updatedErrors = [...ValError];

      if (mail.trim().length === 0) {
        return false;
      }
      if (validateEmail(mail)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const validPhone1 = (phone, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (phone.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (ValidPhone(phone)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const ValidWebsite1 = (web, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (web.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (ValidWebsite(web)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const validName1 = (name, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (name.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (validateName(name)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const handleChangeName = (e) => {
    setname(e.target.value);
  };

  const handleChangecType = (e) => {
    // console.log(e.target.value);
    setcType(e.target.value);
    if (e.target.value !== "select" && e.target.value !== "Select Type") {
      //const cType_id = getcType.find((item) => item.name === e.target.value);
      //setc_type_id(cType_id.c_type_id);
      const updatedErrors = [...ValError];
      updatedErrors[23] = "";
      setValError(updatedErrors);
    } else {
      const updatedErrors = [...ValError];
      updatedErrors[23] = "Please select Customer Type!";
      setValError(updatedErrors);
    }
  };

  const handleKeypadClick = (value) => {
    // try {
    if (activeInput !== null) {
      const newCartList = [...CardList];
      const indexToUpdate = index1;

      if (
        activeInput === "quantity" ||
        activeInput === "unit_price" ||
        activeInput === "discount" ||
        activeInput === "shipment"
      ) {
        var currentValue = String(
          newCartList[indexToUpdate][activeInput] || ""
        );
        // console.log(currentValue);
        if (value === "." && currentValue.includes(".")) {
          return;
        }

        if (value === "." && !currentValue.includes(".")) {
          if (currentValue === "") {
            currentValue = "0";
          }
          newCartList[indexToUpdate][activeInput] = currentValue + ".0";
          setcartList(newCartList);
          setdot(1);
          return;
        }
        var newValue = currentValue + String(value);
        if (dot === 1) {
          var [integerPart] = currentValue.split(".");
          if (integerPart === "") {
            integerPart = "0";
          }
          newValue = integerPart + "." + value;
          setdot(0);
        }

        if (!isNaN(newValue)) {
          // if (activeInput === "shipment") {
          //   // newValue = String(parseInt(newValue));
          //   if (parseFloat(newValue) > newCartList[indexToUpdate].quantity) {
          //     alert("Ship qty must be less or equal to total qty.");
          //     newCartList[indexToUpdate][activeInput] =
          //       newCartList[indexToUpdate][activeInput] + "";
          //     setcartList(newCartList);
          //     setdot(0);
          //     return;
          //   }
          // }
          newCartList[indexToUpdate][activeInput] = newValue;
          newCartList[indexToUpdate].total =
            newCartList[indexToUpdate].unit_price *
              newCartList[indexToUpdate].quantity -
            newCartList[indexToUpdate].discount *
              newCartList[indexToUpdate].quantity;
          //-newCartList[indexToUpdate].discount;

          setcartList(newCartList);
          setdot(0);
        }
      } else if (activeInput === "tax" && index1 === -2) {
        const currentTaxValue = String(tax || "");

        if (value === "." && currentTaxValue.includes(".")) {
          return;
        }

        const newValue = currentTaxValue + String(value || "");
        if (!isNaN(newValue)) {
          settax(newValue);
        }
      } else if (activeInput === "shipment1" && index1 === -3) {
        const currentShipmentValue = String(projectname2 || "");

        if (value === "." && currentShipmentValue.includes(".")) {
          return;
        }

        const newValue = currentShipmentValue + String(value || "");
        if (!isNaN(newValue)) {
          setprojectname2(newValue);
        }
      }
    }
    // } catch (ex) {}
  };

  const handleAddcartClick = () => {
    TimeoutUtility.resetTimeout();
    const isProductInCart = CardList.some(
      (item) => item.product_id === product.value
    );
    if (!isProductInCart && product.value) {
      const resp1 = GetProductByIdSale(p_code, store_id, userID, userToken);
      resp1
        .then(function (result) {
          const defaultProduct = {
            product_id: result.data[0].product_id,
            name: result.data[0].name,
            code: result.data[0].code,
            unit_price: result.data[0].unit_price,
            cost_price: result.data[0].cost_price,
            quantity: qty,
            discount: result.data[0].discount,
            total:
              result.data[0].unit_price * qty - result.data[0].discount * qty,
            // - result.data[0].discount,
            shipment: 0,
            image: result.data[0].image,
            details: result.data[0].details,
            req_delivery_date: "",
            supplier_id: supplier_ids,
            supplier_name: supplier_name,
            notes: "",
            open_datetime: result.data[0].open_datetime,
          };

          setcartList((prevProductList) => [
            defaultProduct,
            ...prevProductList,
          ]);
          setProducts("");
          setSelectedProduct("");
          setSelectedSupplier("");
          setqty(1);

          // setProductOptions((prevOptions) =>
          //   prevOptions.filter(
          //     (option) => option.values !== result.data[0].product_id
          //   )
          // );
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      setSelectedProduct("");
      setSelectedSupplier("");
      setqty(1);
    }
  };

  const handleChangeDTNow = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    // console.log(now.toISOString().slice(0, 19));
    //console.log(oDatetime);
    setODatetime(now.toISOString().slice(0, 19));

    if (dTNow === 1) {
      setOMaxDatetime(now.toISOString().slice(0, 19));
      now = new Date(now.getFullYear(), 0, 2);
      //console.log(now.toISOString().slice(0, 19));
      setOMinDatetime(now.toISOString().slice(0, 19));
    }

    //var str = oDatetime.val();
    //var d = new Date(oDatetime);
    //console.log(d.toISOString().slice(0, 19).replace("T", " "));
    // console.log(
    //   d.toISOString().split("T")[0] + " " + d.toTimeString().split(" ")[0]
    // );
    setDTNow((prev) => (prev === 0 ? 1 : 0));
  };

  const handleChangeODateTime = (e) => {
    if (e === "undefined") {
      setODatetime(null);
    } else {
      setODatetime(e);
    }
  };

  const handleChangeCustomer = (selectedOption) => {
    TimeoutUtility.resetTimeout();
    if (selectedOption && selectedOption.value) {
      setSelectedCustomer(selectedOption);
      setActiveInput("customer");
      setacc_from_bal(selectedOption.balance);
      //setCustomer(selectedOption);
      setcustomer_ids(selectedOption.value);
      //getCustomerDetail(selectedOption.value);
      if (selectedOption.value) {
        //setGetProduct([]);
        // getProductsAll();
      } else {
        //setGetProduct([]);
      }
      setcartList([]);
      // settax(0);
      settotaldiscount(0);
      settotalitem(0);
      setSelectedProduct("");
      setSelectedSupplier("");
      setqty(1);
      setnote("");
      //settrackingno("");
      setprojectname("");
      //setprojectname1("");
      setprojectname2(0);
      //setshipmethod("");
    } else {
      setSelectedCustomer(null);
      setActiveInput("customer");
      setcustomer_ids("");
      setcartList([]);
      setProductStr("");
      settotaldiscount(0);
      settotalitem(0);
      setqty(1);
      setSelectedProduct("");
      setSelectedSupplier("");
      setnote("");
      setprojectname("");
      setprojectname2(0);
    }
  };

  const handleChangeProduct1 = async (selectedOption, event) => {
    if (
      event.key === "Enter" &&
      selectedOption &&
      selectedOption.values &&
      userID &&
      userToken
    ) {
      setSelectedProduct(selectedOption);
      setqty(1);
      await getAllVendorsNameByPurchaseProduct(
        selectedOption.values,
        userID,
        userToken
      )
        .then((resp) => {
          setGetSupplier(resp.data || []);
        })
        .catch((err) => {
          console.log(err.message);
        });
      setActiveInput("product");
      setProducts(selectedOption);
      const selectedProduct = selectedOption.values;
      setp_code(selectedProduct);
      handleAddcartClick();
    }
  };

  const handleChangeProduct = async (selectedOption) => {
    if (selectedOption && selectedOption.values && userID && userToken) {
      var ProductStr1 = ProductStr;
      ProductStr1 = ProductStr1 + selectedOption.values + " ";
      setProductStr(ProductStr1);
      setSelectedProduct(selectedOption);
      setqty(1);
      await getAllVendorsNameByPurchaseProduct(
        selectedOption.values,
        userID,
        userToken
      )
        .then((resp) => {
          setGetSupplier(resp.data || []);
        })
        .catch((err) => {
          console.log(err.message);
        });
      setActiveInput("product");
      setProducts(selectedOption);
      const selectedProduct = selectedOption.values;
      setp_code(selectedProduct);
    }
  };

  const handleChangeNote = (e) => {
    setnote(e.target.value);
  };

  const handleChangeProjectName = (e) => {
    setprojectname(e.target.value);
  };

  const handleChangeProjectName2 = (e) => {
    setActiveInput("shipment1");
    setprojectname2(e.target.value);
    setindex1(-3);
  };

  const handleSaleOrderClick = async (e) => {
    TimeoutUtility.resetTimeout();
    if (ActiveProduct) {
      const {
        phone,
        email,
        website,
        opening_balance,
        cname,
        cphone,
        cemail,
        note1,
        bstreet,
        bcity,
        bstate,
        bzip,
        bcountry,
        bphone1,
        bphone2,
        sstreet,
        scity,
        sstate,
        szip,
        scountry,
        sattname,
        sphone,
      } = document.forms[0];

      setValError([]);
      const updatedErrors = [...ValError];

      if (name === "") {
        updatedErrors[0] = "Enter Customer Name!";
        setValError(updatedErrors);
        return;
      }
      if (name !== "") {
        if (validName1(name, 0) === false) {
          return;
        }
      }
      var exi = 0;
      await CheckCusNameExist(name, userID, userToken)
        .then((resp) => {
          //console.log(resp.data);
          exi = resp.data[0].name;
        })
        .catch((err) => {
          console.log(err.message);
        });
      if (exi === 1) {
        updatedErrors[0] = "Customer Name must be unique!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[0] = "";

      if (phone.value === "") {
        updatedErrors[1] = "Enter Phone!";
        setValError(updatedErrors);

        return;
      }
      if (phone.value !== "") {
        if (validPhone1(phone.value, 1) === false) {
          return;
        }
      }
      updatedErrors[1] = "";

      if (email.value !== "") {
        if (validateEmail1(email.value, 2) === false) {
          return;
        }
      }
      updatedErrors[2] = "";

      if (website.value !== "") {
        if (ValidWebsite1(website.value, 3) === false) {
          return;
        }
      }
      updatedErrors[3] = "";

      if (opening_balance.value === "") {
        updatedErrors[4] = "Invalid field!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[4] = "";

      if (cType === "select" || cType === "Select Type") {
        updatedErrors[23] = "Select Type!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[23] = "";

      if (cname.value === "") {
        updatedErrors[5] = "Enter Contact Name!";
        setValError(updatedErrors);
        return;
      }
      if (cname.value !== "") {
        if (validName1(cname.value, 5) === false) {
          return;
        }
      }
      updatedErrors[5] = "";

      if (cphone.value !== "") {
        if (validPhone1(cphone.value, 6) === false) {
          return;
        }
      }
      updatedErrors[6] = "";

      if (cemail.value !== "") {
        if (validateEmail1(cemail.value, 7) === false) {
          return;
        }
      }
      updatedErrors[7] = "";

      if (note1.value !== "") {
        if (ValidText1(note1.value, 8) === false) {
          return;
        }
      }
      updatedErrors[8] = "";

      if (bstreet.value !== "") {
        if (ValidText1(bstreet.value, 9) === false) {
          return;
        }
      }
      updatedErrors[9] = "";

      if (bcity.value !== "") {
        if (ValidText1(bcity.value, 10) === false) {
          return;
        }
      }
      updatedErrors[10] = "";

      if (bzip.value !== "") {
        if (ValidText1(bzip.value, 11) === false) {
          return;
        }
      }
      updatedErrors[11] = "";

      if (bstate.value !== "") {
        if (ValidText1(bstate.value, 12) === false) {
          return;
        }
      }
      updatedErrors[12] = "";

      if (bcountry.value !== "") {
        if (ValidText1(bcountry.value, 13) === false) {
          return;
        }
      }
      updatedErrors[13] = "";

      if (bphone1.value !== "") {
        if (validPhone1(bphone1.value, 14) === false) {
          return;
        }
      }
      updatedErrors[14] = "";

      if (bphone2.value !== "") {
        if (validPhone1(bphone2.value, 15) === false) {
          return;
        }
      }
      updatedErrors[15] = "";

      if (sstreet.value !== "") {
        if (ValidText1(sstreet.value, 16) === false) {
          return;
        }
      }
      updatedErrors[16] = "";

      if (scity.value !== "") {
        if (ValidText1(scity.value, 17) === false) {
          return;
        }
      }
      updatedErrors[17] = "";

      if (szip.value !== "") {
        if (ValidText1(szip.value, 18) === false) {
          return;
        }
      }
      updatedErrors[18] = "";

      if (sstate.value !== "") {
        if (ValidText1(sstate.value, 19) === false) {
          return;
        }
      }
      updatedErrors[19] = "";

      if (scountry.value !== "") {
        if (ValidText1(scountry.value, 20) === false) {
          return;
        }
      }
      updatedErrors[20] = "";

      if (sphone.value !== "") {
        if (validPhone1(sphone.value, 21) === false) {
          return;
        }
      }
      updatedErrors[21] = "";

      if (sattname.value !== "") {
        if (validName1(sattname.value, 22) === false) {
          return;
        }
      }
      updatedErrors[22] = "";
      setValError([]);
    } else {
      if (customer_ids === "") {
        alert(`Select a Customer!`);
        return;
      }
    }
    if (CardList.length === 0) {
      alert("Estimation cart is empty!");
      return;
    }
    setIsPageFrozen(true);
    setsaveClick(!saveClick);
  };

  // const handlePrintOrderClick = async (e) => {
  //   setprint_flag(true);
  //   handleSaleOrderClick();
  // };

  useEffect(() => {
    async function SaveOrder() {
      if (saveClick && userID && userToken) {
        var cus_id = null;
        if (ActiveProduct) {
          const {
            phone,
            email,
            website,
            opening_balance,
            cname,
            cphone,
            cemail,
            note1,
            bstreet,
            bcity,
            bstate,
            bzip,
            bcountry,
            bphone1,
            bphone2,
            sstreet,
            scity,
            sstate,
            szip,
            scountry,
            sattname,
            sphone,
          } = document.forms[0];

          const cType_id = getcType.find((item) => item.name === cType);
          const prof = null;

          const response1 = await AddCustomerApi(
            name,
            phone.value,
            email.value,
            website.value,
            cname.value,
            cphone.value,
            cemail.value,
            prof,
            note1.value,
            bstreet.value,
            bcity.value,
            bstate.value,
            bzip.value,
            bcountry.value,
            bphone1.value,
            bphone2.value,
            sstreet.value,
            scity.value,
            sstate.value,
            szip.value,
            scountry.value,
            sattname.value,
            sphone.value,
            name,
            1100,
            cType_id.c_type_id,
            opening_balance.value,
            store_id,
            userID,
            userToken
          );
          // console.log(response1, "Response");
          if (response1.status === 200) {
            // console.log(response1.data);
            if (response1.data) {
              var aaa = response1.data;
              cus_id = aaa.customer_id;
            } else {
              alert("Customer failed to add!");
              setsaveClick(!saveClick);
              setIsPageFrozen(false);
              return;
            }
          } else {
            alert("Customer failed to add!");
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }
        } else {
          cus_id = customer_ids;
        }
        // console.log(
        //   d.toISOString().split("T")[0] + " " + d.toTimeString().split(" ")[0]
        // );
        const updatedFormData = { ...formData };
        updatedFormData.est_sale = "E";
        updatedFormData.customer_id = cus_id;
        updatedFormData.total = grandtotal;
        updatedFormData.amount_pending = grandtotal;
        updatedFormData.amount_paid = 0.0;
        updatedFormData.so_note = note;
        updatedFormData.total_price = grandtotal;
        updatedFormData.discount = totaldiscount;
        updatedFormData.user_id = userID;
        if (dTNow) {
          var d = new Date();
          updatedFormData.o_datetime =
            [
              d.getFullYear(),
              (d.getMonth() + 1).toString().padStart(2, "0"),
              d.getDate().toString().padStart(2, "0"),
            ].join("-") +
            " " +
            [
              d.getHours().toString().padStart(2, "0"),
              d.getMinutes().toString().padStart(2, "0"),
              d.getSeconds().toString().padStart(2, "0"),
            ].join(":");
        } else {
          updatedFormData.o_datetime = oDatetime.replace("T", " ");
        }
        //console.log(updatedFormData.o_datetime);
        updatedFormData.store_id = store_id;

        if (projectname !== null) {
          updatedFormData.project_name = projectname;
        } else {
          updatedFormData.project_name = "";
        }
        if (projectname2 !== null) {
          updatedFormData.shipment = projectname2;
        } else {
          updatedFormData.shipment = "";
        }
        if (tax !== null) {
          updatedFormData.tax = tax;
        } else {
          updatedFormData.tax = 0;
        }

        updatedFormData.sale_products = [];

        const uniqueValues = new Set(
          CardList.map((v) => String(v.product_id) + v.notes)
        );
        if (uniqueValues.size < CardList.length) {
          alert(
            `Duplicate notes for same products found.\nSales Order failed to create.`
          );
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          return;
        } else {
          CardList.forEach((product, index) => {
            var dt = "";
            if (product.req_delivery_date) {
              dt = product.req_delivery_date;
            }
            const saleProduct = {
              product_id: product.product_id,
              supplier_id: product.supplier_id,
              quantity: product.quantity,
              price: product.unit_price,
              cost_price: product.cost_price,
              discount: product.discount,
              req_delivery_date: dt,
              item_note: product.notes,
            };
            updatedFormData.sale_products.push(saleProduct);
          });

          setFormData(updatedFormData);
          // console.log(updatedFormData);
          const response = await AddEstimationApi(
            updatedFormData,
            userID,
            userToken
          );
          if (response.status === 200) {
            var so_ids = response.data[0].so_id;
            // console.log(so_ids);
            if (so_ids) {
              if (so_idss === 1) {
                const path = `/Estimates/ConvertEstimate/${so_ids}`;
                window.open(path, "_blank");
                setso_idss(0);
                window.close();
                return;
              }
              if (print_flag) {
                alert("Estimation created successfully.");
                await openNewTab(so_ids);
                setprint_flag(false);
                window.location.reload();
              }
              // setso_idss(response.data[0][0].so_id);
            }
            if (!print_flag) {
              alert("Estimation created successfully.");
            }
          } else {
            alert("Estimation failed to add!");
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          if (!print_flag) {
            window.location.reload();
          }
        }
      }
    }
    SaveOrder();
  }, [saveClick]);

  const handleToggle = (value) => {
    //setToggle((pre) => !pre);
    // if (!viewOrderProductOpen) {
    //   openViewOrderProduct();
    // }
    setActiveProdMenuId({
      product_id: value.product_id,
      store_id: store_id,
    });
    setActiveProdMenu(true);
    //setproductID(value.product_id);
  };

  const handleNewClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      const path = `/Estimates/AddEstimation/${store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClearClick = async (event) => {
    TimeoutUtility.resetTimeout();
    if (window.confirm(`Are you sure you want to Clear the order?`)) {
      // setcartList([]);

      // setshipmethod("");
      // settrackingno("");
      // setprojectname1("");
      // setprojectname2(0);
      // setprojectname("");
      // settax(0);
      // setProductOptions([]);
      // setnote("");
      // setSelectedProduct("");
      // setSelectedCustomer("");
      window.location.reload();
    }
  };

  const handleBackClick = async (event) => {
    TimeoutUtility.resetTimeout();
    if (
      window.confirm(
        `Are you sure you want to Close without saving the changes?`
      )
    ) {
      // console.log(CardList);
      window.close();
    }
  };

  const handleViewEmployeesClick2 = async (event) => {
    event.preventDefault();
    try {
      setso_idss(1);
      await handleSaleOrderClick();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUnitPriceChange = (index, value) => {
    const newCartList = [...CardList];

    newCartList[index].unit_price = value;

    newCartList[index].total =
      value * parseFloat(newCartList[index].quantity) -
      parseFloat(newCartList[index].quantity) *
        parseFloat(newCartList[index].discount);
    setcartList(newCartList);
    setActiveInput("unit_price");
    setindex1(index);
  };

  const CalculateAllFields = () => {
    let total_amt = 0,
      total_itm = 0;
    let total_dis = 0;
    let grandTotal = 0.0;
    let taxx = 0,
      dd = 0;

    if (tax) {
      taxx = parseFloat(tax);
    }
    if (projectname2) {
      dd = parseFloat(projectname2);
    }

    for (let i = 0; i < CardList.length; i++) {
      let a = 0,
        b = 0,
        c = 0;
      if (CardList[i].total) {
        a = parseFloat(CardList[i].total);
      }
      if (CardList[i].discount) {
        b = parseFloat(CardList[i].discount);
      }
      if (CardList[i].quantity) {
        c = parseFloat(CardList[i].quantity);
      }
      total_amt = total_amt + a + b * c;
      total_dis = total_dis + b * c;
      total_itm = total_itm + c;

      // grandTotal = ((parseFloat(CardList[i].unit_price) - parseFloat(CardList[i].discount)) * parseFloat(CardList[i].quantity));
    }
    settotaldiscount(total_dis);
    settotalAmount(total_amt);
    settotalitem(total_itm);

    taxx = (taxx / 100) * (total_amt - total_dis);
    grandTotal = total_amt - total_dis + taxx + dd;

    setgrandtotal(grandTotal);
  };

  const handleChangeQty = (e) => {
    setActiveInput("qty");
    setqty(e.target.value);
    //setindex1(-2);
  };

  const handleNotesChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].notes = value;
    setcartList(newCartList);
    setActiveInput("notes");
    setindex1(index);
  };

  const handleChangeSupplier = (selectedOption) => {
    TimeoutUtility.resetTimeout();
    if (selectedOption && selectedOption.value) {
      setSelectedSupplier(selectedOption);
      setActiveInput("customer");
      setsupplier_ids(selectedOption.value);
      setsupplier_name(selectedOption.label);
    } else {
      setSelectedSupplier(null);
      setActiveInput("customer");
      setsupplier_ids("");
      setsupplier_name("");
    }
  };

  const handleQuantityChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].quantity = value;
    newCartList[index].total =
      value * parseFloat(newCartList[index].unit_price) -
      value * parseFloat(newCartList[index].discount);
    setcartList(newCartList);
    setActiveInput("quantity");
    setindex1(index);
  };

  const handleDiscountChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].discount = value;
    newCartList[index].total =
      parseFloat(newCartList[index].quantity) *
        parseFloat(newCartList[index].unit_price) -
      parseFloat(newCartList[index].quantity) * value;
    setcartList(newCartList);
    setActiveInput("discount");
    setindex1(index);
  };

  const handleChangeReqDate = (index, e) => {
    const newCartList = [...CardList];
    if (e === "undefined") {
      newCartList[index].req_delivery_date = "";
      //setreq_date(null);
    } else {
      newCartList[index].req_delivery_date = e;
      //setreq_date(e);
    }
  };

  const handleChangeTax = (e) => {
    setActiveInput("tax");
    settax(e.target.value);
    setindex1(-2);
  };

  const formatCurrency = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  const handleDeleteClick = (index) => {
    TimeoutUtility.resetTimeout();
    const item1 = CardList[index].product_id;
    const newCartList = [...CardList];
    newCartList.splice(index, 1);
    setcartList(newCartList);

    const currentIdsArray = ProductStr.split(" ");
    const updatedIdsArray = currentIdsArray.filter(
      (id) => id !== String(item1)
    );
    const updatedProductIds = updatedIdsArray.join(" ");
    setProductStr(updatedProductIds);
  };

  const customFilter = (option, inputValue) => {
    const optionSearchField = String(option.value).toLowerCase();
    const lowerCasedInput = inputValue.toLowerCase();
    return optionSearchField.includes(lowerCasedInput);
  };

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  const handleInputChange = (inputValue) => {
    if (customer_ids || ActiveProduct) {
      if (inputValue && inputValue.length >= 2 && userID && userToken) {
        const searchResults = getProductFilterSubString(
          store_id,
          inputValue,
          ProductStr,
          userID,
          userToken
        );
        searchResults
          .then((response) => {
            const productsArray = response.data;
            // const filteredProducts = productsArray.filter(
            //   (product) =>
            //     !CardList.some((item) => item.product_id === product.product_id)
            // );
            // const newProductOptions = filteredProducts.map((item) => ({
            const filteredProducts = productsArray.filter(
              (product) =>
                new Date(product.open_datetime) <= new Date(oDatetime)
            );
            //console.log(filteredProducts);
            //console.log(productsArray);
            addProductToOptions(filteredProducts);
          })
          .catch((error) => {
            console.error("Error fetching products:", error);
          });
      } else {
        // setProductOptions(productOptions1);
      }
    } else {
      setProductOptions([]);
    }
  };

  const addProductToOptions = (prodArr) => {
    const newProductOptions = prodArr.map((item) => ({
      values: item.product_id,
      value: `${item.code} ${item.productname} ${item.details} ${formatCurrency(
        item.unit_price
      )}`.toLowerCase(),
      label: (
        <div
          style={{
            display: "flex",
            marginTop: "4px",
          }}
        >
          {item.image && (
            <div
              style={{
                flex: "0 0 10%",
              }}
            >
              <img
                className="rounded-xl"
                src={`data:image/jpeg;base64,${item.image}`}
                alt={`Product ${item.code}`}
                style={{
                  maxWidth: "50px",
                  height: "50px",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
          <div
            style={{
              flex: item.image ? "0 0 78%" : "0 0 88%",
              paddingLeft: "0px",
            }}
          >
            <div>
              <div style={{ fontWeight: "bold", fontSize: "16px" }}>{`${
                item.code
              } ${truncate(item.productname, item.image ? 48 : 54)}`}</div>
            </div>
            <div style={{ fontSize: "14px" }}>{`${truncate(
              item.details,
              item.image ? 68 : 76
            )}`}</div>
          </div>

          <div
            style={{
              flex: "0 0 12%",
              fontWeight: "500",
              color: currentColor,
              textAlign: "right",
              verticalAlign: "middle",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "15px",
            }}
          >{`${formatCurrency(item.unit_price)}`}</div>
        </div>
      ),
    }));
    setProductOptions(newProductOptions);
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken && store_id && store_id !== 0) {
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        //console.log(now.toISOString().slice(0, 19));
        setODatetime(now.toISOString().slice(0, 19));

        await GetAllCustomersName(store_id, userID, userToken)
          .then((resp) => {
            setGetCustomer(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });

        await GetTaxByStoreId(store_id, userID, userToken)
          .then((resp) => {
            settax(resp.data[0].tax);
          })
          .catch((err) => {
            console.log(err.message);
          });
        await GetAllCustomerTypes(userID, userToken)
          .then((resp) => {
            setGetcType(resp.data || []);
            if (resp.data.length > 0) {
              setcType(resp.data[0].name);
              // setc_type_id(resp.data[0].c_type_id);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken, store_id]);

  // useEffect(() => {
  //   TimeoutUtility.resetTimeout();
  //   const fetchProductOptions = async () => {
  //     const fetchedProductOptions = GetProduct.map((item) => ({
  //       label: `${item.code} ${item.productname}`,
  //       value: item.product_id,
  //     }));
  //     setProductOptions(fetchedProductOptions);
  //   };
  //   fetchProductOptions();
  // }, [GetProduct, customer_ids]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    const fetchCustomerOptions = async () => {
      if (GetCustomer) {
        const fetchedCustomerOption = GetCustomer.map((item) => ({
          label: `${item.customer}`,
          value: item.customer_id,
          balance: item.balance,
          account_id: item.account_id,
          o_datetime: item.open_datetime,
        }));
        setDefaultCustomer(fetchedCustomerOption[0]);
        setCustomerOptions(fetchedCustomerOption);
        setSelectedCustomer(fetchedCustomerOption[0]);
        if (fetchedCustomerOption[0]) {
          setActiveInput("customer");
          setcustomer_ids(fetchedCustomerOption[0].value);
          setacc_from_bal(fetchedCustomerOption[0].balance);
          //getCustomerDetail(fetchedCustomerOption[0].value);
        }
        //setcartList([]);
      }
    };
    fetchCustomerOptions();
  }, [GetCustomer]);

  useEffect(() => {
    setGetSupplier([]);
    setProducts("");
    setp_code("");
    setqty(1);
    setSelectedProduct(null);
    setProductOptions([]);
    if (dTNow === 0) {
      if (GetCustomer) {
        var d1 = new Date(oDatetime);
        //console.log(GetCustomer);
        const filteredCustomers = GetCustomer.filter(
          (item) => new Date(item.open_datetime) <= d1
        );
        //console.log(filteredCustomers);
        const fetchedCustomerOption = filteredCustomers.map((item) => ({
          label: `${item.customer}`,
          value: item.customer_id,
          balance: item.balance,
          account_id: item.account_id,
          o_datetime: item.open_datetime,
        }));
        setCustomerOptions(fetchedCustomerOption);
        if (fetchedCustomerOption.length > 0) {
          setDefaultCustomer(fetchedCustomerOption[0]);
          setSelectedCustomer(fetchedCustomerOption[0]);
          if (fetchedCustomerOption[0]) {
            setActiveInput("customer");
            setcustomer_ids(fetchedCustomerOption[0].value);
            setacc_from_bal(fetchedCustomerOption[0].balance);
            //getCustomerDetail(fetchedCustomerOption[0].value);
          }
        } else {
          setDefaultCustomer(null);
          setSelectedCustomer(null);
          setcustomer_ids("");
          setacc_from_bal("");
        }
      }
      if (CardList.length > 0) {
        const filteredCart = CardList.filter(
          (product) => new Date(product.open_datetime) <= new Date(oDatetime)
        );
        //console.log(filteredCart);
        if (filteredCart !== CardList) {
          setcartList(filteredCart);
        }
      }
    } else {
      if (GetCustomer) {
        const fetchedCustomerOption = GetCustomer.map((item) => ({
          label: `${item.customer}`,
          value: item.customer_id,
          balance: item.balance,
          account_id: item.account_id,
          o_datetime: item.open_datetime,
        }));
        setCustomerOptions(fetchedCustomerOption);
        if (fetchedCustomerOption.length > 0) {
          setDefaultCustomer(fetchedCustomerOption[0]);
          setSelectedCustomer(fetchedCustomerOption[0]);
          if (fetchedCustomerOption[0]) {
            setActiveInput("customer");
            setcustomer_ids(fetchedCustomerOption[0].value);
            setacc_from_bal(fetchedCustomerOption[0].balance);
            //getCustomerDetail(fetchedCustomerOption[0].value);
          }
        } else {
          setDefaultCustomer(null);
          setSelectedCustomer(null);
          setcustomer_ids("");
          setacc_from_bal("");
        }
      }
    }
  }, [oDatetime]);

  useEffect(() => {
    const fetchSupplierOptions = async () => {
      if (GetSupplier) {
        const fetchedSupplierOption = GetSupplier.map((item) => ({
          label: `${item.vendor}`,
          value: item.vendor_id,
          balance: item.balance,
          account_id: item.account_id,
        }));
        //console.log(fetchedCustomerOption);
        //setDefaultSupplier(fetchedSupplierOption[0]);
        setSupplierOptions(fetchedSupplierOption);
        setSelectedSupplier(fetchedSupplierOption[0]);
        if (fetchedSupplierOption[0]) {
          setsupplier_ids(fetchedSupplierOption[0].value);
          setsupplier_name(fetchedSupplierOption[0].label);
          //getCustomerDetail(fetchedCustomerOption[0].value);
        }
      }
      //setcartList([]);
    };
    fetchSupplierOptions();
  }, [GetSupplier]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    CalculateAllFields();
  }, [CardList, tax, projectname2]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxHeight: "70px",
      height: "70px",
      fontSize: "14px",
    }),
    option: (provided) => ({
      ...provided,
      maxHeight: "70px",
      fontSize: "14px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "250px",
    }),
  };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      {isPageFrozen && <div className="overlay-freeze" />}
      <Container fluid className="g-0 p-0 justify-end">
        <Row
          xs={1}
          sm={1}
          className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={7} className="container-col">
            <Header title="CREATE ESTIMATION" />
          </Col>
          <Col md={5} className="container-col">
            <label className="label" style={{ fontSize: "12px" }}>
              <span style={{ paddingLeft: "8px", paddingRight: "36px" }}>
                DateTime:
              </span>
              <span>
                Now{" "}
                <input
                  type="checkbox"
                  checked={dTNow === 1}
                  onChange={handleChangeDTNow}
                  style={{
                    width: "14px",
                    height: "14px",
                  }}
                />
              </span>
            </label>
            <input
              disabled={dTNow}
              className="input"
              id="o_date"
              type="datetime-local"
              style={{
                textAlign: "left",
                fontSize: "12px",
                width: "178px",
                background: dTNow && "lightgray",
              }}
              value={oDatetime}
              min={oMinDatetime}
              max={oMaxDatetime}
              onChange={(e) => handleChangeODateTime(e.target.value)}
            />
          </Col>
        </Row>

        <Row
          xs={1}
          sm={1}
          className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={9} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <Row>
                    <Col md={10}>
                      <label className="label" style={{ fontSize: "18px" }}>
                        Customer:
                      </label>
                    </Col>
                    <Col md={2}>
                      <label className="label" style={{ fontSize: "13px" }}>
                        <input
                          type="checkbox"
                          value="ActiveProduct"
                          checked={ActiveProduct === 1}
                          onChange={handleChangeActive}
                        />
                        {` `}New Customer
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {ActiveProduct ? (
                <form>
                  <Row>
                    <Col md={3}>
                      <div className="col-lg-12">
                        <div className="form-group">
                          {/* <label className="label">Name: </label> */}
                          <input
                            className="input"
                            required
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleChangeName}
                            placeholder="Name"
                            autoFocus
                            onBlur={(e) => validName1(e.target.value, 0)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          <span className="label-imp">*</span>
                          {ValError[0] && (
                            <p className="label-error-pos">{ValError[0]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            required
                            type="text"
                            name="phone"
                            placeholder="Phone"
                            className="input"
                            onBlur={(e) => validPhone1(e.target.value, 1)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          <span className="label-imp">*</span>
                          {ValError[1] && (
                            <p className="label-error-pos">{ValError[1]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            className="input"
                            onChange={(e) => validateEmail(e.target.value)}
                            onBlur={(e) => validateEmail1(e.target.value, 2)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[2] && (
                            <p className="label-error-pos">{ValError[2]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="website"
                            placeholder="Website"
                            className="input"
                            onBlur={(e) => ValidWebsite1(e.target.value, 3)}
                            style={{
                              height: "110%",
                              width: "calc(100% - 12px)",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[3] && (
                            <p className="label-error-pos">{ValError[3]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="number"
                            step="1.00"
                            min="0"
                            // defaultValue={0}
                            name="opening_balance"
                            placeholder="Open Bal"
                            className="input"
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          <span className="label-imp">*</span>
                          {ValError[4] && (
                            <p className="label-error-pos">{ValError[4]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select
                            className="select"
                            id="cTypeSelect"
                            value={cType}
                            onChange={handleChangecType}
                            style={{
                              margin: "0px",
                              width: "calc(100% - 12px)",
                              height: "110%",
                              fontSize: "12px",
                              padding: "8px",
                            }}
                          >
                            {getcType.map((item) => (
                              <option key={item.c_type_id}>{item.name}</option>
                            ))}
                          </select>
                          <span className="label-imp">*</span>
                          {ValError[23] && (
                            <p className="label-error-pos">{ValError[23]}</p>
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="cname"
                            placeholder="Contact Name"
                            onBlur={(e) => validName1(e.target.value, 5)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          <span className="label-imp">*</span>
                          {ValError[5] && (
                            <p className="label-error-pos">{ValError[5]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            type="text"
                            name="cphone"
                            placeholder="Contact Phone"
                            className="input"
                            onBlur={(e) => validPhone1(e.target.value, 6)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[6] && (
                            <p className="label-error-pos">{ValError[6]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            type="text"
                            name="cemail"
                            placeholder="Contact Email"
                            className="input"
                            onBlur={(e) => validateEmail1(e.target.value, 7)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[7] && (
                            <p className="label-error-pos">{ValError[7]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="note1"
                            placeholder="Note"
                            onBlur={(e) => ValidText1(e.target.value, 8)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[8] && (
                            <p className="label-error-pos">{ValError[8]}</p>
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="bstreet"
                            placeholder="Billing Street"
                            onBlur={(e) => ValidText1(e.target.value, 9)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[9] && (
                            <p className="label-error-pos">{ValError[9]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="bcity"
                            placeholder="Billing City"
                            onBlur={(e) => ValidText1(e.target.value, 10)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[10] && (
                            <p className="label-error-pos">{ValError[10]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="bstate"
                            placeholder="Billing State"
                            onBlur={(e) => ValidText1(e.target.value, 12)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[12] && (
                            <p className="label-error-pos">{ValError[12]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="bzip"
                            placeholder="Billing Zip"
                            onBlur={(e) => ValidText1(e.target.value, 11)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[11] && (
                            <p className="label-error-pos">{ValError[11]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="bcountry"
                            placeholder="Billing Country"
                            onBlur={(e) => ValidText1(e.target.value, 13)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[13] && (
                            <p className="label-error-pos">{ValError[13]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="bphone1"
                            placeholder="Billing Phone 1"
                            onBlur={(e) => validPhone1(e.target.value, 14)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[14] && (
                            <p className="label-error-pos">{ValError[14]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="article-container-cus1">
                        <div className="article-cu3">
                          <div className="col-lg-12 mt-2">
                            <div className="form-group">
                              <input
                                className="input"
                                type="text"
                                name="bphone2"
                                placeholder="Billing Phone 2"
                                onBlur={(e) => validPhone1(e.target.value, 15)}
                                style={{
                                  width: "calc(100% - 12px)",
                                  height: "10%",
                                  fontSize: "12px",
                                }}
                              />

                              {ValError[15] && (
                                <p className="label-error-pos">
                                  {ValError[15]}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="article-cu2"
                          style={{
                            marginTop: "6px",
                            alignContent: "center",
                          }}
                        >
                          <button
                            style={{
                              fontSize: "12px",
                              padding: "4px",
                              backgroundColor: currentColor,
                              color: "#fff",
                              border: "none",
                              fontWeight: "bold",
                              width: "24px",
                              borderRadius: "4px",
                            }}
                            color="white"
                            onClick={handleCopyClick}
                          >
                            C
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="sstreet"
                            placeholder="Shipping Street"
                            onBlur={(e) => ValidText1(e.target.value, 16)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[16] && (
                            <p className="label-error-pos">{ValError[16]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="scity"
                            placeholder="Ship City"
                            onBlur={(e) => ValidText1(e.target.value, 17)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[17] && (
                            <p className="label-error-pos">{ValError[17]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="sstate"
                            placeholder="Shipping State"
                            onBlur={(e) => ValidText1(e.target.value, 19)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[19] && (
                            <p className="label-error-pos">{ValError[19]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="szip"
                            placeholder="Ship Zip"
                            onBlur={(e) => ValidText1(e.target.value, 18)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[18] && (
                            <p className="label-error-pos">{ValError[18]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="scountry"
                            placeholder="Shipping Country"
                            onBlur={(e) => ValidText1(e.target.value, 20)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[20] && (
                            <p className="label-error-pos">{ValError[20]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="sphone"
                            placeholder="Shipping Phone"
                            onBlur={(e) => validPhone1(e.target.value, 21)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[21] && (
                            <p className="label-error-pos">{ValError[21]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="col-lg-12 mt-2">
                        <div className="form-group">
                          <input
                            className="input"
                            type="text"
                            name="sattname"
                            placeholder="Shipping Attention Name"
                            onBlur={(e) => validName1(e.target.value, 22)}
                            style={{
                              width: "calc(100% - 12px)",
                              height: "10%",
                              fontSize: "12px",
                            }}
                          />
                          {ValError[22] && (
                            <p className="label-error-pos">{ValError[22]}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              ) : (
                <>
                  <Row
                    xs={1}
                    sm={1}
                    className="justify-content-center"
                    style={{
                      padding: "0",
                    }}
                  >
                    <Col md={12} className="container-col">
                      <div className="sale-input-div">
                        <div className="sale-input">
                          <Select
                            className="myreact-select"
                            id="customer"
                            value={selectedCustomer}
                            onChange={handleChangeCustomer}
                            options={CustomerOptions}
                            isSearchable
                            placeholder="Select Customer"
                            isClearable={true}
                            autoFocus
                          />
                        </div>

                        <div
                          className="sale-bal ml-4"
                          style={{ backgroundColor: currentColor }}
                        >
                          <label>{formatCurrency(acc_from_bal)}</label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <div
                style={{
                  marginTop: ValError.every((element) => element === "")
                    ? "20px"
                    : "12px",
                }}
              />
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="ProductSelect"
                      className="label"
                      style={{ fontSize: "18px", flex: "56%" }}
                    >
                      Product:
                    </label>
                    <label
                      className="label"
                      style={{ fontSize: "18px", flex: "12%" }}
                    >
                      Quantity:
                    </label>
                    <label
                      className="label"
                      style={{ fontSize: "18px", flex: "32%" }}
                    >
                      Supplier:
                    </label>
                  </div>
                  <div className="sale-input-div">
                    <div className="sale-input">
                      <Select
                        className="myreact-select-prod"
                        id="ProductSelect"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        value={selectedProduct}
                        onChange={handleChangeProduct}
                        options={productOptions}
                        isSearchable
                        placeholder="Search Product With Name / Code"
                        //isClearable
                        styles={customStyles}
                        onKeyDown={(event) =>
                          handleChangeProduct1(selectedProduct, event)
                        }
                        filterOption={customFilter}
                        onInputChange={handleInputChange}
                      />
                      <input
                        id="qty"
                        type="number"
                        step="1"
                        min="0"
                        max="1000000"
                        className="input myreact-select-prod-qty"
                        //defaultValue={1.0}
                        value={qty}
                        placeholder="Quantity"
                        //onClick={() => handleInputClick("tax", -2)}
                        onChange={handleChangeQty}
                      />
                      <Select
                        className="myreact-select-supp"
                        id="supplier"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        value={selectedSupplier}
                        onChange={handleChangeSupplier}
                        options={SupplierOptions}
                        isSearchable
                        placeholder="Product Supplier"
                        isClearable
                        styles={customStyles}
                      />
                    </div>
                    <button
                      className="sale-bal ml-4"
                      type="button"
                      style={{
                        backgroundColor: currentColor,
                        fontWeight: "1000",
                        fontSize: "18px",
                        height: "100%",
                      }}
                      onClick={handleAddcartClick}
                    >
                      +
                    </button>
                  </div>
                </Col>
              </Row>
              <br />
              <br />
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale"
                style={{ height: ActiveProduct ? "34vh" : "44vh" }}
              >
                <div className="m-0 p-0">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="table-sale-tr">
                        <th
                          style={{
                            width: "85px",
                          }}
                        >
                          CODE
                        </th>
                        <th
                          style={{
                            width: "360px",
                          }}
                        >
                          PRODUCT
                        </th>
                        <th
                          style={{
                            width: "75px",
                          }}
                        >
                          PRICE
                        </th>
                        <th
                          style={{
                            width: "75px",
                          }}
                        >
                          DISC
                        </th>
                        <th
                          style={{
                            width: "65px",
                          }}
                        >
                          QTY
                        </th>
                        <th
                          style={{
                            width: "80px",
                          }}
                        >
                          TOTAL
                        </th>
                        <th
                          style={{
                            width: "90px",
                          }}
                        >
                          SUPPLIER
                        </th>
                        <th
                          style={{
                            width: "110px",
                          }}
                        >
                          REQ DATE
                        </th>
                        <th
                          style={{
                            width: "240px",
                          }}
                        >
                          NOTES
                        </th>
                        <th
                          style={{
                            width: "35px",
                          }}
                        >
                          DEL
                        </th>
                      </tr>
                    </thead>
                    <tbody className="pos-table-body">
                      {CardList?.map((item, index) => (
                        <tr key={index}>
                          <td onClick={() => handleToggle(item)}>
                            {item.code}
                          </td>
                          <td onClick={() => handleToggle(item)}>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {item.image && (
                                <div
                                  className="image flex gap-4"
                                  style={{
                                    maxWidth: "72px",
                                    height: "72px",
                                    flex: "0 0 20%",
                                  }}
                                >
                                  <img
                                    className="rounded-xl"
                                    src={`data:image/jpeg;base64,${item.image}`}
                                    alt={`Product ${item.code}`}
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  flex: item.image && "0 0 80%",
                                  paddingTop: !item.image && "8px",
                                  paddingBottom: !item.image && "7px",
                                  alignSelf: "center",
                                }}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  {truncate(item.name, item.image ? 38 : 44)}
                                </div>
                                <div>
                                  {truncate(item.details, item.image ? 78 : 88)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="unit_price"
                                type="number"
                                step="1.00"
                                min="0"
                                value={item.unit_price}
                                onClick={() =>
                                  handleInputClick("unit_price", index)
                                }
                                onChange={(e) =>
                                  handleUnitPriceChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="discount"
                                min="0"
                                type="number"
                                step="1.00"
                                value={item.discount}
                                onClick={() =>
                                  handleInputClick("discount", index)
                                }
                                onChange={(e) =>
                                  handleDiscountChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="quantity"
                                type="number"
                                step="1.00"
                                min="0"
                                value={item.quantity}
                                onClick={() =>
                                  handleInputClick("quantity", index)
                                }
                                onChange={(e) =>
                                  handleQuantityChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>{formatCurrency(item.total)}</td>
                          <td>{item.supplier_name}</td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="req_date"
                                type="date"
                                value={item.req_delivery_date}
                                onChange={(e) =>
                                  handleChangeReqDate(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <textarea
                                className="textarea"
                                id="notes"
                                type="text"
                                maxLength={250}
                                value={item.notes}
                                onClick={() => handleInputClick("notes", index)}
                                onChange={(e) =>
                                  handleNotesChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <button onClick={() => handleDeleteClick(index)}>
                              ❌
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale1"
              >
                <table className="borderless w-100 h-100">
                  <tbody>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ paddingTop: "8px" }}
                      >
                        SUB TOTAL:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          paddingTop: "8px",
                        }}
                      >
                        {formatCurrency(total_amount)}
                      </td>
                      <td
                        className="table-grand-sum"
                        rowSpan="5"
                        style={{ backgroundColor: currentColor }}
                      >
                        <div className="tgs-label">
                          GRAND TOTAL
                          <br />
                          <span className="tgs-value">
                            {formatCurrency(grandtotal)}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label ">DISCOUNT:</td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                        }}
                      >
                        {formatCurrency(totaldiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">TAX:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"%"}
                        </label>
                        <input
                          id="tax"
                          type="number"
                          step="1"
                          min="0"
                          max="100"
                          className="input table-sum-tb"
                          value={tax}
                          onClick={() => handleInputClick("tax", -2)}
                          onChange={handleChangeTax}
                          style={{
                            color: currentColor,
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">SHIPPING:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"$"}
                        </label>
                        <input
                          type="number"
                          name="shipment"
                          min="0"
                          step="1.00"
                          value={projectname2}
                          onChange={handleChangeProjectName2}
                          onClick={() => handleInputClick("shipment1", -3)}
                          className="input table-sum-tb"
                          style={{
                            marginLeft: "13px",
                            color: currentColor,
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ marginBottom: "8px" }}
                      >
                        TOTAL ITEMS:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          marginBottom: "8px",
                        }}
                      >
                        {total_item || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </div>
          </Col>
          <Col md={3} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleSaleOrderClick}
                  >
                    <div className="action-btn">SAVE</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: "gray",
                      // background: currentColor,
                    }}
                    // onClick={handlePrintOrderClick}
                  >
                    <div className="action-btn">PRINT</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleNewClick}
                  >
                    <div className="action-btn">NEW</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleClearClick}
                  >
                    <div className="action-btn">CLEAR</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleViewEmployeesClick2}
                  >
                    <div className="action-btn">SAVE & CONVERT</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleBackClick}
                  >
                    <div className="action-btn">CLOSE</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <Col md={12} className="container-col right-table-field">
                  <label>Project Name:</label>
                  <input
                    type="text"
                    name="project name"
                    value={projectname}
                    onChange={handleChangeProjectName}
                    placeholder="Project Name"
                    className="input"
                  />
                </Col>
                <Col
                  md={12}
                  className="container-col right-table-field"
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "16px",
                  }}
                >
                  <textarea
                    className="textarea"
                    placeholder="Estimation Note"
                    id="noteTextarea"
                    value={note}
                    onChange={handleChangeNote}
                  />
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{ padding: "0px" }}
              >
                {keypadButtons.map((number, index) => (
                  <Col md={4} className="container-col" key={index}>
                    <Card
                      className="keypad-button1"
                      style={{
                        border: "1px solid " + currentColor,
                        color: currentColor,
                      }}
                      key={number}
                      onClick={() => handleKeypadClick(number)}
                    >
                      {number}
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          {/* {toggle && (
            <div className="overlay1">
              <Sidebar
                close={() => setToggle(false)}
                product_id={productID}
                store_id={store_id}
              />
            </div>
          )} */}
        </Row>
      </Container>
    </div>
  );
};
export default AddEstimation;
